import React, { useState } from "react";
import "twin.macro";
import { H, P, Button } from "components/typography";
import { Product } from "api/v2/menuCategories.types";
import { currencyFormatter } from "helpers/formatters";

import MenuItemDialogCartForm from "./menuItemDialog/wizard";
import "@reach/dialog/styles.css";
import MenuItemNoForm from "./menuItemDialog/noForm";

const MenuItem: React.FC<{
  product: Product;
  eventId: string;
  showJoinButton: boolean;
}> = ({ product, eventId, showJoinButton }) => {
  const [showDialog, setShowDialog] = useState(false);

  return (
    <li tw="list-none w-full md:w-1/2-gutter">
      <Button.Card
        tw="flex justify-between w-full h-full p-0 text-left "
        onClick={() => setShowDialog(true)}
      >
        <div tw="p-6 w-2/3">
          <H.Three>{product.name}</H.Three>
          <P.Medium tw="h-12 overflow-hidden mb-2">
            {product.description}
          </P.Medium>
          <P.Medium tw="font-medium">
            {currencyFormatter(product.price)}
          </P.Medium>
        </div>
        {product.avatarThumb && (
          <div
            tw="w-1/3 h-full bg-center bg-cover"
            style={{ backgroundImage: `url(${product.avatarThumb})` }}
          ></div>
        )}
      </Button.Card>
      {showJoinButton ? (
        <MenuItemDialogCartForm
          product={product}
          isOpen={showDialog}
          onDismiss={() => setShowDialog(false)}
          eventId={eventId}
        />
      ) : (
        <MenuItemNoForm
          product={product}
          isOpen={showDialog}
          onDismiss={() => setShowDialog(false)}
        />
      )}
    </li>
  );
};

export default MenuItem;
