import api, { extractError } from "api/api";

export const createPaymentIntent = ({ queryKey }: any) => {
  const [_key, amount] = queryKey;

  return api
    .post(`/api/v2/payment_sheets`, {
      data: { payment_sheet: { amount } }
    })
    .then((resp) => resp.data?.paymentSheet)
    .catch((e) => {
      throw extractError(e);
    });
};
