import React from "react";
import "twin.macro";

import { P, A } from "components/typography";
import { Link } from "react-router-dom";

import { useAuth } from "components/AuthProvider";
import { useQuery } from "react-query";
import { fetchFailedOrders } from "api/v2/orders";
import { FailedOrder } from "api/v2/order.types";
import { readableDate } from "helpers/helper";
import { currencyFormatter } from "helpers/formatters";

const FailedOrdersBanner: React.FC = () => {
  const auth = useAuth();

  const { data: orders, isLoading } = useQuery<FailedOrder[]>(
    ["Orders"],
    fetchFailedOrders,
    { enabled: auth.loggedIn }
  );

  if (!auth.loggedIn || isLoading) {
    return null;
  }

  return (
    <section tw="bg-danger w-full px-6">
      {orders?.map((order) => (
        <P.Medium tw="font-bold text-white text-center py-4" key={order.id}>
          <span>
            Your {currencyFormatter(order.transactionAmount)} payment for{" "}
            {order.restaurantName} on {readableDate(order.eventRunAt)} failed.{" "}
          </span>
          <Link
            to={`/orders/${order.id}/retry`}
            tw="font-medium"
            component={A.OnDark}
          >
            Resubmit payment.
          </Link>
        </P.Medium>
      ))}
    </section>
  );
};

export default FailedOrdersBanner;
