import React from "react";
import PropTypes from "prop-types";
const styles = ""; // eslint-disable-line

// <p className="P14">
//   <span className="T1">DwellSocial, Inc.</span>
// </p>
const Heading = (props) => <h1>{props.children}</h1>;
Heading.propTypes = { children: PropTypes.any };

const SubHeading = (props) => <h2>{props.children}</h2>;
SubHeading.propTypes = { children: PropTypes.any };

const SectionHeading = (props) => <h3>{props.children}</h3>;
SectionHeading.propTypes = { children: PropTypes.any };

const Terms = () => (
  <div className="container card mt-4">
    <Heading>DwellSocial, Inc.</Heading>
    <SubHeading>Terms of Use</SubHeading>
    <p className="P2"></p>
    <div className="P9_borderStart">
      <span className="T2">Last revised: </span>
      <span className="T4">                        </span>
      <span className="T2">, 2017</span>
      <span className="T2">
        <span
          className="Footnote_20_anchor"
          title="Footnote:  NTD: This draft assumes Company does not charge for any services or collects any fees for use of the Site. These Terms should be updated from time to time if this changes, if the Company starts selling products or if the Company otherwise collects any fees or payment information. "
        >
          <a href="#ftn1" id="body_ftn1">
            1
          </a>
        </span>
      </span>
    </div>

    <SectionHeading>Introduction</SectionHeading>

    <p className="P9">
      <span className="T2">
        Please read the following terms and conditions of use (as amended from
        time to time, these &apos;Terms of Use&apos;) governing your use of the
        online interfaces and properties (e.g., websites, services, features,
        content or applications) owned, offered and/or controlled by
        DwellSocial, Inc., a Delaware Corporation, (&apos;DwellSocial,&apos;
        &apos;Company,&apos; &apos;we,&apos; us&apos; or &apos;our&apos;),
        including, without limitation,  the 
      </span>
      <span className="T7">www.dwellsocial.com</span>
      <span className="T2">
         website (together with any subdomains, applications and other products
        or services offered by Company, the &apos;Site&apos;).
      </span>
    </p>

    <SectionHeading>Application</SectionHeading>

    <p className="P10">
      <span className="T7">
        In addition to any other terms and conditions which may from time to
        time apply to certain of Company’s services, these Terms of Use apply to
        any person or entity who utilizes the Site or the Content (as defined
        herein) in any way. Any person or entity who utilizes the Site or the
        Content in any way shall be collectively referred to herein as "
      </span>
      <span className="T8">user</span>
      <span className="T7">," "</span>
      <span className="T8">you</span>
      <span className="T7">," "</span>
      <span className="T8">your</span>
      <span className="T7">," or "</span>
      <span className="T8">yours</span>
      <span className="T7">."</span>
    </p>
    <p className="P9_borderStart">
      <span className="T2">
        Your compliance with these Terms of Use, without modification, is a
        condition to your use of the Site. If you do not agree to be bound by
        the Terms of Use, promptly exit the Site. Please also consult our
        Privacy Policy, located at{" "}
      </span>
      <span className="T7">http://www.dwellsocial.com/pages/privacy</span>
      <span className="T2">
        , for a description of our privacy practices and policies.
      </span>
    </p>
    <p className="P9_borderEnd">
      <span className="T2">
        BY USING THE SITE YOU REPRESENT AND WARRANT THAT YOU ARE OVER THE AGE OF
        13. SEE THE SECTION OF THESE TERMS OF USE TITLED "PERSONS UNDER THE AGE
        OF 13."
      </span>
    </p>

    <SectionHeading>About the Company</SectionHeading>

    <p className="P3">
      Company is, among other things, a community where users can share
      recommendations and referrals for businesses and/or service providers
      (&apos;Service Providers&apos;) that perform and/or provide household and
      personal services (such as, without limitation, home repair and
      maintenance services, lawn care and landscaping services, housekeeping
      services, personal training services, babysitting services, and any other
      services which may from time to time be listed or addressed on the site)
      (collectively &apos;Listed Services&apos;) with friends, family, neighbors
      and any other users with whom they are connected.  
    </p>
    <p className="P3">
      To be clear, Company (a) only connects users to Service Providers that
      they have listed as a &apos;favorite;&apos; and (b) only connects users
      who have proactively connected with other users.  Company does not (i)
      negotiate pricing or other terms for Listed Services between users of the
      Site and Service Providers; (ii) collect any fees from users of the Site
      for Listed Services or any other services; (iii) provide any
      recommendations of Service Providers except for recommendations listed by
      users of the Site; or (iv) provide Listed Services to users of the Site
      itself.
    </p>

    <SectionHeading>Listed Service Content</SectionHeading>

    <p className="P12">
      <span className="T2">
        Users of the Site may provide or submit information, advice,
        recommendations or guidance about a particular type of Listed Service or
        Service Provider ("Listed Service Content"), and Company may collect
        and/or display Listed Service Content on the Site. Company gives no
        assurance that any Listed Service Content displayed on the Site is
        accurate, complete or verified. Company does not warrant the accuracy
        of, or assume (and you agree that Company does not bear any)
        responsibility for any errors or omissions in the Listed Service
        Content. Company has no responsibility to update or review any Listed
        Service Content.  {" "}
      </span>
    </p>

    <p className="P13_borderStart">
      <span className="T7">
        You understand that Company owns the Listed Service Content. Any and all
        Listed Service Content collected through the Site or otherwise by
        Company through any other means is the property of Company, and Company
        has the right (but not the obligation) to revise, modify, amend, use or
        re-use such Listed Service Content (including, without limitation, any
        name, user ID or other identifying information that you may submit in
        connection with the Listed Service Content). You won&apos;t modify,
        publish, transmit, participate in the transfer or sale of, reproduce
        (except as expressly provided in the Terms of Use), create derivative
        works based on or otherwise exploit any of the Listed Service Content.{" "}
      </span>
    </p>

    <SectionHeading>Fees and Payments for Listed Services</SectionHeading>

    <p className="P9_borderEnd">
      <span className="T2">
        If you are connected with a Service Provider through the Site, you are
        responsible to the applicable Service Provider for any and all charges,
        fees, duties, taxes and assessments arising out of any Listed Services
        or otherwise due to the Service Provider, all upon the terms and
        conditions as agreed to between yourself and the applicable Service
        Provider.  You are solely responsible for your interactions with Service
        Providers.{" "}
      </span>

      <div className="P11">
        <span className="T3">
          You agree that we will not be liable to you, any Service Provider or
          any other third party for any change of costs for Listed Services or
          other third party services or for any other fees or charges which may
          be due to a Service Provider.  Further, you agree that we will have no
          liability with respect to the acts, omissions, errors,
          representations, warranties, breaches or negligence of any Service
          Provider or for any personal injuries, death, property damage, or
          other damages or expenses resulting from your interactions with any
          Service Provider or any other third party. You hereby release Company
          from any and all claims or liability related to any complaint, claim
          and/or expense related to or arising from any interactions between
          yourself and a Service Provider, including, without limitation, any
          harm caused to you by action or inaction of a Service Provider or a
          Service Provider’s failure to comply with applicable law and/or
          failure to abide by the terms of any agreement between yourself and
          the Service Provider.
        </span>
      </div>
    </p>

    <SectionHeading>Ownership of the Site; Content</SectionHeading>

    <p className="P9">
      <span className="T2">
        All pages within the Site and any material made available for download
        are the property of Company, or its licensors or suppliers, as
        applicable. Company may use any such pages and material as Company
        desires, including, without limitation, to copy, modify, delete in its
        entirety, adapt, publish, translate, create derivative works from and/or
        sell and/or distribute such materials and/or incorporate such materials
        into any form, medium or technology throughout the world.  The Site is
        protected by United States and international copyright and trademark
        laws. The contents of the Site, including, without limitation the Listed
        Service Content (as defined herein), files, information,
        documents, text, photographs, images, audio, video, and any other
        materials accessed through or made available for use or download through
        the Site (collectively, "Content") may not be copied, distributed,
        modified, reproduced, published or used, in whole or in part, except for
        purposes authorized or approved in writing by Company. You may not frame
        or utilize framing techniques to enclose, or deep linking to, any name,
        trademarks, service marks, logo, Content or other
        proprietary information (including; images, text, page layout, or form)
        of Company without our express written consent.
      </span>
    </p>
    <SectionHeading>Persons under the Age of 13</SectionHeading>
    <p className="P9_borderEnd">
      <span className="T2">
        The Site is not available to persons under the age of 13. If you are
        between the ages of 13 and 18 (or between 13 and the age of legal
        majority in your country of residence), you may only use the Site under
        the supervision of a parent or legal guardian who agrees to be bound by
        these Terms of Use. BY USING THE SITE OR ANY CONTENT IN ANY WAY, YOU
        REPRESENT THAT YOU ARE AT LEAST 13 YEARS OF AGE AND HAVE NOT BEEN
        PREVIOUSLY SUSPENDED OR REMOVED FROM THE SITE.{" "}
      </span>
    </p>

    <SectionHeading>
      Use and Access to Site; Security and Restrictions; Passwords
    </SectionHeading>

    <p className="P3">
      <span className="T2">
        You agree to follow all applicable laws when using the Site. If
        applicable law prohibits you from using all or any portion of the Site,
        then you aren&apos;t authorized to use the Site.{" "}
      </span>
    </p>
    <p className="P3">
      <span className="T2">
        All interactions on the Site must comply with these Terms of Use. To the
        extent your conduct, in our sole discretion, restricts or inhibits any
        other user from using or enjoying any part of the Site, we may limit or
        terminate your privileges on the Site and seek other remedies,
        including, without limitation, cancellation of your user account.
      </span>
    </p>
    <p className="P3">
      <span className="T2">
        You are prohibited from violating or attempting to violate the security
        of the Site, including, without limitation, (a) accessing data not
        intended for you or logging onto a server or an account which you are
        not authorized to access; or (b) attempting to probe, scan or test the
        vulnerability of a system or network or to breach security or
        authentication measures without proper authorization; or (c) accessing
        or using the Site or any portion thereof without authorization, in
        violation of these Terms of Use or in violation of applicable law. You
        may not use any scraper, crawler, spider, robot or other automated means
        of any kind to access or copy data on the Site, deep-link to any feature
        or content on the Site, bypass our robot exclusion headers or other
        measures we may use to prevent or restrict access to the Site.
        Violations these Terms of Use or of system or network security may
        result in civil or criminal liability. Company will investigate
        occurrences that may involve such violations and may involve, and
        cooperate with, law enforcement authorities in prosecuting users who are
        involved in such violations. You agree not to use any device, software
        or routine to interfere or attempt to interfere with the proper working
        of the Site or any activity being conducted on the Site. You agree not
        to take any action that places excessive demand on the Site or our
        services or imposes, or may impose, an unreasonable or disproportionally
        large load on our servers or other portion of our infrastructure.{" "}
      </span>
      <span className="T7">
        We also reserve the right to modify, change or discontinue any aspect or
        feature of the Site, including, without limitation, requirements for
        use.
      </span>
    </p>
    <p className="P3">
      <span className="T2">
        You agree to defend, indemnify and hold Company harmless from
        and against all third party claims, damages and expenses (including
        reasonable attorney’s fees) against or incurred by Company arising out
        of your breach of these Terms of Use or violation of applicable law,
        your use or access of the Site.
      </span>
    </p>
    <SectionHeading>User Accounts; Protected Areas</SectionHeading>

    <p className="P9_borderEnd">
      <span className="T2">
        Portions of the Site or services offered on the Site may require you to
        create a user account and/or user ID and/or password (collectively,
        "User Account Information"). In the event access to the Site or a
        portion thereof is limited or requires any User Account Information
        ("Protected Areas"), you agree to access Protected Areas using only your
        User Account Information. You agree that all in formation you submit in
        connection with the creation of a user account is true and correct and
        that you are authorized to submit such information. You agree to, from
        time to time as necessary, update any information associated with your
        user account (including, without limitation, your name, address, phone
        number, payment information and email address) so that it remains
        current, accurate and correct at all times. You agree to protect the{" "}
      </span>
      <span className="T2">
        confidentiality of your User Account Information, and not to share or
        disclose your User Account Information to any third party. You agree
        that you are fully responsible for all activity occurring under your
        user account.{" "}
      </span>
    </p>
    <p className="P3">
      <span className="T7">
        Your User Account Information is non-transferrable. You cannot sell,
        combine, or otherwise share it with any other person. If you violate any
        portion of these Terms of Use, including, without limitation, by failing
        to maintain updated and correct information about your user account,
        Company may, in our sole discretion, determine that your user account
        has fallen out of good standing and we may cancel your user account.
        Upon cancellation or termination of your user account, the provisions of
        these Terms of Use that are by their nature intended to survive
        termination (e.g., any disclaimers, all limitations of liability and all
        indemnities) shall survive.{" "}
      </span>
    </p>
    <p className="P13_borderStart">
      <span className="T7">
        You can close your user account at any time through the Site. Company
        retains ownership of all data associated with your user account
        (including, without limitation, any Listed Service Content or User
        Generated Content (as defined herein) you have posted) after you close
        your account, and reserves the right use such data for any future
        purposes.
      </span>
    </p>
    <p className="P9">
      <span className="T2">
        You agree to defend, indemnify and hold Company harmless from
        and against all third party claims, damages and expenses (including
        reasonable attorney’s fees) against or incurred by Company arising out
        of your breach of these Terms of Use or violation of applicable law, or
        access by anyone accessing the Site with or using your User Account
        Information.
      </span>
    </p>
    <p className="P9_borderEnd">
      <span className="T2">
        AS A USER OF THE SITE, YOU UNDERSTAND AND AGREE THAT: (1) NEITHER
        COMPANY NOR ITS AFFILIATES WILL HAVE ANY LIABILITY TO YOU OR OTHERS FOR
        ANY UNAUTHORIZED TRANSACTIONS MADE USING YOUR USER ACCOUNT INFORMATION;
        AND (2) THE UNAUTHORIZED USE OF YOUR USER ACCOUNT COULD CAUSE YOU TO
        INCUR LIABILITY TO BOTH COMPANY AND OTHER USERS.
      </span>
    </p>

    <SectionHeading>Right to Deny Access</SectionHeading>

    <p className="P11">
      <span className="T3">
        We reserve the right to suspend or deny use of, or access to, the all or
        any portion of Site and/or all or any portion of the Content to you for
        any or no reason, with or without notice. We have the right to suspend
        or terminate any user account at any time for any or no reason, in our
        sole discretion. You agree that we will not be liable to you or to any
        third party for, without limitation, any denial of use of the Site the
        Content or other services offered by us or from suspension or
        termination of your user account.{" "}
      </span>
    </p>

    <SectionHeading>Accuracy and Integrity of the Site</SectionHeading>

    <p className="P9">
      <span className="T2">
        Although Company attempts to ensure the integrity and accurateness of
        the Site, it makes no representations, warranties or guarantees
        whatsoever as to the correctness or accuracy of the Site and Content
        (including the Listed Service Content) thereon. It is possible that the
        Site could include typographical errors, inaccuracies or other errors,
        and that unauthorized additions, deletions and alterations could be made
        to the Site by third parties. In the event that an inaccuracy arises,
        please inform Company so that it can be corrected. Content or other
        information contained on the Site may be changed or updated without
        notice. Additionally, Company shall have no responsibility or liability
        for information or Content posted to the Site from any nonCompany
        affiliated third party.{" "}
      </span>
    </p>

    <SectionHeading>Links to Other Sites</SectionHeading>

    <p className="P9_borderStart">
      <span className="T2">
        The Site may include links or allow access to third party websites.
        Company makes no representations whatsoever about any other website that
        you may access through the Site (each, a "Third Party Site"). When you
        access a Third Party Site, please understand that it is independent from
        Company, and that Company has no control over the content on such Third
        Party Site ("Third Party Content"). In addition, a link to a Third Party
        Site does not mean that Company endorses or accepts any responsibility
        for the Third Party Content on the Third Party Site or the use of the
        Third Party Content or the Third Party Site.{" "}
      </span>
    </p>
    <p className="P9_borderEnd">
      <span className="T2">
        It is your responsibility to take precautions to ensure that anything
        you select for your use or download, whether from the Site or a Third
        Party Site, is free of such items as viruses, worms, Trojan horses, and
        other items of a destructive nature. Company assumes no responsibility,
        and is not liable, for any transmission or material, or any viral
        infection of your computer equipment or software, or any other types of
        damage related to your access, use of browsing of Third Party Sites or
        Third Party Content. If you decide to access a Third Party Site, you do
        this entirely at your own risk and you should review the terms of use
        and privacy policy governing the use of such Third Party Sites.
      </span>
    </p>

    <SectionHeading>
      User Generated Content, Reviews, Feedback and other Postings to the Site
    </SectionHeading>

    <p className="P9_borderStart">
      <span className="T2">
        If you submit, upload or post any Listed Service Content or other
        comments, ideas, suggestions, information, files, videos, images or
        other materials to us, our Site, any Third Party Site used to
        communicate with Company ("User Generated Content"), you agree not to
        provide any User Generated Content that (a) is defamatory, abusive,
        libelous, unlawful, obscene, threatening, harassing, fraudulent,
        pornographic, or harmful, or that could encourage criminal or unethical
        behavior; (b) violates or infringes the privacy, copyright, trademark,
        trade dress, trade secrets or intellectual property rights of any person
        or entity; (c) contains or transmits a virus, malware or any other
        harmful component; (d) offers unauthorized downloads of any copyrighted,
        confidential or private information; (e) has the effect of impersonating
        others; (f) contains by non-spokesperson employees of Company purporting
        to speak on behalf of Company or provides confidential information
        concerning Company; (g) contains chain letter of any kind; (h) is
        purposely inaccurate, commits fraud or falsifies information in
        connection your user account or to create multiple user accounts; (i)
        constitutes, encourages or provides instructions for a criminal offense,
        violates the rights of any party, or that would otherwise create
        liability or violate any local, state, national or international law,
        including, without limitation, the regulations of the U.S. Securities
        and Exchange Commission or any rules of a securities exchange such as
        the New York Stock Exchange (NYSE), the American Stock Exchange or the
        NASDAQ; or (j) violates applicable laws.{" "}
      </span>
    </p>
    <p className="P9">
      <span className="T2">
        You agree, without limitation, not to: (i) contact other users of the
        Site through unsolicited e-mail, telephone calls, mailings or any other
        method of communication; (ii) collect any material or content from any
        Content, including, without limitation; (iii) post, repost or feature
        any User Generated Content or Content to consumers in any manner that
        diverts traffic from the Site without our express written permission; or
        (iv) collect personal information of any user.{" "}
      </span>
    </p>
    <p className="P9">
      <span className="T2">
        You represent and warrant to Company that you have the legal right and
        authorization to provide all User Generated Content to Company for the
        purposes and Company’s use as set forth herein. Company shall have a
        royalty-free, irrevocable, transferable right and license to use the
        User Generated Content however Company desires, including, without
        limitation, to copy, modify, delete in its entirety, adapt,
        publish, translate, create derivative works from and/or sell and/or
        distribute such User Generated Content{" "}
      </span>
      <span className="T7">
        (including, without limitation, any name, user ID or other identifying
        information that you may submit in connection with the User Generated
        Content){" "}
      </span>
      <span className="T2">
        and/or incorporate such User Generated Content into any form, medium or
        technology throughout the world. Company is and shall be under no
        obligation to (1) to maintain any User Generated Content in confidence;
        (2) pay to you any compensation for any User Generated Content; (3)
        respond to any User Generated Content.
      </span>
    </p>
    <p className="P9">
      <span className="T2">
        Company does not regularly review posted User Generated Content, but
        does reserve the right (but is under no obligation) to monitor, edit,
        block or remove any User Generated Content submitted to the Site. You
        grant Company the right to use the name that you submit in connection
        with any User Generated Content. You agree not to use a false email
        address, impersonate any person or entity, or otherwise mislead as to
        the origin of any User Generated Content. You are and shall remain
        solely responsible for the content of any User Generated Content you
        make, use, post and/or share. Company and its affiliates take no
        responsibility and assume no liability for any User Generated Content
        submitted by you or any third party.  Additionally, Company has no
        responsibility or liability for the deletion of, or the failure to store
        or to transmit, any User Generated Content and other communications
        maintained by the Site.
      </span>
    </p>
    <p className="P9">
      <span className="T2">
        In some instances and from time to time, it may be possible to modify or
        remove the User Generated Content submitted or posted through your user
        account. Company makes no representations or warranties that the User
        Generated Content you modify or remove will actually be modified or
        removed from the Site or elsewhere, or that the User Generated Content
        will cease to appear on the Internet, in search engines, social media
        websites, or in any other form, media or technology.{" "}
      </span>
    </p>
    <p className="P9">
      <span className="T2">
        You agree that you post, and access, any and all User Generated Content
        at your own sole risk. We aren’t liable for any errors or omissions in
        any User Generated Content, or for any damages or loss you might suffer
        in connection with it. You are solely responsible for interactions with
        other users of the Site and with Service Providers.{" "}
      </span>
    </p>
    <p className="P9">
      <span className="T2">
        You agree to defend, indemnify and hold Company harmless from and
        against all third party claims, damages and expenses (including
        reasonable attorneys’ fees) against or incurred by Company arising out
        of any User Generated Content you post or allow to be posted to the
        Site.
      </span>
    </p>
    <SectionHeading>Third Party Disputes</SectionHeading>

    <p className="P12">
      <span className="T2">
        If there is a dispute between users of the Site, between users of the
        Site and any Service Provider or between users of the Site and any other
        third party, you agree that we are under no obligation to become
        involved. In the event that you have a dispute with one or more other
        users, you release Company, its officers, employees, agents, and
        successors from claims, demands, and damages of every kind or nature,
        known or unknown, suspected or unsuspected, disclosed or undisclosed,
        arising out of or in any way related to such disputes and/or the Site
        and/or any services provided by Company.{" "}
      </span>
    </p>

    <SectionHeading>
      Claims of Copyright Infringement
      <sub>
        <a
          href="#ftn2"
          title="Footnote:  NTD: Discuss applicability and provision of contact information to U.S. Copyright Office. "
        >
          2
        </a>
      </sub>
    </SectionHeading>

    <p className="P9_borderStart">
      <span className="T2">
        We disclaim any responsibility or liability for copyrighted materials
        posted on the Site. If you believe that your work has been copied in a
        manner that constitutes copyright infringement, please follow the
        procedures set forth below. Company respects the intellectual property
        rights of others and expects its users to do the same.{" "}
      </span>
    </p>
    <p className="P9_borderEnd">
      <span className="T2">
        In accordance with the Digital Millennium Copyright Act ("DMCA"), we
        will respond promptly to notices of alleged infringement that are
        reported to Company’s Designated Copyright Agent, identified below, on
        the Site.  If you believe any content or material herein infringes your
        copyright, you must give Company notice under the Digital Millennium
        Copyright Act.
      </span>
    </p>
    <p className="P4">
      <span className="T2">DwellSocial, Inc.</span>
    </p>
    <p className="P4">
      <span className="T2">
        Attn: Allen Shulman, Designated Copyright Agent
      </span>
    </p>
    <p className="P4">
      <span className="T2"> Email: </span>
      <span className="T4">                                        </span>
    </p>

    <SectionHeading>Security</SectionHeading>

    <p className="P12">
      <span className="T2">
        Transmissions over the Internet are never 100% secure or error-free. We
        take reasonable steps to protect your user account and any personal
        information you may submit from loss, misuse, and unauthorized access,
        disclosure, alteration and destruction, but we are in no event
        responsible for liability related to any security breach of the Site.{" "}
      </span>
    </p>

    <SectionHeading>Disclaimer of Warranties</SectionHeading>

    <p className="P9">
      <span className="T9">
        COMPANY DOES NOT WARRANT THAT ACCESS TO OR USE OF THE SITE WILL
        BE UNINTERRUPTED OR ERROR-FREE OR THAT DEFECTS IN THE SITE WILL BE
        CORRECTED. THE SITE, INCLUDING ANY CONTENT OR INFORMATION CONTAINED
        WITHIN IT OR ANY SITE- RELATED SERVICE, IS PROVIDED "AS IS," WITH ALL
        FAULTS, WITH NO REPRESENTATIONS OR WARRANTIES OF ANY KIND, EITHER
        EXPRESSED OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, THE IMPLIED
        WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, QUALITY
        OF INFORMATION, QUIET ENJOYMENT, AND TITLE/NON-INFRINGEMENT. COMPANY
        DOES NOT WARRANT THE ACCURACY, COMPLETENESS OR TIMELINESS OF THE
        INFORMATION OBTAINED THROUGH THE SITE. YOU ASSUME TOTAL RESPONSIBILITY
        AND RISK FOR YOUR USE OF the SITE, SITE-RELATED SERVICES, AND LINKED
        WEBSITES. COMPANY DOES NOT WARRANT THAT FILES AVAILABLE FOR DOWNLOAD
        WILL BE FREE OF VIRUSES, WORMS, TROJAN HORSES OR OTHER DESTRUCTIVE
        PROGRAMMING. YOU ARE RESPONSIBLE FOR IMPLEMENTING PROCEDURES SUFFICIENT
        TO SATISFY YOUR NEEDS FOR DATA BACK AND SECURITY. WARRANTIES RELATING TO
        PRODUCTS OR SERVICES OFFERED, SOLD AND DISTRIBUTED BY COMPANY ARE
        SUBJECT TO SEPARATE WARRANTY TERMS AND CONDITIONS, IF ANY, PROVIDED WITH
        OR IN CONNECTION WITH THE APPLICABLE PRODUCTS OR SERVICES.
      </span>
    </p>
    <SectionHeading>
      Limitation of Liability Regarding Use of Site
    </SectionHeading>

    <p className="P9_borderStart">
      <span className="T9">
        YOU EXPRESSLY AGREE THAT USE OF THE SITE IS AT YOUR SOLE RISK.  COMPANY
        AND ANY THIRD PARTIES MENTIONED ON the SITE ARE NEITHER RESPONSIBLE NOR
        LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL, CONSEQUENTIAL, SPECIAL,
        EXEMPLARY, PUNITIVE, OR OTHER DAMAGES WHATSOEVER (INCLUDING, WITHOUT
        LIMITATION, THOSE RESULTING FROM LOST PROFITS, LOST DATA, OR BUSINESS
        INTERRUPTION) ARISING OUT OF OR RELATING IN ANY WAY TO THE
        SITE, SITE-RELATED SERVICES, CONTENT OR INFORMATION CONTAINED WITHIN THE
        SITE, AND/OR ANY LINKED WEBSITE, WHETHER BASED ON WARRANTY, CONTRACT,
        TORT, OR ANY OTHER LEGAL THEORY AND WHETHER OR NOT ADVISED OF THE
        POSSIBILITY OF SUCH DAMAGES. YOUR SOLE REMEDY FOR DISSATISFACTION WITH
        THE SITE, SITE-RELATED SERVICES, AND/OR LINKED WEBSITES IS TO STOP USING
        THE SITE AND/OR THOSE SERVICES. TO THE EXTENT ANY ASPECTS OF THE
        FOREGOING LIMITATIONS OF LIABILITY ARE NOT ENFORCEABLE, THE MAXIMUM
        LIABILITY OF COMPANY TO YOU WITH RESPECT TO YOUR USE OF the SITE IS $500
        (FIVE HUNDRED DOLLARS).
      </span>
    </p>
    <p className="P9_borderEnd">
      <span className="T9">
        COMPANY IS NOT RESPONSIBLE FOR ANY USER’S VIOLATION OF LAWS, RULES OR
        REGULATIONS.{" "}
      </span>
    </p>

    <SectionHeading>General Indemnification</SectionHeading>

    <p className="P11">
      <span className="T3">
        In addition to your indemnification obligations set forth throughout
        these Terms of Use, you agree to defend, indemnify and hold harmless
        Company, its subsidiaries and affiliates and their respective directors,
        officers, employees and agents from and against all claims and expenses,
        including, without limitation, attorneys’ fees, arising out of, related
        to, or in connection with any of the following: (a) any Listed Services
        purchased from a Service Provider or arising out of any interaction with
        a Service Provider on the Site or any additional products or services
        purchased or obtained by you from such Service Provider; (b) any User
        Generated Content submitted or posted by you; (c) any use of the Site or
        other Company services in violation of  these Terms of Use; (d) fraud
        you commit; or (e) your violation of any applicable U.S. or foreign law
        or rights of a third party.{" "}
      </span>
    </p>

    <SectionHeading>Interpretation and Disputes</SectionHeading>

    <p className="P9_borderStart">
      <span className="T2">
        We will try work in good faith to resolve any issue you have with Site
        if you bring such issue to us in a timely fashion. However, we realize
        that there may be rare cases where we may not be able to resolve
        an issue to your satisfaction.{" "}
      </span>
    </p>
    <p className="P9">
      <span className="T7">
        These Terms of Use governed by the laws of the United States and the
        State of Delaware, without regard to any conflict of laws provisions.{" "}
      </span>
      <span className="T2">A</span>
      <span className="T7">
        ny case, controversy, suit, action or proceeding arising out of, in
        connection with, or related to these Terms of Use (including any dispute
        related to the Site) shall be brought in state and federal courts
        located in Chicago, Illinois, and you hereby waive any objection to the
        exclusive jurisdiction of such courts. You hereby (a){" "}
      </span>
      <span className="T10">
        consent and submit to exclusive personal jurisdiction and venue of such
        courts
      </span>
      <span className="T7">
        ; and (b) YOU AND COMPANY AGREE TO WAIVE ANY RIGHT TO TRIAL BY JURY.{" "}
      </span>
    </p>
    <p className="P9_borderEnd">
      <span className="T2">
        YOU AND COMPANY AGREE THAT EACH MAY BRING CLAIMS AGAINST THE OTHER ONLY
        IN YOUR OR ITS INDIVIDUAL CAPACITY, AND NOT AS A PLAINTIFF OR
        CLASS MEMBER IN ANY PURPORTED CLASS OR REPRESENTATIVE PROCEEDING.{" "}
      </span>
    </p>

    <SectionHeading>Organizational Users</SectionHeading>

    <p className="P11">
      <span className="T3">
        If you&apos;re the Site or any Company products or services on behalf of
        an organization or entity, you affirm that you have the right to act on
        behalf of that organization or entity, and that these Terms of Use apply
        to the organization or entity (and all references to "you" and similar
        terms refer to that organization or entity). The organization or entity
        will hold harmless and indemnify Company (and its affiliates, officers,
        agents, and employees) from any claim, suit or action arising from or
        related to the use of the Site or violation of these Terms of Use,
        including any liability or expense arising from claims, losses, damages,
        suits, judgments, litigation costs and attorneys&apos; fees.
      </span>
    </p>

    <SectionHeading>Miscellaneous</SectionHeading>

    <p className="P9_borderStart">
      <span className="T2">
        In the event that any of the Terms of Use are held by a court or other
        tribunal of competent jurisdiction to be unenforceable, such
        provisions shall be limited or eliminated to the minimum extent
        necessary so that these Terms of Use shall otherwise remain in full
        force and effect.{" "}
      </span>
    </p>
    <p className="P9">
      <span className="T2">
        No waiver by either you or Company of any breach or default or failure
        to exercise any right allowed under these Terms of Use is a waiver of
        any preceding or subsequent breach or default or a waiver or forfeiture
        of any similar or future rights under our Terms of Use. The section
        headings used herein are for convenience only and shall be of no legal
        force or effect. If a court of competent jurisdiction holds any{" "}
      </span>
      <span className="T2">
        provision of our Terms of Use invalid, such invalidity shall not affect
        the enforceability of any other provisions contained in these Terms of
        Use, and the remaining portions of our Terms of Use shall continue in
        full force and effect.
      </span>
    </p>
    <p className="P9">
      <span className="T2">
        You may not assign these Terms of Use, or any rights, benefits or
        obligations hereunder, by operation of law or otherwise, without the
        express written permission of Company. Any attempted assignment that
        does not comply with these Terms of Use shall be null and void. Company
        may assign these Terms of Use, in whole or in part, to any third-party
        in its sole discretion.
      </span>
    </p>
    <p className="P9">
      <span className="T2">
        These Terms of Use and the Privacy Policy constitute the entire
        agreement between Company and you pertaining to the subject matter
        hereof and supersede all prior or contemporaneous communications and
        proposals, whether oral or written, between you and Company with respect
        to such subject matter.
      </span>
    </p>
    <p className="P9">
      <span className="T7">
        You agree that these Terms of Use and any other documentation,
        agreements, notices or communications between you and Company may be
        provided to you electronically, to the extent permissible by law. Please
        print a copy of all documentation, agreements, notices or other
        communications for your reference.
      </span>
    </p>

    <SectionHeading>Revisions to Terms of Use</SectionHeading>

    <p className="P13">
      <span className="T2">
        In its sole discretion, Company may from time-to-time revise these Terms
        of Use by updating this posting. You should, therefore, periodically
        visit this page to review the current Terms of Use, so you are aware of
        any such revisions to which you are bound. Certain provisions of these
        Terms of Use may be superseded by expressly designated legal notices
        or terms located on particular pages within the Site.
      </span>
    </p>

    <SectionHeading>Contact Information</SectionHeading>

    <p className="P9_borderEnd">
      <span className="T2">
        If you have any questions, please contact Company at:{" "}
      </span>
    </p>

    <p className="P4">
      <span className="T2">DwellSocial, Inc.</span>
    </p>

    <p className="P4">
      <span className="T2">3100 Concord Ct.</span>
    </p>

    <p className="P4">
      <span className="T2">Northbrook, IL. 60062</span>
    </p>

    <p className="P4">
      <span className="T2">Email: info@dwellsocial.com</span>
    </p>

    <p>
      <span className="footnodeNumber">
        <a className="Footnote_20_Symbol" id="ftn1" href="#body_ftn1">
          1
        </a>
      </span>
      <span className="footnote_20_reference" /> NTD: This draft assumes Company
      does not charge for any services or collects any fees for use of the Site.
      These Terms should be updated from time to time if this changes, if the
      Company starts selling products or if the Company otherwise collects any
      fees or payment information.
    </p>
    <p className="P16">
      <span className="footnodeNumber">
        <a className="Footnote_20_Symbol" id="ftn2" href="#body_ftn2">
          2
        </a>
      </span>
      <span className="footnote_20_reference" /> NTD: Discuss applicability and
      provision of contact information to U.S. Copyright Office.  
    </p>
  </div>
);

export default Terms;
