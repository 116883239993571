import PropTypes from "prop-types";
import connectComponent from "redux/connectComponent";
import * as DB from "helpers/db";

const TabCount = ({ type, ...props }) => {
  switch (type) {
    case "open":
      return `${DB.relevances(props).length}`;
    case "active":
      return `${DB.activeProjectIds(props).length}`;
    case "complete":
      return `${DB.completeProjectIds(props).length}`;
    default:
      return null;
  }
};

TabCount.propTypes = {
  type: PropTypes.string
};

export default connectComponent(TabCount, [
  "authentication",
  "relevance",
  "users",
  "project_memberships"
]);
