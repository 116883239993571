import React from "react";
import "twin.macro";

import { useQuery } from "react-query";
import { Event } from "api/v2/event.types";
import { fetchEvent } from "api/v2/events";
import { fetchOrder } from "api/v2/orders";
import { Order } from "api/v2/order.types";

import OrderSummary from "./orderSummary";

import {
  useParams,
  Redirect,
  useRouteMatch,
  useLocation
} from "react-router-dom";
import { H, Alert, Breadcrumb } from "components/typography";
import Spinner from "components/spinner";

import { useAuth } from "components/AuthProvider";

import PaymentFormPage from "./paymentFormPage";

const Checkout: React.FC = () => {
  const { id: orderId } = useParams<{ id: string }>();
  const { url } = useRouteMatch();
  const locationState = useLocation().state as { error: string | undefined };

  const auth = useAuth();

  const { data: order, isLoading } = useQuery<Order>(
    ["order", orderId],
    fetchOrder
  );

  const { data: event, isLoading: eventIsLoading } = useQuery<Event>(
    ["event", order?.eventId],
    fetchEvent,
    {
      enabled: !!order
    }
  );

  if (!auth.loggedIn)
    return (
      <Redirect
        to={{
          pathname: "/session",
          state: { checkout: true, referrer: url }
        }}
      />
    );

  if (isLoading || eventIsLoading) {
    return (
      <div tw="flex w-full justify-center p-6">
        <Spinner disabled={false} />
      </div>
    );
  }

  if (!orderId || !order || !event) return <Redirect to="/404project" />;

  return (
    <div tw="flex flex-col md:flex-row">
      <article tw="w-full md:w-4/5 min-h-screen px-6 md:px-24 pt-10">
        <Breadcrumb.Nav tw="py-4">
          <Breadcrumb.Location>Order Details</Breadcrumb.Location>
          <Breadcrumb.Separator />
          <Breadcrumb.Location>Payment Details</Breadcrumb.Location>
        </Breadcrumb.Nav>
        <H.Two
          as="h1"
          tw="pb-4 mb-0 border-0 border-solid border-secondary border-b"
        >
          Complete your order
        </H.Two>
        {locationState?.error && (
          <Alert.Danger>
            <strong>Error: </strong>
            {locationState?.error}
          </Alert.Danger>
        )}

        <Alert.Info>
          There was an issue processing your payment. Please check your credit
          card. If you have been charged for this order, please reach out to us
          at{" "}
          <a href="mailto:support@dwellsocial.zendesk.com">
            support@dwellsocial.zendesk.com
          </a>{" "}
          to sort this out.
        </Alert.Info>

        <PaymentFormPage
          amount={order.total}
          eventId={order.eventId.toString()}
          event={event}
          orderId={orderId}
        />
      </article>
      <OrderSummary
        event={event}
        order={order}
        isLoading={isLoading || eventIsLoading}
      />
    </div>
  );
};

export default Checkout;
