import dayjs from "dayjs";

/* React Component Helpers */
export const pluralize = (count, str) => {
  const force = count === null;
  const plural = count && count > 1;
  const alreadyPlural = str && str.match(/s$/);

  return (plural || force) && !alreadyPlural ? `${str}s` : str;
};

export const pluck = (arr, key) => arr.map((val) => val[key]);

export const except = (arr, ...args) =>
  arr.filter((val) => !args.some((arg) => `${arg}` == `${val}`));

export const capitalize = (word) =>
  word ? word.slice(0, 1).toUpperCase() + word.slice(1) : word;

export const camelize = (word) => {
  let words = word.split("_");

  return [
    words[0],
    ...words
      .slice(1)
      .map((word) => word.slice(0, 1).toUpperCase() + word.slice(1))
  ].join("");
};

export const snakeify = (word) =>
  word.replace(/[A-Z]/g, (character) => `_${character.toLowerCase()}`);

export const humanize = (word) => {
  let words = word.split("_");

  return [capitalize(words[0]), ...words.slice(1).map(capitalize)].join(" ");
};

export const isString = (val) => {
  return typeof val === "string" || val instanceof String;
};

export const toSentence = (words) => {
  if (!words) {
    return "";
  } else if (isString(words)) {
    return words;
  } else if (words.length == 1) {
    return words[0];
  } else if (words.length == 2) {
    return words.join(" and ");
  } else {
    let term = words.slice(-1)[0];

    if (!term) {
      return "";
    }

    let finalTerm = term.replace(/^/, "and ");

    return words
      .slice(0, words.length - 1)
      .concat([finalTerm])
      .join(", ");
  }
};

// Object helpers
export const objectMap = (object, callback) =>
  Object.fromEntries(callback(Object.entries(object)));

// DATE HANDLING
export const dateSortDesc = (date1, date2) => {
  if (date1 > date2) return -1;
  if (date1 < date2) return 1;
  return 0;
};

export const timeBetween = (d0, d1) => {
  let msPerDay = 8.64e7;
  let msPerHour = msPerDay / 24;
  let msPerMinute = msPerHour / 60;

  // Copy dates so don't mess them up
  let x0 = new Date(d0);
  let x1 = new Date(d1);

  if (x1 <= x0) return { days: 0, hours: 0, minutes: 0 };

  let totalMs = x1 - x0;

  let remainingDaysMs = Math.floor(totalMs / msPerDay) * msPerDay;
  let remainingHoursMs =
    Math.floor((totalMs - remainingDaysMs) / msPerHour) * msPerHour;
  let remainingMinutesMs =
    Math.floor((totalMs - remainingDaysMs - remainingHoursMs) / msPerMinute) *
    msPerMinute;

  let remainingDays = Math.floor(remainingDaysMs / msPerDay);
  let remainingHours = Math.floor(remainingHoursMs / msPerHour);
  let remainingMinutes = Math.floor(remainingMinutesMs / msPerMinute);

  // Round to remove daylight saving errors
  return {
    days: remainingDays,
    hours: remainingHours,
    minutes: remainingMinutes
  };
};

// Get year from next month
export const yearNextMonth = (date) => {
  const nextMonth = date.getMonth() + 1;
  return new Date(date.setMonth(nextMonth)).getFullYear().toString();
};

// Get readable date
export const readableDate = (date, full = false) =>
  dayjs(date).format(`MMMM D${full ? ", YYYY" : ""}`);

export const readablePhone = (phone) => {
  let cleaned = ("" + phone).replace(/\D/g, "");
  let match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);

  if (match) {
    return ["(", match[2], ") ", match[3], "-", match[4]].join("");
  }
  return "No phone";
};

// Fisher-Yates / Knuth sort algorithm
export const shuffle = (objs) => {
  for (var i = objs.length - 1; i >= 0; i--) {
    let randomIndex = Math.floor(Math.random() * (i + 1));
    let itemAtIndex = objs[randomIndex];

    objs[randomIndex] = objs[i];
    objs[i] = itemAtIndex;
  }
  return objs;
};

// Show an element
const showElement = (el) => (el.style.display = "block");

// Hide an element
const hideElement = (el) => (el.style.display = "none");

const toggleElement = (el) =>
  window.getComputedStyle(el).display === "block"
    ? hideElement(el)
    : showElement(el);

// Toggle element visibility
export const toggle = (event) => toggleElement(event.target);

export const homeToFullTextAddress = (home) =>
  home && [home.street, home.city, home.state].filter((x) => x).join(", ");

export const formalizedPhone = (phone) => {
  // console.log("formalized phone", phone)
  if (!phone) return "";

  const segments = phone.match(/^(1|\+1)?(\d{3})(\d{3})(\d{4})$/);

  // console.log("segments", segments)
  if (segments) {
    const chunks = segments.slice(2);

    return "(" + chunks[0] + ") " + chunks.slice(1).join("-");
  }

  // console.log("Replaced", phone.replace(/[^\d]+/,'_'))

  const tokenized = phone.replace(/[^\d]+/g, "_").split("_");

  // console.log("tokenized", tokenized)
  if (tokenized.length != 3) return phone;

  // console.log("TOKENIZED", tokenized)
  return "(" + tokenized[0] + ") " + tokenized[1] + "-" + tokenized[2];
};

const queryParams = (loc) => {
  const params = new URLSearchParams(loc.search);

  let q = {};
  //Iterate the search parameters.
  for (let p of params) {
    q[p[0]] = p[1];
  }

  return q;
};

// query parameters
export const queryParameters = (loc) => queryParams(loc);

export const leadParameters = (loc) => {
  let params = queryParameters(loc);

  return Object.keys(params)
    .filter((key) => key.match(/^utm_/) || key === "rid")
    .reduce((ac, key) => Object.assign(ac, { [key]: params[key] }), {
      referer: document.referrer
    });
};

// functional helpers
export const tap = (yielded) => (callback) => [callback(yielded), yielded][1];

export const yieldSelf = (exp) => (func) => func(exp);

export const dig = (path, tree) =>
  path.reduce((ac, node) => (ac && ac[node] ? ac[node] : null), tree);

export const digInstantiate = (path, tree, ...args) =>
  yieldSelf(dig(path, tree))((func) => func && new func(...args));

// promise helpers
export const promise = (callback) =>
  new Promise((resolve, reject) => callback(resolve, reject)); // eslint-disable-line no-unused-vars

// layout helpers
export const killWrap = () => {
  const el = document.getElementById("root");
  el && el.classList.remove("wrap");
};

export const addWrap = () => {
  const el = document.getElementById("root");
  el && el.classList.add("wrap");
};

export const domMemoize = (key) => (setter) =>
  window[key] || (window[key] = setter());

export const compact = (list) => (list || []).filter((item) => item);

export const compactObject = (obj) =>
  Object.fromEntries(Object.entries(obj).filter((kv) => kv[1]));
