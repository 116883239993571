import React from "react";
import PropTypes from "prop-types";
import connectComponent from "redux/connectComponent";
import ReferenceField from "components/ReferenceField";

import { LinkedEventCard } from "components/cards/EventCard";

export const getMonth = (date) =>
  new Intl.DateTimeFormat("en-US", { month: "short" }).format(date);

const Project = (props) => (
  <ReferenceField
    reference="project"
    id={props.id}
    preloaded={props.preloaded}
    record={props.record}
  >
    <LinkedEventCard {...props} />
  </ReferenceField>
);

Project.propTypes = {
  id: PropTypes.any,
  preloaded: PropTypes.bool,
  record: PropTypes.object
};

export default connectComponent(Project, ["authentication", "projects"]);
