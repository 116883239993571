import { find, findAllBy } from "./base";

const PROJECTS = ["projects", "projects"];

export const project = (state) => (id) => find(state)(PROJECTS, id);

export const projects = (state) => (params) =>
  findAllBy(state)(PROJECTS, params);

export const available =
  (state) =>
  (id) =>
  (membership = null) => {
    const proj = project(state)(id);

    if (!proj) return false;

    if (membership) return proj.status == "open" || proj.status == "closed";

    return proj.status === "open" && !proj.full;
  };

export const purchaseable =
  (state) =>
  (id) =>
  (membership = null) => {
    const proj = project(state)(id);

    if (!proj) return false;

    if (!membership) return proj.status == "open";

    return proj.status == "open" || proj.status == "closed";
  };

/****
 * Joinability
 */

// must exist and be open
const isJoinable = (proj) => proj?.status === "open";

export const joinable = (state) => (id) => isJoinable(project(state)(id));

/*** end joinability ***/
