import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import { googleAutocomplete } from "lib/google";

export const placeToComponents = (place) => {
  if (!place) return {};

  const value = (type, field = "long_name") =>
    (place.address_components.find((p) => p.types.includes(type)) || {})[field];

  return {
    street: [value("street_number"), value("route")].filter((p) => p).join(" "),
    city: value("locality"),
    state: value("administrative_area_level_1", "short_name"),
    postal_code: value("postal_code")
  };
};

const AddressAutocompleteField = ({
  inputProps,
  onChange,
  onFocus,
  onReset,
  required
}) => {
  const [selected, setSelected] = useState(false);

  const addressRef = React.createRef();

  // const ifSelected = callback => () => (selected && callback())
  const unlessSelected = (callback) => () => !selected && callback();

  const reset = () => {
    onReset && onReset();
    addressRef.current.value = "";
  };

  const select = (components) => {
    setSelected(true);
    onChange(components);
    onFocus && onFocus();
  };

  const onSelect = (place) =>
    place.place_id ? select(placeToComponents(place)) : reset();

  useEffect(() => {
    googleAutocomplete(addressRef.current, onSelect);
  }, []);

  return (
    <input
      ref={addressRef}
      autoComplete="off"
      autoCorrect="off"
      className="form-control"
      data-testid="address"
      required={required}
      type="text"
      {...inputProps}
      onBlur={unlessSelected(reset)}
    />
  );
};

AddressAutocompleteField.propTypes = {
  inputProps: PropTypes.object,
  required: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onFocus: PropTypes.func,
  onReset: PropTypes.func
};

export default AddressAutocompleteField;
