import React, { useState, useEffect, useRef } from "react";
import tw from "twin.macro";
import styled, { css } from "styled-components";
import useInterval from "helpers/useInterval";

/**
 * This is a *very* simplistic carousel that just autoscrolls.
 * There are no controls, but it should  be easy enough to extend
 * if we ever need it.
 *
 * It prefers 4 or more slides/children but will work with 3. At 2,
 * it just doesn't slide, lol.
 */
const Carousel: React.FC<{ autoScrollInterval: number }> = ({
  autoScrollInterval,
  children
}) => {
  const childrenRef = useRef<Array<HTMLDivElement | null>>([]);
  const numChildren = React.Children.count(children);

  const [minHeight, setMinHeight] = useState<string | undefined>();

  useEffect(() => {
    const tallestChildHeight = childrenRef.current.reduce(
      (maxHeight, currentChild) =>
        Math.max(currentChild?.clientHeight || 0, maxHeight),
      0
    );

    setMinHeight(`${tallestChildHeight}px`);
  }, [children]);

  const [currentSlide, setCurrentSlide] = useState(1);
  const nextSlide = currentSlide + 1 >= numChildren ? 0 : currentSlide + 1;
  const prevSlide = currentSlide === 0 ? numChildren - 1 : currentSlide - 1;

  useInterval(() => {
    setCurrentSlide(currentSlide + 1 >= numChildren ? 0 : currentSlide + 1);
  }, autoScrollInterval);

  return (
    <section
      tw="flex flex-row relative w-full h-full overflow-hidden items-center"
      style={minHeight ? { minHeight: minHeight } : {}}
    >
      {React.Children.map(children, (child, i) => (
        <Slide
          key={`testimonial-${i}`}
          i={i}
          ref={(el) => (childrenRef.current[i] = el)}
          currentSlide={currentSlide}
          nextSlide={nextSlide}
          prevSlide={prevSlide}
          numChildren={numChildren}
        >
          {child}
        </Slide>
      ))}
    </section>
  );
};
export default Carousel;

interface SlideProps {
  i: number;
  currentSlide: number;
  nextSlide: number;
  prevSlide: number;
  numChildren: number;
}
const Slide = styled.div<SlideProps>(
  ({ i, currentSlide, nextSlide, prevSlide, numChildren }) => [
    tw`flex-shrink-0 w-full absolute`,
    // animation logic
    i === currentSlide && tw`translate-x-0`,
    i === prevSlide && tw`-translate-x-full`,
    i === nextSlide && tw`translate-x-full`,

    i === prevSlide && numChildren < 4 && tw`hidden`,
    ![prevSlide, currentSlide, nextSlide].includes(i) && tw`hidden`,
    css`
      transform: translate(var(--tw-translate-x));
    `,
    tw`transition-all ease-in-out duration-1000`
  ]
);
