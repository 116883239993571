import dayjs from "dayjs";
import React, { useState } from "react";
import PropTypes from "prop-types";

import { useQuery } from "react-query";
import history from "redux/history";
import Container from "components/container";
import { Typography } from "@material-ui/core";
import Projects from "./projects";
import { readableDate, queryParameters } from "helpers/helper";
import ProjectsFilters from "components/ProjectsFilters";
import { fetchRestaurant } from "api/v2/restaurants";
import { fetchEventLocation } from "api/v2/eventLocations";

const NOON = 12;

const asCentral = (date, startOfDay) =>
  date ? `${date}T${startOfDay ? "00:00:00" : "23:59:59"}.000-05:00` : null;

const asReadableDate = (date) => readableDate(`${date}T${NOON}:00:00`);

export const landingTitle = ({ run_on, starts, ends }) => {
  if (starts && ends) {
    return `Events for ${asReadableDate(starts)} through ${asReadableDate(
      ends
    )}`;
  } else if (run_on) {
    return `Events on ${asReadableDate(run_on)}`;
  } else {
    return `Upcoming Events`;
  }
};

const isPostalCode = (code) => !!`${code}`.match(/\d{5}/);

const Landing = ({ location }) => {
  const {
    near: explicitNear,
    starts,
    ends,
    run_on,
    event_location: explicitEventLocation,
    restaurant: explicitRestaurant,

    // deprecated
    restaurant_id,

    // deprecated
    postal_code,

    // deprecated
    professional_id
  } = queryParameters(location || history.location);

  const [restaurant, setRestaurant] = useState(explicitRestaurant);
  const [eventLocation, setEventLocation] = useState(explicitEventLocation);
  const [near, setNear] = useState(explicitNear);

  const explicitDate = (starts && ends) || run_on;

  const legacyRestaurant = !!(restaurant_id || professional_id);
  const legacyLocation = !!postal_code;
  const legacyNear = !!(explicitNear && isPostalCode(explicitNear));

  if (legacyRestaurant || legacyLocation || legacyNear) {
    const { data: restaurantData, isLoading: restaurantIsLoading } = useQuery(
      ["restaurant", restaurant_id || professional_id],
      fetchRestaurant,
      { enabled: legacyRestaurant }
    );

    const { data: eventLocationData, isLoading: eventLocationIsLoading } =
      useQuery(["eventLocation", postal_code], fetchEventLocation, {
        enabled: legacyLocation
      });

    const {
      data: eventLocationNearData,
      isLoading: eventLocationNearIsLoading
    } = useQuery(["eventLocationNear", near], fetchEventLocation, {
      enabled: legacyNear
    });

    if (
      legacyRestaurant &&
      !restaurant &&
      restaurantData?.slug &&
      !restaurantIsLoading
    ) {
      setRestaurant(restaurantData.slug);
    }

    if (
      legacyLocation &&
      !eventLocation &&
      eventLocationData?.slug &&
      !eventLocationIsLoading
    ) {
      setEventLocation(eventLocationData.slug);
    }

    if (
      legacyNear &&
      isPostalCode(near) &&
      eventLocationNearData?.slug &&
      !eventLocationNearIsLoading
    ) {
      setNear(eventLocationNearData.slug);
    }
  }

  const filters = {
    // From UI Filters
    restaurant,
    event_location: eventLocation,
    eventLocation: eventLocation,

    // From URL
    professional_id,
    near,
    run_on,
    run_at_since: explicitDate ? null : dayjs().format("YYYY-MM-DD"),
    aasm_state: "open"
  };

  // console.log("Filters", filters);

  const startsCentral = asCentral(starts, true);

  const endsCentral = asCentral(ends, false);

  return (
    <Container style={{ maxWidth: 850 }}>
      <div className="row mt-4">
        <div className="col-8">
          <Typography variant="h6" className="pt-4">
            {landingTitle({ run_on, starts, ends })}
          </Typography>
        </div>
      </div>

      <ProjectsFilters
        {...filters}
        onSelectEventLocation={setEventLocation}
        onSelectRestaurant={setRestaurant}
      />

      <Projects {...filters} starts={startsCentral} ends={endsCentral} />
    </Container>
  );
};

Landing.propTypes = {
  location: PropTypes.object
};

export default Landing;
