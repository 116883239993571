import React from "react";
import { createGlobalStyle } from "styled-components";
import PreflightStyles from "./preflight";

const CustomStyles = createGlobalStyle`
  body {
    transition: none
  }
`;

const GlobalStyles = () => (
  <>
    <link
      href="https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;700&display=swap"
      rel="stylesheet"
    />
    <CustomStyles />
    <PreflightStyles />
  </>
);

export default GlobalStyles;
