import React from "react";
import tw from "twin.macro";
import styled from "styled-components";

import { Button } from "components/typography";

import Popover, { usePopover } from "components/Popover";
import { useAuth } from "components/AuthProvider";

import { APP_STORE_URL } from "helpers/consts";

const HamburgerNav: React.FC = () => {
  const auth = useAuth();
  const {
    btnRef,
    popOverRef,
    isPopoverOpen: isCartOpen,
    setIsPopoverOpen: setIsCartOpen
  } = usePopover();

  return (
    <>
      <Button.TertiaryInfo
        tw="md:hidden"
        onClick={() => setIsCartOpen((prevState) => !prevState)}
        ref={btnRef}
      >
        <i className="fas fa-bars" tw="pr-1" />
      </Button.TertiaryInfo>
      <Popover
        hidden={!isCartOpen}
        targetRef={btnRef}
        ref={popOverRef}
        tw="z-30"
      >
        <div tw="mt-2 border border-secondary border-solid bg-white shadow rounded">
          <ul tw="p-0 m-0 list-none py-3">
            <NavLi>
              <Button.Tertiary
                tw="text-secondary-dark"
                as="a"
                href={APP_STORE_URL}
              >
                Get the App
              </Button.Tertiary>
            </NavLi>
            <NavLi>
              {auth.loggedIn ? (
                <Button.Tertiary
                  tw="text-secondary-dark"
                  as="a"
                  href="/dashboard"
                >
                  Dashboard
                </Button.Tertiary>
              ) : (
                <Button.Tertiary tw="text-secondary-dark" as="a" href="/browse">
                  Browse Groups
                </Button.Tertiary>
              )}
            </NavLi>
            <NavLi>
              <Button.Tertiary tw="text-secondary-dark" as="a" href="/account">
                {auth.loggedIn ? "Profile" : "Sign in"}
              </Button.Tertiary>
            </NavLi>
          </ul>
        </div>
      </Popover>
    </>
  );
};

export default HamburgerNav;

const NavLi = styled.li(tw`px-6 py-3`);
