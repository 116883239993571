import tw from "twin.macro";
import styled, { css } from "styled-components";
import * as P from "./Paragraph";

const BaseAlert = styled(P.Medium)(tw`
  border border-solid rounded 
  px-6 py-4`);

export const Danger = styled(BaseAlert)(() => [
  tw`text-danger border-danger bg-danger-bg`,
  css`
    & a {
      ${tw`text-danger underline`};
    }
  `
]);

export const Info = styled(BaseAlert)(() => [
  tw`text-info border-info bg-info-bg`,
  css`
    & a {
      ${tw`text-info underline`};
    }
  `
]);

export const Success = styled(BaseAlert)(() => [
  tw`text-success border-success bg-success-bg`,
  css`
    & a {
      ${tw`text-success underline`};
    }
  `
]);
