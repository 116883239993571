import React, { useState } from "react";
import "twin.macro";
import dayjs from "dayjs";

import {
  FormSection,
  Legend,
  Description
} from "screens/event/checkout/styles";
import Spinner from "components/spinner";
import { Button } from "components/typography";
import ErrorMessage from "components/forms/ErrorMessage";

import { useMutation, useQueryClient } from "react-query";
import { deleteOrder } from "api/v2/events";
import { retryOrder } from "api/v2/orders";
import { Event } from "api/v2/event.types";

import {
  PaymentElement,
  useStripe,
  useElements
} from "@stripe/react-stripe-js";

const PaymentForm: React.FC<{
  orderId: string;
  clientSecret: string;
  eventId: string;
  event: Event;
}> = ({ orderId, clientSecret, eventId, event }) => {
  const queryClient = useQueryClient();
  const stripe = useStripe();
  const elements = useElements();
  const [formIsComplete, setFormIsComplete] = useState(false);

  const [stripeError, setStripeError] = useState<string>();
  const [orderError, setOrderError] = useState<string>();
  const [isLoading, setIsLoading] = useState(false);

  const deleteOrderMutation = useMutation(deleteOrder);

  const retryOrderMutation = useMutation(retryOrder, {
    onSuccess: async (data) => {
      queryClient.invalidateQueries("event");

      if (!stripe || !elements) {
        setStripeError("Stripe not loaded. This shouldn't happen");
        return;
      }

      const { error } = await stripe.confirmPayment({
        elements,
        confirmParams: {
          return_url: `${location.protocol}//${window.location.hostname}/orders/${data.id}/receipt`
        }
      });

      if (error.message) {
        deleteOrderMutation.mutate(
          {
            eventId,
            orderId: data.orderId as string,
            retry: true
          },
          {
            onSuccess: () => {
              setStripeError(error.message);

              // fetch a new payment intent
              queryClient.invalidateQueries("paymentIntent");
            }
          }
        );
      }

      setIsLoading(false);
    },
    onError: (error) => {
      setOrderError(error as string);
    }
  });

  if (!stripe || !elements || deleteOrderMutation.isLoading) {
    return (
      <div tw="flex w-full justify-center p-6">
        <Spinner disabled={false} />
      </div>
    );
  }

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();

    setIsLoading(true);

    retryOrderMutation.mutate({
      id: orderId,
      clientSecret
    });
  };

  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      <FormSection tw="pt-4">
        <Description>
          <Legend hasError={!!stripeError || !!orderError} tw="pt-3">
            Payment
          </Legend>
          <ErrorMessage hasError={!!orderError}>{orderError}</ErrorMessage>
          <ErrorMessage hasError={!!stripeError}>{stripeError}</ErrorMessage>
        </Description>
        <div tw="flex flex-row gap-2">
          <PaymentElement
            id="payment-element"
            onChange={(e) => setFormIsComplete(e.complete)}
          />
        </div>
      </FormSection>
      <Button.Primary
        tw="w-full mt-6"
        type="submit"
        disabled={isLoading || !stripe || !elements || !formIsComplete}
        id="submit"
      >
        {isLoading || !stripe || !elements ? (
          <Spinner disabled={false} />
        ) : (
          `Resubmit payment for ${dayjs(event.runAt).format("dddd, MMM D")}`
        )}
      </Button.Primary>
    </form>
  );
};

export default PaymentForm;
