import React, { useState } from "react";
import "twin.macro";
import { Product } from "api/v2/menuCategories.types";

import { Dialog } from "@reach/dialog";
import "@reach/dialog/styles.css";

import {
  useCart,
  actionTypes as cartActionTypes,
  cartItem
} from "components/CartProvider";

import DescriptionPage from "./steps/descriptionPage";
import OptionsPage from "./steps/optionsPage";
import SinglePage from "./steps/singlePage";

import { FormValues, updateFormType, OPTION_FORM_PREFIX } from "./wizard.types";

const formOptionsToProviderOptions = (
  formOptions: FormValues["options"],
  product: Product
): cartItem["optionChoices"] =>
  formOptions &&
  Object.entries(formOptions).reduce((acc, [key, choiceId]) => {
    const parsedKey = parseInt(key.replace(OPTION_FORM_PREFIX, ""));
    const option = product.options.find((option) => option.id === parsedKey);

    return {
      ...acc,
      [parsedKey]: {
        choiceId: parseInt(choiceId),
        choiceName: option?.choices.find(
          (choice) => choice.id === parseInt(choiceId)
        )?.name,
        optionName: option?.name
      }
    };
  }, {});

const providerOptionsToFormOptions = (
  providerOptions: cartItem["optionChoices"]
): FormValues["options"] =>
  providerOptions &&
  Object.entries(providerOptions).reduce(
    (acc, [key, optionChoice]) => ({
      ...acc,
      [`${OPTION_FORM_PREFIX}${key}`]: optionChoice.choiceId.toString()
    }),
    {}
  );

const Wizard: React.FC<{
  product: Product;
  isOpen: boolean;
  onDismiss: () => void;
  eventId: string;
  cartItemIndex?: number;
}> = ({ product, isOpen, onDismiss, eventId, cartItemIndex }) => {
  const isEditing = typeof cartItemIndex === "number";
  const cart = useCart();
  const cartItem: cartItem = isEditing
    ? cart.state[eventId]?.items[cartItemIndex]
    : { quantity: 1, notes: "", product };
  const { optionChoices, ...cartItemWithoutOptions } = cartItem;

  const defaultFormValues = {
    options: providerOptionsToFormOptions(optionChoices),
    ...cartItemWithoutOptions
  };
  const [formValues, setFormValues] = useState<FormValues>(defaultFormValues);
  const [formStep, setFormStep] = useState(1);

  const resetFormAndDismiss = () => {
    setFormValues(defaultFormValues);
    setFormStep(1);
    onDismiss();
  };

  const updateForm: updateFormType = (newFormValues, isLastStep = false) => {
    const updatedValues = { ...formValues, ...newFormValues };
    setFormValues(updatedValues);

    if (isLastStep) {
      const itemValues = {
        product,
        quantity: updatedValues.quantity,
        optionChoices: formOptionsToProviderOptions(
          updatedValues.options,
          product
        ),
        notes: updatedValues.notes
      };

      resetFormAndDismiss();
      isEditing
        ? [0, "0"].includes(updatedValues.quantity)
          ? cart.dispatch({
              type: cartActionTypes.REMOVE,
              value: { cartId: eventId, itemIndex: cartItemIndex }
            })
          : cart.dispatch({
              type: cartActionTypes.UPDATE,
              value: {
                item: itemValues,
                cartId: eventId,
                itemIndex: cartItemIndex
              }
            })
        : cart.dispatch({
            type: cartActionTypes.ADD,
            value: { item: itemValues, cartId: eventId }
          });
    }
  };

  return (
    <Dialog
      isOpen={isOpen}
      onDismiss={resetFormAndDismiss}
      tw="p-0 w-11/12 md:w-1/3 rounded mt-28"
      aria-labelledby="menu-item-title"
    >
      {product.options.length < 3 ? (
        <SinglePage
          product={product}
          isEditing={isEditing}
          formValues={formValues}
          updateForm={updateForm}
          setFormStep={setFormStep}
          onDismiss={resetFormAndDismiss}
        />
      ) : (
        <>
          {formStep === 1 && (
            <DescriptionPage
              product={product}
              isEditing={isEditing}
              formValues={formValues}
              updateForm={updateForm}
              setFormStep={setFormStep}
              onDismiss={resetFormAndDismiss}
            />
          )}
          {formStep === 2 && (
            <OptionsPage
              product={product}
              isEditing={isEditing}
              formValues={formValues}
              updateForm={updateForm}
              setFormStep={setFormStep}
              onDismiss={resetFormAndDismiss}
            />
          )}
        </>
      )}
    </Dialog>
  );
};

export default Wizard;
