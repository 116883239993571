import React from "react";
import { Link } from "react-router-dom";

const Thanks = () => (
  <div className="d-flex flex-column flex-grow-1 align-items-center justify-content-center container">
    <div className="text-center justify-content-center">
      <h4 className="green justify-content-center">
        Thank you for registering
      </h4>
      <p className="text-center justify-content-center">
        Please check your inbox (or maybe spam folder) for your confirmation
        email and confirm within 7 days.
      </p>

      <div className="actions">
        <Link to="/dashboard" className="btn btn-primary">
          Get Dwellin!
        </Link>
      </div>
    </div>
  </div>
);

export default Thanks;
