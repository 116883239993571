import React from "react";
import PropTypes from "prop-types";
import connectComponent from "redux/connectComponent";
import { query } from "./data";

import ProjectsList from "components/ProjectsList";

const loadRemote = (actions) => (params) => () =>
  actions.loadProjectsBy(...query(params));

// Don't load a zip postal code. We use eventLocations
const nonNumericPostalCode = (code) => !`${code}`.match(/\d{5}/);
const nonNumericRestaurant = (rid, pid) => !pid || (pid && rid);

export const shouldLoad = (filters, meta) => {
  return (
    nonNumericPostalCode(filters.near) &&
    nonNumericRestaurant(filters.restaurant, meta.professional_id)
  );
};

export const Projects = ({
  aasm_state,
  actions,
  starts,
  ends,
  run_on,
  run_at_since,
  near,
  postal_code,
  restaurant,
  event_location,
  professional_id
}) => {
  const filters = {
    aasm_state,
    run_on,
    run_at_since,
    postal_code: near ? null : postal_code,
    near: near ? near : null,
    starts,
    ends,
    restaurant,
    event_location
  };

  return (
    <ProjectsList
      loadRemote={loadRemote(actions)(filters)}
      shouldLoad={shouldLoad(filters, { professional_id })}
      effectKey={Object.values(filters).join("_")}
    />
  );
};

Projects.propTypes = {
  aasm_state: PropTypes.string,
  actions: PropTypes.object,
  database: PropTypes.object,
  starts: PropTypes.string,
  ends: PropTypes.string,
  run_on: PropTypes.string,
  run_at_since: PropTypes.string,
  near: PropTypes.any,
  postal_code: PropTypes.any,
  restaurant: PropTypes.any,
  event_location: PropTypes.any,
  professional_id: PropTypes.any
};

export default connectComponent(Projects, ["projects"]);
