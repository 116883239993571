import React from "react";
import tw from "twin.macro";
import styled, { css } from "styled-components";

import { Event } from "api/v2/event.types";
import { toSentence } from "helpers/helper";
import { P } from "components/typography";
import dayjs from "dayjs";

const EventHeaderCard: React.FC<{ event: Event }> = ({ event }) => (
  <Card bgAvatar={event.image}>
    <P.Small tw="text-white font-bold">
      {dayjs(event.runAt).format("ddd, MMM D")}
    </P.Small>
    <P.Medium tw="text-white font-bold">{event.restaurantName}</P.Medium>
    <P.Small tw="text-white">
      <i className="fas fa-map-marker-alt" tw="mr-1" />
      {event.deliveryZone || toSentence(event.locations)}
    </P.Small>
  </Card>
);

export default EventHeaderCard;

interface CardProps {
  bgAvatar: string;
}
const Card = styled.section<CardProps>(() => [
  tw`rounded-md w-full bg-gray-700 p-6 bg-cover bg-center text-white flex flex-col justify-end`,
  css`
    background-image: linear-gradient(
        to top,
        rgba(0, 0, 0, 1),
        rgba(0, 0, 0, 0.4) 50%
      ),
      url(${(props: CardProps) => props.bgAvatar || "none"});
  `
]);
