import api, { extractError } from "api/api";

export const fetchAllMemberships = () => {
  return api
    .get(`/api/v2/memberships`)
    .then((resp) => resp.data?.memberships)
    .catch((e) => {
      throw extractError(e);
    });
};

export const fetchMembership = ({ queryKey }: any) => {
  const [_key, id] = queryKey;

  return api
    .get(`/api/v2/memberships/${id}`)
    .then((resp) => resp.data?.membership)
    .catch((e) => {
      throw extractError(e);
    });
};
