import React from "react";
import { withRouter, Route, Switch } from "react-router-dom";

import PrivateRoute from "../lib/authRouter/privateRoute";

import Account from "../screens/users/profile";

import AccountEdit from "../screens/users/edit";

import Browse from "../screens/browse";
import Restaurant from "../screens/restaurants";

import Dashboard from "../screens/dashboard";
import Landing from "../screens/landing";
import Password from "../screens/password";
import Event from "screens/event";
import Checkout from "screens/event/checkout";
import OrderRetryPayment from "screens/orders/retryPayment";
import Receipt from "screens/orders/receipt/receipt";
import Registration from "../screens/registration";
import Session from "../screens/session";
import Home from "../screens/home";
import Naperville from "../screens/home/naperville/index";
import Reset from "../screens/reset";

// Layout elements
import Header from "../screens/header";
import Footer from "../screens/footer";

// Static
import Faq from "../App/faq";
import Privacy from "../App/privacy";
import Terms from "../App/terms";
import Support from "../App/support";

// Rewrites
import Rewrites from "./rewrites";

// Missing
import FourOhFour from "screens/project/FourOhFour";

import FiveHundred from "screens/FiveHundred";
import OutOfArea from "screens/outOfArea";

import * as Sentry from "@sentry/react";

const SentryRoute = Sentry.withSentryRouting(Route);

const FooterlessLayout = () => (
  <React.Fragment>
    <SentryRoute
      exact={true}
      path="/404project"
      render={(props) => <FourOhFour {...props} />}
    />
  </React.Fragment>
);

const StandardLayout = () => (
  <React.Fragment>
    <Header />
    <PrivateRoute path="/dashboard" component={Dashboard} />
    <PrivateRoute path="/account" component={Account} exact={true} />
    <PrivateRoute path="/account/edit" component={AccountEdit} />
    <SentryRoute path="/browse" exact={true} component={Browse} />
    <SentryRoute path="/browse/:id" component={Browse} />

    <SentryRoute path="/restaurants/:id" component={Restaurant} />
    <SentryRoute path="/landing" component={Landing} />

    <SentryRoute path="/registration" component={Registration} />

    <SentryRoute path="/users/password" component={Password} />
    <SentryRoute path="/password" component={Password} />

    <SentryRoute path="/session" component={Session} />
    <SentryRoute exact={true} path="/ooa" component={OutOfArea} />

    <SentryRoute path="/faq" component={Faq} />
    <SentryRoute path="/privacy" component={Privacy} />
    <SentryRoute path="/terms" component={Terms} />
    <SentryRoute path="/support" component={Support} />
    <Footer />
  </React.Fragment>
);

const Routes = () => (
  <React.Fragment>
    <Rewrites />
    <Switch>
      <SentryRoute exact={true} path="/" component={Home} />
      <SentryRoute exact={true} path="/naperville" component={Naperville} />
      <SentryRoute exact={true} path="/reset" component={Reset} />
      <SentryRoute exact={true} path="/500" component={FiveHundred} />

      <SentryRoute
        exact={true}
        path="/orders/:id/receipt"
        component={Receipt}
      />
      <SentryRoute
        path={["/projects/:id/checkout", "/events/:id/checkout"]}
        component={Checkout}
      />
      <SentryRoute path={"/orders/:id/retry"} component={OrderRetryPayment} />
      <SentryRoute path={["/projects/:id", "/events/:id"]} component={Event} />
      <SentryRoute path="/404project" component={FooterlessLayout} />
      <SentryRoute path="/500" component={FooterlessLayout} />
      <SentryRoute component={StandardLayout} />
    </Switch>
  </React.Fragment>
);

export default withRouter(Routes);
