import React from "react";

const Sorry = () => (
  <React.Fragment>
    <div className="container">
      <section className="heading">
        <div className="content">
          <h5>Sorry, there are no groups in your area... yet!</h5>
        </div>
      </section>
    </div>

    <div className="container">
      <p>Check back for more opportunities soon!</p>
    </div>
  </React.Fragment>
);

export default Sorry;
