export default (state = {}, action) => {
  switch (action.type) {
    case "CHECK_AUTHENTICATION":
      return {
        ...state,
        checked: true
      };
    case "LOAD_ACCOUNT":
      return {
        ...state,
        userId: action.id,
        token: action.token || state.token,
        stripe_id: action.stripe_id
      };
    case "SET_USER_ID":
      return {
        ...state,
        userId: action.userId
      };
    case "SET_LEAD_TRACKING":
      return {
        ...state,
        leadTracking: { ...state.leadTracking, ...action.leadTracking }
      };
    case "UPDATE_HOME":
      return {
        ...state,
        home: action.home
      };
    case "SET_TAB":
      return {
        ...state,
        tab: action.tab
      };
    case "SET_CURRENT_ZIP":
      return {
        ...state,
        zip: action.zip
      };
    case "BUMP_VERSION":
      return {
        ...state,
        version: Date.now()
      };
    case "RESET_SESSION":
    case "RESET":
      return {};
    default:
      return state;
  }
};
