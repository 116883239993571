import React, { useState } from "react";
import "twin.macro";
import dayjs from "dayjs";
import { useHistory } from "react-router-dom";

import { FormSection, Legend, Description } from "./styles";
import Spinner from "components/spinner";
import { Button, P } from "components/typography";

import { useMutation } from "react-query";
import { createOrder } from "api/v2/events";
import { Event } from "api/v2/event.types";

import { useAuth } from "components/AuthProvider";
import { useCart } from "components/CartProvider";
import { OrderFormValues } from "./orderForm";

const PaymentCreditForm: React.FC<{
  orderFormValues: OrderFormValues;
  eventId: string;
  event: Event;
  d2d: boolean;
  promocode?: string;
}> = ({ eventId, orderFormValues, event, d2d, promocode }) => {
  const auth = useAuth();
  const cart = useCart();
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(false);

  const createOrderMutation = useMutation(createOrder, {
    onSuccess: async (data) => {
      history.push({
        pathname: `/orders/${data.id}/receipt`,
        state: {
          orderCreated: true
        }
      });
      setIsLoading(false);
    },
    onError: (error) => {
      history.push({
        pathname: `/events/${eventId}/checkout`,
        state: { error }
      });
    }
  });

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();

    setIsLoading(true);

    createOrderMutation.mutate({
      id: eventId,
      eventCart: cart.state[eventId],
      ...orderFormValues,
      leadTracking: auth.leadTracking || {},
      promocode: promocode || ""
    });
  };

  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      <FormSection>
        <Description>
          <Legend>Payment</Legend>
        </Description>
        <div>
          <P.Medium>This order will be paid using your credits.</P.Medium>
          <P.Medium> No Credit Card information needed.</P.Medium>
        </div>
      </FormSection>
      <Button.Primary
        tw="w-full mt-6"
        type="submit"
        disabled={isLoading}
        id="submit"
      >
        {isLoading ? (
          <Spinner disabled={false} />
        ) : (
          `Place ${d2d ? "delivery" : "pickup"} order for ${dayjs(
            event.runAt
          ).format("dddd, MMM D")}`
        )}
      </Button.Primary>
    </form>
  );
};

export default PaymentCreditForm;
