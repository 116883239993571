import React from "react";
import "twin.macro";

import { Redirect } from "react-router-dom";
import Spinner from "components/spinner";

import { useQuery } from "react-query";
import { createPaymentIntent } from "api/v2/paymentIntents";
import { PaymentIntent } from "api/v2/paymentIntents.types";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { Event } from "api/v2/event.types";

import PaymentForm from "./paymentForm";
import { OrderFormValues } from "./orderForm";

const stripePromise = process.env.REACT_APP_STRIPE_KEY
  ? loadStripe(process.env.REACT_APP_STRIPE_KEY)
  : null;

const PaymentFormPage: React.FC<{
  amount: number | string;
  orderFormValues: OrderFormValues;
  eventId: string;
  event: Event;
  d2d: boolean;
  promocode?: string;
}> = ({ amount, eventId, orderFormValues, event, d2d, promocode }) => {
  const { isLoading: paymentIntentIsLoading, data: paymentIntent } =
    useQuery<PaymentIntent>(["paymentIntent", amount], createPaymentIntent, {
      refetchOnWindowFocus: false
    });

  if (paymentIntentIsLoading || !paymentIntent) {
    return (
      <div tw="flex w-full justify-center p-6">
        <Spinner disabled={false} />
      </div>
    );
  }
  const clientSecret = paymentIntent.paymentIntent;

  if (!clientSecret) return <Redirect to="/404project" />;

  return (
    <Elements
      options={{
        clientSecret,
        appearance: {
          theme: "stripe"
        }
      }}
      stripe={stripePromise}
      key={clientSecret}
    >
      <PaymentForm
        eventId={eventId}
        orderFormValues={orderFormValues}
        clientSecret={clientSecret}
        event={event}
        d2d={d2d}
        promocode={promocode}
      />
    </Elements>
  );
};

export default PaymentFormPage;
