import React, { cloneElement, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { camelize, capitalize, pluralize } from "helpers/helper";
import connectComponent from "redux/connectComponent";
import Spinner from "components/spinner";

// Unlike ReactAdmin ReferenceArrayFieldField
// we assume 'children' is a template and don't rely
// on an intermediate collection distributor like DataGrid
const ReferenceArrayField = ({
  actions,
  children,
  database,
  filters,
  additionalFilters,
  render,
  sortBy,
  reference
}) => {
  const [results, setResults] = useState([]);

  const model = pluralize(null, reference);

  const unifiedFilters = filters || {};

  const sorter = sortBy || "name";

  const dbResults = database[model][camelize(model)](unifiedFilters);

  const objects = (dbResults.length ? dbResults : results).sort((a, b) =>
    a[sorter].localeCompare(b[sorter])
  );

  const defaultFilter = {
    filter: JSON.stringify({ ...unifiedFilters, ...(additionalFilters || {}) })
  };

  const effectKey = `${database.authentication.version()}-${reference}-${defaultFilter}-${render}`;

  useEffect(() => {
    if (render != false)
      actions[`load${capitalize(camelize(model))}`](defaultFilter)
        .then((data) => {
          setResults(data);
        })
        .catch(() => console.log("Error Loading", model));
  }, [effectKey]);

  if (render == false) return null;

  if (!objects) return <Spinner />;

  return cloneElement(children, { records: objects });
};

ReferenceArrayField.propTypes = {
  actions: PropTypes.object,
  additionalFilters: PropTypes.object,
  children: PropTypes.any,
  database: PropTypes.object,
  reference: PropTypes.string,
  filters: PropTypes.object,
  sortBy: PropTypes.string,
  render: PropTypes.bool
};

export default connectComponent(ReferenceArrayField, [
  "authentication",
  "avatars",
  "event_locations",
  "products",
  "projects",
  "professionals"
]);
