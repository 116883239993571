import React, { useState, DetailedHTMLProps, InputHTMLAttributes } from "react";
import tw, { theme } from "twin.macro";
import styled from "styled-components";

import { P } from "components/typography";

export interface RadioInputProps
  extends DetailedHTMLProps<
    InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  hasError?: boolean;
}

const RadioInput = React.forwardRef<HTMLInputElement, RadioInputProps>(
  ({ hasError = false, id, children, ...props }, ref) => {
    const [focusElementRef, setFocusElementRef] = useState<
      HTMLDivElement | undefined
    >();

    const setInputRef = (element: HTMLDivElement) => {
      setFocusElementRef(element);
    };

    const focusChildInput = () => {
      (focusElementRef?.firstElementChild as HTMLElement | undefined)?.focus();
    };
    if (hasError) {
      focusChildInput();
    }

    return (
      <div ref={setInputRef}>
        <StyledRadioInput type="radio" id={id} {...props} ref={ref} />
        <Radiolabel as="label" htmlFor={id} hasError={hasError}>
          {children}
        </Radiolabel>
      </div>
    );
  }
);
export default RadioInput;

// twin.macro/tailwind v2 doesn't support accent color (tailwind v3 does tho!)
// and neither does react's style prop, so we have to use actual css to do it
const StyledRadioInput = styled.input`
  accent-color: ${theme("colors.primary")};
`;

interface Radiolabel {
  hasError?: boolean;
}
const Radiolabel = styled(P.Small)<Radiolabel>(({ hasError }) => [
  tw`ml-2`,
  hasError && tw`text-danger`
]);
