import React, { useState } from "react";

import PropTypes from "prop-types";
import { Redirect } from "react-router-dom";
import connectComponent from "../../redux/connectComponent";
import { useForm } from "react-hook-form";

import { Error } from "components/errors";
import withWrap from "components/withWrap";
import Container from "components/container";

const AddHome = ({ actions }) => {
  const { register, handleSubmit, errors } = useForm();

  const [done, setDone] = useState(false);
  const [error, setError] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const [ooa, setOoa] = useState(false);

  const verify = (postal_code) => actions.validateHome(postal_code);

  const onSuccess = () => actions.refresh().then(() => setDone(true));

  const onFailure = (errorResponse) => {
    errorResponse.then((error) => setError(error));
  };

  const stopSubmitting = () => setSubmitting(false);

  const updateUser = (user) =>
    actions
      .updateUser(user)
      .then(onSuccess)
      .catch(onFailure)
      .finally(stopSubmitting);

  const onUpdate = (data) => {
    if (submitting) return;

    setSubmitting(true);

    const user = { postal_code: data.postal_code };

    return verify(user.postal_code)
      .then(() => true)
      .catch(() => false)
      .then((verified) => (verified ? updateUser(user) : setOoa(true)));
  };

  if (!submitting) {
    if (done) return <Redirect to="/dashboard" />;

    if (ooa) return <Redirect to="/ooa" />;
  }

  return (
    <Container>
      <form action="/" className="box-form" onSubmit={handleSubmit(onUpdate)}>
        <h4 className="box--headline">Almost done!</h4>
        <p className="desc--headline">
          Providing a zip code will allow us to provide you with local projects
          so you can join your neighbors and save money!
        </p>
        <div className="input-form">
          <label htmlFor="postal_code">Home Zip</label>
          <input
            type="text"
            className="form-control"
            ref={register({ required: true, pattern: /^\d{5}$/ })}
            id="postal_code"
            name="postal_code"
            autoComplete="postal-code"
          />
          {errors.postal_code && <Error error="Valid zipcode required" />}
          {error && <Error error={error} />}
        </div>

        <input
          type="submit"
          className="w-button"
          value={submitting ? "Submitting..." : "Finish"}
          data-testid="signup"
        />
      </form>
    </Container>
  );
};

AddHome.propTypes = {
  actions: PropTypes.object
};

export default withWrap(connectComponent(AddHome, ["authentication"]));
