import tw from "twin.macro";
import { css, StyledComponent } from "styled-components";

export const hoverableUnderline = [
  tw`
  mb-2 
  relative 
  after:content-[''] 
  after:absolute 
  after:block 
  after:w-full 
  after:h-1
  after:bg-primary
  after:origin-center
  after:transition
  after:duration-500
  after:ease`,
  `
    &::after {
      transform: scaleX(0)
    }

    &:hover::after, &:focus::after {
      transform: scaleX(1)
    }
  `
];

export const HoverableUnderlineContainer = (
  underlinedChild: StyledComponent<any, any>
) =>
  css`
    & ${underlinedChild}::after {
      transform: scaleX(0);
    }

    &:hover ${underlinedChild}::after, &:focus ${underlinedChild}::after {
      transform: scaleX(1);
    }
  `;
