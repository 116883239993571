const DEBUG = false;

const Log = (prefix, ...msg) => {
  DEBUG && console.log(`${prefix} :: `, ...msg); // eslint-disable-line
  return true;
};

export const TapLog =
  (...loggable) =>
  (tappable) => {
    Log(...loggable, tappable);
    // console.log(...loggable, tappable)
    return tappable;
  };

export default Log;
