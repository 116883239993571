import React from "react";
import "twin.macro";

import Project from "screens/project/project";

import Spinner from "components/spinner";

const defaultEmptyMessage = "We couldn't find any projects.";

const LoadingEvents: React.FC = () => (
  <h5>
    <Spinner disabled={false} />
  </h5>
);

const EmptyEvents: React.FC<{ message: string }> = ({ message }) => (
  <h5>{message || defaultEmptyMessage}</h5>
);

const Events: React.FC<{
  events: Array<{ id: number | string }>;
  loaded: boolean;
  emptyMessage: string;
}> = ({ events, loaded, emptyMessage }) =>
  !events.length ? (
    loaded ? (
      <EmptyEvents message={emptyMessage} />
    ) : (
      <LoadingEvents />
    )
  ) : (
    <section tw="flex flex-wrap justify-center">
      {events.map((event) => (
        <Project id={event.id} record={event} key={event.id} />
      ))}
    </section>
  );

export default Events;
