import React from "react";
import "twin.macro";

import { Redirect } from "react-router-dom";
import Spinner from "components/spinner";

import { useQuery } from "react-query";
import { createPaymentIntent } from "api/v2/paymentIntents";
import { PaymentIntent } from "api/v2/paymentIntents.types";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { Event } from "api/v2/event.types";

import PaymentForm from "./paymentForm";

const stripePromise = process.env.REACT_APP_STRIPE_KEY
  ? loadStripe(process.env.REACT_APP_STRIPE_KEY)
  : null;

const PaymentFormPage: React.FC<{
  amount: number | string;
  eventId: string;
  event: Event;
  orderId: string;
}> = ({ amount, eventId, event, orderId }) => {
  const { isLoading: paymentIntentIsLoading, data: paymentIntent } =
    useQuery<PaymentIntent>(["paymentIntent", amount], createPaymentIntent, {
      refetchOnWindowFocus: false
    });

  if (paymentIntentIsLoading || !paymentIntent) {
    return (
      <div tw="flex w-full justify-center p-6">
        <Spinner disabled={false} />
      </div>
    );
  }
  const clientSecret = paymentIntent.paymentIntent;

  if (!clientSecret) return <Redirect to="/404project" />;

  return (
    <Elements
      options={{
        clientSecret,
        appearance: {
          theme: "stripe"
        }
      }}
      stripe={stripePromise}
    >
      <PaymentForm
        eventId={eventId}
        clientSecret={clientSecret}
        event={event}
        orderId={orderId}
      />
    </Elements>
  );
};

export default PaymentFormPage;
