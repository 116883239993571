import React, { useEffect } from "react";
import "twin.macro";
import api, { extractError } from "api/api";
import { useQuery } from "react-query";
import Spinner from "components/spinner";
import { H } from "components/typography";
import { MenuCategory } from "api/v2/menuCategories.types";
import ShowMoreContainer from "components/ShowMoreContainer";
import MenuItem from "./menuItem";
import {
  useCart,
  actionTypes as cartActionTypes
} from "components/CartProvider";
const Menu: React.FC<{ eventId: string; showJoinButton: boolean }> = ({
  eventId,
  showJoinButton
}) => {
  const cart = useCart();
  const fetchMenuCategories = ({ queryKey }: any) => {
    const [_key, id] = queryKey;

    return api
      .get(`/api/v2/events/${id}/menu_categories`)
      .then((resp) => resp.data?.menuCategories)
      .catch((e) => {
        throw extractError(e);
      });
  };

  const { data: menuCategories, isLoading } = useQuery<MenuCategory[]>(
    ["menuCategories", eventId],
    fetchMenuCategories
  );

  useEffect(() => {
    if (!menuCategories) {
      return;
    }

    const menuProductIds = menuCategories.flatMap((menuCategory) =>
      menuCategory.products.map((product) => product.id)
    );

    const eventCart = cart.state[eventId];

    eventCart?.items.map((item, i) => {
      if (!menuProductIds.includes(item.product.id)) {
        cart.dispatch({
          type: cartActionTypes.REMOVE,
          value: { cartId: eventId, itemIndex: i }
        });
      }
    });
  }, [isLoading]);

  if (isLoading || !menuCategories) {
    return (
      <div tw="flex w-full justify-center p-6">
        <Spinner disabled={false} />
      </div>
    );
  }

  return (
    <section>
      <ShowMoreContainer
        showButtonContent="View More"
        hideButtonContent="View Less"
        truncatedHeight={520}
        showGradient
      >
        <ul tw="p-0 m-0 mt-8">
          {menuCategories.map((category) => (
            <li key={category.id} tw="list-none">
              <H.Two tw="my-4">{category.name}</H.Two>
              <ul tw="p-0 m-0 flex flex-wrap gap-6 justify-between">
                {category.products.map((product) => (
                  <MenuItem
                    product={product}
                    key={product.id}
                    eventId={eventId}
                    showJoinButton={showJoinButton}
                  />
                ))}
              </ul>
            </li>
          ))}
        </ul>
      </ShowMoreContainer>
    </section>
  );
};

export default Menu;
