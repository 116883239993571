import { createBrowserHistory, createMemoryHistory } from "history";

let history =
  process.env.NODE_ENV === "test" // eslint-disable-line
    ? createMemoryHistory({})
    : createBrowserHistory({});

// for tests
export const initializeHistory = () => {
  if (
    history.location &&
    history.location.state &&
    history.location.state.from
  ) {
    const state = { ...history.location.state };
    delete state.from;
    history.replace({ ...history.location, state });
    history.replace("/");
  }
};

export default history;
