import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import connectComponent from "../../redux/connectComponent";
import { readablePhone } from "../../helpers/helper";
import { Redirect } from "react-router-dom";

import { createToast, toastContainer } from "screens/shared/toast";
import Avatar from "screens/shared/avatar";

import PhoneField from "screens/shared/forms/phoneField";
import AddressAutocompleteField from "screens/shared/forms/addressAutocompleteField";

// form helpers to extract
const Label = (labelProps) => (
  <label htmlFor={labelProps.id}>{labelProps.children}</label>
);

const Input = (inputProps) => (
  <input
    id={inputProps.id}
    ref={inputProps.inputRef}
    required={inputProps.required}
    type={inputProps.type || "text"}
    className="form-control"
    defaultValue={inputProps.value}
  />
);

const Field = (fieldProps) => (
  <div className={"form-group " + fieldProps.className}>
    <Label id={fieldProps.id}>{fieldProps.label}</Label>
    <Input
      id={fieldProps.id}
      inputRef={fieldProps.inputRef}
      type={fieldProps.type || "text"}
      {...(fieldProps.inputProps || {})}
    />
  </div>
);

class UserShow extends PureComponent {
  static propTypes = {
    actions: PropTypes.object
  };

  state = {
    contact: {},
    passwordType: "password",
    eyeClass: "fa-eye-slash",
    loggedOut: false,
    submitted: false
  };

  constructor(props) {
    super(props);
    this.emailRef = React.createRef();
    this.phoneRef = React.createRef();
    this.passwordRef = React.createRef();
    this.fileRef = React.createRef();
    this.formRef = React.createRef();

    // Home fields for update
    this.streetRef = React.createRef();
    this.unitNumberRef = React.createRef();
    this.cityRef = React.createRef();
    this.stateRef = React.createRef();
    this.postalCodeRef = React.createRef();
  }

  componentDidMount() {
    this.loadUser(this.userId(this.props));
  }

  loadUser = (id) => id && this.props.actions.loadUser(id);

  userId = (props) => props.database.authentication.userId();

  user = (props) => props.database.authentication.user();

  dataWithAvatar = (data) => {
    const avatar = this.fileRef.current.files[0];

    if (avatar) {
      return { ...data, avatar };
    } else {
      return data;
    }
  };

  handleForm = (event) => {
    event.preventDefault();

    const home = {
      ...(Object.keys(this.state.contact).length
        ? this.state.contact
        : {
            street: this.streetRef.current.value,
            city: this.cityRef.current.value,
            state: this.stateRef.current.value,
            postal_code: this.postalCodeRef.current.value
          })
    };

    this.props.actions
      .updateUser(
        this.dataWithAvatar({
          id: this.userId(this.props),
          email: this.emailRef.current.value,
          phone: this.phoneRef.current.value,
          password: this.passwordRef.current.value,
          ...home
        })
      )
      .then(() => createToast("Account settings updated!"))
      .then(() => this.props.actions.checkAuthentication())
      .then(() => this.setState({ submitted: true }));

    return false;
  };

  toggleVisibility = (event) => {
    event.preventDefault();
    this.setState(
      this.state.passwordType === "text"
        ? { passwordType: "password", eyeClass: "fa-eye-slash" }
        : { passwordType: "text", eyeClass: "fa-eye" }
    );
    return false;
  };

  back = (event) => {
    event.preventDefault();
    this.props.actions.push("/account");
    return false;
  };

  logout = (event) => {
    event.preventDefault();
    this.setState({ loggedOut: true });
  };

  setAddress = (contact) => this.setState({ contact });

  // Autocomplete Address (for non-existing address)
  renderAddressField = (props) => {
    const user = this.user(props);

    return user.street?.length && user.postal_code?.length ? (
      <>
        <div className="form-row">
          <Field
            id="user-home-street"
            inputRef={this.streetRef}
            label="Street"
            inputProps={{
              size: 20,
              value: user.street,
              autoFill: "street-address",
              required: "required"
            }}
            className="col-md-8"
          />
        </div>
        <div className="form-row">
          <Field
            id="user-home-city"
            inputRef={this.cityRef}
            label="City"
            inputProps={{ value: user.city, required: "required" }}
            className="col-md-6"
          />
          <Field
            id="user-home-state"
            inputRef={this.stateRef}
            label="State"
            inputProps={{ value: user.state, required: "required" }}
            className="col-md-3"
          />
          <Field
            id="user-home-postal_code"
            inputRef={this.postalCodeRef}
            label="Postal Code"
            inputProps={{
              value: user.postal_code,
              autoFill: "postal-code",
              required: "required"
            }}
            className="col-md-3"
          />
        </div>
      </>
    ) : (
      <AddressAutocompleteField
        id="user_info_address"
        name="user_info[address]"
        placeholder="123 Main St, Northbrook, IL 90210"
        onChange={this.setAddress}
      />
    );
  };

  render() {
    const user = this.user(this.props);

    if (!user) return <div />;

    if (this.state.submitted) return <Redirect to="/account" />;
    if (this.state.loggedOut) return <Redirect to="/reset" />;

    return (
      <React.Fragment>
        {toastContainer()}
        <div className="container mt-4 row-pad-col-bottoms">
          <a href="/account" onClick={this.back}>
            BACK
          </a>
        </div>
        <div className="container card mt-4 row-pad-col-bottoms">
          <h5>Update Account</h5>
          <form
            action="/"
            onSubmit={this.handleForm}
            ref={this.formRef}
            encType="multipart/form-data"
          >
            <Avatar {...this.props} type="users" {...user} size="medium" />
            <div className="form-group mt-4">
              <input
                className="form-file-control"
                type="file"
                ref={this.fileRef}
              />
            </div>
            <div className="form-group">
              <Field
                id="user-email"
                inputRef={this.emailRef}
                label="Email"
                type="email"
                inputProps={{ required: true, value: user.email }}
              />
            </div>
            <div className="form-group">
              <PhoneField
                id="user_info_phone"
                title="Mobile Phone"
                defaultValue={readablePhone(user.phone)}
                name="user_info[phone]"
                inputRef={this.phoneRef}
                placeholder=""
              />
            </div>
            <div className="form-group">
              <label htmlFor="user_info_address">Address</label>
              {this.renderAddressField(this.props)}
            </div>
            <div className="form-group">
              <label htmlFor="user_info_password">
                Password &nbsp;
                <a href="/" onClick={this.toggleVisibility}>
                  <i
                    className={"fa " + this.state.eyeClass}
                    aria-hidden={true}
                  ></i>
                </a>
              </label>
              <input
                type={this.state.passwordType}
                id="user_info_password"
                name="user_info[password]"
                className="form-control"
                autoComplete="false"
                autofill="off"
                ref={this.passwordRef}
              />
            </div>

            <div className="form-group">
              <button className="btn btn-primary">Update</button>
            </div>

            <div className="form-group">
              <button
                data-testid="signout"
                className="btn btn-success"
                onClick={this.logout}
              >
                Logout
              </button>
            </div>
          </form>
        </div>
      </React.Fragment>
    );
  }
}

export default connectComponent(UserShow, ["authentication", "users"]);
