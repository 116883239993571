import { useState } from "react";
import jsonp from "jsonp";

// @ts-ignore: noImplicitAny
const MailchimpSubscribeEndpoint =
  "https://dwellsocial.us16.list-manage.com/subscribe/post-json?u=46c807bf5f0cec7ad38350a8a&id=a86a95c685&f_id=002c43e1f0";

const useMailingListSubscriptionApi = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmittedSuccessfully, setIsSubmittedSuccessfully] = useState(false);
  const [hasSubmissionErrors, setHasSubmissionErrors] = useState(false);

  const submit = ({ email, zip }: { email: string; zip: string }) => {
    setIsSubmitting(true);

    jsonp(
      `${MailchimpSubscribeEndpoint}&EMAIL=${encodeURIComponent(
        email
      )}&ZIP=${encodeURIComponent(zip)}`,
      { param: "c" },
      (err: any, data: any) => {
        if (!err) {
          console.log("MC Subscription", data);
          setIsSubmittedSuccessfully(true);
          setIsSubmitting(false);
        } else {
          setHasSubmissionErrors(true);
          setIsSubmitting(false);
        }
      }
    );
  };

  return {
    isSubmitting,
    isSubmittedSuccessfully,
    hasSubmissionErrors,
    submit
  };
};

export default useMailingListSubscriptionApi;
