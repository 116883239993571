import React from "react";
import Show from "./show";
import UpdateInfo from "screens/users/updateInfo";

import { killWrap, addWrap } from "helpers/helper";
import { Route, Switch } from "react-router-dom";

import * as Sentry from "@sentry/react";

const SentryRoute = Sentry.withSentryRouting(Route);

class Session extends React.PureComponent {
  componentDidMount() {
    // eslint-disable-line
    killWrap();
  }

  componentWillUnmount() {
    // eslint-disable-line
    addWrap();
  }

  render() {
    // eslint-disable-line
    return (
      <React.Fragment>
        <Switch>
          <SentryRoute
            exact={true}
            path="/session/update_info"
            component={UpdateInfo}
          />
          <SentryRoute path="/session" component={Show} />
        </Switch>
      </React.Fragment>
    );
  }
}
export default Session;
