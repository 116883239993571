// TODO: Think about updating state after a conversion
export default (state = {}, action) => {
  switch (action.type) {
    case "SET_EXPERIMENT_VARIANT":
      return {
        ...state,
        [action.name]: action.variant
      };
    case "RESET":
      return {};
    default:
      return state;
  }
};
