import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import connectComponent from "redux/connectComponent";
import Events from "./events";
import ProjectsFilters from "components/ProjectsFilters";

const OpenProjects = (props) => {
  const { actions } = props;

  const [loaded, setLoaded] = useState(false);
  const [restaurant, setRestaurant] = useState();
  const [eventLocation, setEventLocation] = useState();
  const [events, setEvents] = useState([]);

  const filterKey = `filter-${restaurant}-${eventLocation}`;

  useEffect(() => {
    const params = {};
    const hasParams = !!(restaurant || eventLocation);

    if (restaurant) params.restaurant = restaurant;

    if (eventLocation) params.event_location = eventLocation;

    actions
      .loadRelevantEvents(hasParams ? { filter: JSON.stringify(params) } : {})
      .then((events) => {
        setEvents(events);
        return setLoaded(true);
      });
  }, [filterKey]);

  return (
    <>
      <ProjectsFilters
        restaurant={restaurant}
        eventLocation={eventLocation}
        onSelectEventLocation={setEventLocation}
        onSelectRestaurant={setRestaurant}
      />

      <Events events={events} loaded={loaded} />
    </>
  );
};

OpenProjects.propTypes = {
  actions: PropTypes.object
};

export default connectComponent(OpenProjects, ["authentication"]);
