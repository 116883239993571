import { getOne, getOneBy, getAllBy } from "../db";

import { projectsByIds } from "./projects";

export const PROJECT_MEMBERSHIPS = [
  "project_memberships",
  "project_memberships"
];

const deprecatedUserId = (state) => getOne(["authentication"], "userId", state);

// Projects
export const projectMembershipForProject = (props, project_id) =>
  getOneBy(
    PROJECT_MEMBERSHIPS,
    {
      project_id,
      user_id: deprecatedUserId(props)
    },
    props
  );

export const projectMembershipsForUserId = (props, user_id) =>
  getAllBy(PROJECT_MEMBERSHIPS, { user_id }, props);

const projectMembershipsForUserIdByStatus = (props, user_id, status) =>
  getAllBy(PROJECT_MEMBERSHIPS, { user_id, status }, props);

const projectIdsForUserIdByStatus = (props, user_id, status) =>
  projectMembershipsForUserIdByStatus(props, user_id, status).map(
    (pm) => pm.project_id
  );

// Legacy

const projectsByStatus = (props, user_id, status) =>
  projectsByIds(props, projectIdsForUserIdByStatus(props, user_id, status));

// Projects - These are all 'my'
export const pendingProjects = (props) =>
  projectsByStatus(props, deprecatedUserId(props), "pending");

export const inactiveProjects = (props) =>
  projectsByStatus(props, deprecatedUserId(props), "inactive");
export const activeProjects = (props) =>
  projectsByStatus(props, deprecatedUserId(props), "active");

export const activeProjectIds = (props) =>
  projectIdsForUserIdByStatus(props, deprecatedUserId(props), "active");

export const completeProjectIds = (props) =>
  projectIdsForUserIdByStatus(props, deprecatedUserId(props), "complete");

// TODO: Fix for user profile
export const activeProjectsForUser = (props, user) =>
  projectsByStatus(props, user.id, "active");
