import React, { Component } from "react";
import PropTypes from "prop-types";
import { formElement } from "./formElement";

export class PhoneField extends Component {
  static propTypes = {
    onChange: PropTypes.func,
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    className: PropTypes.string,
    name: PropTypes.string,
    inputRef: PropTypes.any,
    onBlur: PropTypes.func
  };

  constructor(props) {
    super(props);
    this.phoneRef = props.inputRef || React.createRef();
  }

  componentDidMount() {
    this.onInput();
  }

  onInput = (event) => {
    let value = this.phoneRef.current.value;
    this.phoneRef.current.value = this.normalizePhone(value);

    if (this.props.onChange) {
      this.props.onChange(event);
    }
    if (event && this.props.onBlur) {
      this.props.onBlur();
    }
  };

  normalizePhone = (phone) => {
    let numbers = phone.replace(/\D/g, "");
    let charactersForIndex = { 0: "(", 3: ") ", 6: " - " };
    let normalized = "";

    for (var i = 0; i < numbers.length; i++) {
      normalized += (charactersForIndex[i] || "") + numbers[i];
    }

    return normalized;
  };

  render() {
    const { inputRef, ...props } = this.props; // eslint-disable-line no-unused-vars

    return (
      <input
        type="tel"
        onInput={this.onInput}
        onBlur={this.onInput}
        {...props}
        ref={this.phoneRef}
        id={this.props.id}
        name={this.props.name}
        className={
          "form-control" +
          (this.props.className ? " " + this.props.className : "")
        }
      />
    );
  }
}

export default formElement(PhoneField);
