import React from "react";
import "twin.macro";
import { shareToFacebook } from "api/external/facebook";
import { Button } from "components/typography";

import { optimizeEvent } from "actions/experiments";

export type FacebookShareButtonProps = {
  shareUrl: string;
  className?: string;
};

/* This componenent intentionally doesn't do anything in dev.
 * It is only designed to work in prod where it's hooked up
 * to a facebook endpoint
 */
const FacebookShareButton: React.FC<FacebookShareButtonProps> = ({
  className,
  shareUrl
}) => {
  const facebookInvite = () => {
    optimizeEvent("share", {
      event_category: "facebook",
      event_label: undefined,
      value: shareUrl
    });

    shareToFacebook(shareUrl);
  };

  return (
    <Button.Primary
      onClick={() => {
        facebookInvite();
      }}
      tw="bg-tertiary hover:bg-tertiary-dark"
      className={className}
    >
      <i className="fab fa-facebook" tw="pr-2" />
      Share to Facebook
    </Button.Primary>
  );
};

export default FacebookShareButton;
