import React from "react";
import "twin.macro";

import { P } from "components/typography";
import { ProductOption } from "api/v2/menuCategories.types";
import { FormValues, OPTION_FORM_PREFIX } from "./wizard.types";
import RadioInput from "components/forms/RadioInput";

import { DeepMap, FieldError } from "react-hook-form";

const OptionsRadio: React.FC<{
  option: ProductOption;
  errors: DeepMap<FormValues, FieldError>;
  register: any;
}> = ({ option, errors, register }) => (
  <fieldset tw="m-0 p-0 border-0">
    <P.Small as="legend" tw="font-bold pb-2">
      {option.name}
    </P.Small>
    {option.choices.map((choice) => (
      <RadioInput
        key={choice.id}
        name={`options.${OPTION_FORM_PREFIX}${option.id}`}
        id={`options.${OPTION_FORM_PREFIX}${option.id}-${choice.id}`}
        ref={register({ required: true })}
        type="radio"
        value={choice.id.toString()}
      >
        {choice.name}
      </RadioInput>
    ))}
    {errors.options?.[`${OPTION_FORM_PREFIX}${option.id}`] && (
      <P.Small tw="text-danger">Must select an option</P.Small>
    )}
  </fieldset>
);

export default OptionsRadio;
