import tw from "twin.macro";
import styled from "styled-components";

const Base = styled.a(
  tw`font-montserrat underline hover:underline bg-transparent border-none`
);
export const OnLight = Base;
export const OnDark = styled(Base)(
  tw`text-white hover:text-white hover:opacity-60`
);
export const Primary = styled(Base)(tw`text-primary hover:text-primary-dark`);
