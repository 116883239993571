import React from "react";
import PropTypes from "prop-types";
import connectComponent from "redux/connectComponent";
import * as Toast from "screens/shared/toast";
// Initial confirmation message is sent by backend via email
// All we need to present to the user is the resend form (if they come back after a week)
//
class Confirmation extends React.PureComponent {
  static propTypes = {
    actions: PropTypes.object
  };

  state = {
    confirmed: false,
    error: null
  };

  constructor(props) {
    super(props);
    this.emailRef = React.createRef();
  }

  resend = (event) => {
    event.preventDefault();
    this.props.actions
      .resendConfirmation(this.emailRef.current.value)
      .then(() => this.setState({ confirmed: true }))
      .catch(() => {
        Toast.createToast("Email does not need confirmation", { color: "red" });
      });
    return false;
  };

  render() {
    if (this.state.confirmed)
      return (
        <div className="container">
          <section className="heading">
            <div className="content">
              <h5>Check your email for a confirmation link</h5>
            </div>
          </section>
        </div>
      );

    return (
      <React.Fragment>
        <div className="container">
          <section className="heading">
            <div className="content">
              <h5>Resend confirmation instructions</h5>
            </div>
          </section>
        </div>

        <div className="container">
          <form action="/" onSubmit={this.resend}>
            <div className="form-group">
              <label htmlFor="email">Email</label>
              <input
                className="form-control"
                name="email"
                id="email"
                ref={this.emailRef}
                autoFocus={true}
                autoComplete={"email"}
                required="required"
              />
            </div>

            <div className="row mtb-4 text-center">
              <div className="col-12">
                <div className="btn-group" role="group" aria-label="confirm">
                  <button
                    type="submit"
                    name="commit"
                    className="btn btn-outline-success"
                    data-testid="resend"
                  >
                    Resend
                  </button>
                </div>
              </div>
            </div>
            <br />
          </form>
        </div>
      </React.Fragment>
    );
  }
}
export default connectComponent(Confirmation, []);
