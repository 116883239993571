export const pluralize = (count, str) =>
  count === null || count > 1
    ? str.match(/y$/i)
      ? str.replace(/y$/, "ies")
      : `${str}s`
    : str;

const extractId = (item) => item && `${item.id || item}`;

const extractIds = (a, b) => [a, b].map(extractId);

const compareIds = ([a, b]) => a && b && a === b;

const compareItemWithId = (id) => (item) => compareIds(extractIds(item, id)); // ((a,b) => ([extractId(a),extractId(b)])(item, id)  {

const handleIf = (handler, value) => (handler ? handler(value) : value);

const appendItem =
  (list, handler = null) =>
  (updatedItem) =>
    updatedItem.id
      ? list.map((item) =>
          handleIf(
            handler,
            compareItemWithId(item)(updatedItem)
              ? { ...item, ...updatedItem }
              : item
          )
        )
      : list;

// FIND
const arrayFind = (list, id) => list.find(compareItemWithId(id)); //item => `${item.id}` === `${id}`)

const objsFind = (objs, id) => objs[id];

export const find = (list, id) =>
  Array.isArray(list) ? arrayFind(list, id) : objsFind(list || {}, id);

// assumes array of objects (no object of objects yet)
export const findBy = (list, expectations) =>
  (list || []).find((obj) =>
    Object.entries(expectations || {}).every(
      (expectation) => `${obj[expectation[0]]}` === `${expectation[1]}`
    )
  );

export const findAllBy = (list, expectations) =>
  (list || []).filter((obj) =>
    Object.entries(expectations || {}).every((expectation) =>
      Array.isArray(expectation[1])
        ? find(expectation[1], obj[expectation[0]])
        : `${obj[expectation[0]]}` === `${expectation[1]}`
    )
  );

// UPDATE OR CREATE
const arrayUpdate = (list, updatedItem, handler = undefined) =>
  find(list, updatedItem)
    ? appendItem(list, handler)(updatedItem)
    : list.concat([handleIf(handler, updatedItem)]);

const objsUpdate = (list, updatedItem, handler = undefined) => {
  const mergedItem = Object.assign(find(list, updatedItem) || {}, updatedItem);

  list[updatedItem.id] = handleIf(handler, mergedItem);

  return list;
};

export const update = (list, updatedItem, handler = undefined) => {
  // eslint-disable-line
  // console.log("Updating ", list, updatedItem, handler)
  return Array.isArray(list)
    ? arrayUpdate(list, updatedItem, handler)
    : objsUpdate(list || {}, updatedItem, handler);
};

// REMOVE
const arrayRemove = (list, removedItem) =>
  removedItem
    ? list.filter((item) => !compareItemWithId(item)(removedItem))
    : list;

const objsRemove = (list, removedItem) =>
  Object.keys(list).reduce(
    (object, key) =>
      `${key}` !== `${removedItem.id}`
        ? { ...object, [key]: list[key] }
        : object,
    {}
  );

export const remove = (list, removedItem) =>
  Array.isArray(list)
    ? arrayRemove(list, removedItem)
    : objsRemove(list || {}, removedItem);

// Complex nested operations
// e.g.
// return {
//   ...state,
//   users: update(
//     state.users,
//     { id: action.id },
//     user => ({
//       ...user,
//       active_membership_project_ids: (
//         includeUser(
//           user.active_membership_project_ids,
//           action.id))}))}
//
// TURNS INTO
//
// return {
//    ...state,
//    users: removeFromNestedIdList(
//      state.users,
//      { id: action.id },
//      'active_membership_project_ids',
//      action.userId)

// REDUCER HELPERS - assumes list keyed like firebase object-list
export const excludeId = (ids, excludedId) =>
  (ids || []).filter((id) => `${id}` != `${excludedId}`);

export const includeId = (ids, includedId) => [
  ...new Set((ids || []).concat([includedId]))
];

export const addToNestedIdList = (objs, id, listKey, includedId) =>
  update(objs, { id }, (obj) => ({
    ...obj,
    [listKey]: includeId(obj[listKey], includedId)
  }));

export const addToNestedIdLists = (objs, id, listKeys, includedId) =>
  listKeys.reduce(
    (allObjs, listKey) => addToNestedIdList(allObjs, id, listKey, includedId),
    objs
  );

export const removeFromNestedIdList = (objs, id, listKey, excludedId) =>
  update(objs, { id }, (obj) => ({
    ...obj,
    [listKey]: excludeId(obj[listKey], excludedId)
  }));

const initialState = () => ({});

export const crudReducer =
  (name, initial = initialState, actions = () => ({})) =>
  (state = initial(), actionOpts) => {
    const { replace, ...action } = actionOpts;

    const pluralName = pluralize(null, name);

    // We include initial keys if they haven't, allowing them to be stomped
    // by current state

    const defaultCase = () => state;

    const cases = {
      [`LOAD_${pluralName.toUpperCase()}`]: () => ({
        ...initial(),
        ...state,
        [pluralName]: (action[pluralName] || []).reduce(
          (ac, item) => update(ac, item),
          replace ? [] : state[pluralName]
        )
      }),
      [`LOAD_${name.toUpperCase()}`]: () => ({
        ...initial(),
        ...state,
        [pluralName]: update(state[pluralName], action[name])
      }),
      [`REMOVE_${name.toUpperCase()}`]: () => ({
        ...initial(),
        ...state,
        [pluralName]: remove(state[pluralName], action[name])
      }),
      [`RESET_${pluralName.toUpperCase()}`]: () => ({ ...initial() }),

      RESET: () => ({ ...initial() }),
      ...actions(state, action)
    };

    const currentCase = cases[action.type] || defaultCase;

    // console.log("CurrentCase", currentCase, action.type, cases)

    return currentCase();
  };
