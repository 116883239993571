import { useState, useRef, useEffect } from "react";

import Popover from "@reach/popover";

export const usePopover = () => {
  const popOverRef = useRef<HTMLDivElement>(null);
  const btnRef = useRef<HTMLButtonElement>(null);

  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const handleClickOutside = (event: MouseEvent) => {
    if (
      !popOverRef.current?.contains(event.target as HTMLElement) &&
      !btnRef.current?.contains(event.target as HTMLElement)
    ) {
      setIsPopoverOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  });

  return { popOverRef, btnRef, isPopoverOpen, setIsPopoverOpen };
};

export default Popover;
