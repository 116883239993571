import tw from "twin.macro";
import styled from "styled-components";

export const ResponsiveFloatingBottomBar = styled.section(
  tw`
    border-0 border-solid border-secondary border-t 
    w-full bg-white py-6
    absolute rounded-b -bottom-1 px-6
  `
);

export const ModalArticle = styled.article(
  tw`h-auto max-h-[calc(100vh - 140px)] relative`
);

export const ScrollSection = styled.section(
  tw`overflow-y-scroll h-auto max-h-[calc(100vh - 140px)] pb-24`
);
