import Queue from "helpers/queue";
import { dig, digInstantiate, domMemoize, yieldSelf } from "helpers/helper";

// Required div for Places Library
const attributions = () => document.getElementById("attributions");

// Have the places/contacts libraries finished loading
const loaded = () => window.google;

const places = () =>
  digInstantiate(
    ["google", "maps", "places", "PlacesService"],
    window,
    attributions()
  );

const autocomplete = (element) =>
  digInstantiate(
    ["google", "maps", "places", "Autocomplete"],
    window,
    element,
    {}
  );

const maps = () => dig(["google", "maps"], window);

const onAutocompleteChange = (ac, callback) =>
  maps().event.addListener(ac, "place_changed", callback);

const withPlace = (callback) => (place) =>
  [
    console.log(place.address_components), // eslint-disable-line
    callback && callback(place)
  ][1];

const gpsc = () => domMemoize("gpsc")(places);

const queueRunner = () =>
  Queue({
    name: "GooglePlaces",
    loaded: () => loaded() && gpsc()
  });

export const withGooglePlaces = (callback) =>
  queueRunner().run(() => callback(gpsc()));

export const googleAutocomplete = (element, callback) =>
  queueRunner().run(() =>
    yieldSelf(autocomplete(element))((ac) =>
      onAutocompleteChange(ac, () =>
        yieldSelf(ac.getPlace())(withPlace(callback))
      )
    )
  );

// Google Places helpers
export const googleLocation = (lat, lng) =>
  new window.google.maps.LatLng(lat, lng);

// Callbacks invoked by Google upon library load, callable from init
const onGooglePlacesLoad = () => queueRunner().drain();
window.onGooglePlacesLoad = onGooglePlacesLoad;
