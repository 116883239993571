import React from "react";
import PropTypes from "prop-types";

import Project from "screens/project/project";

import Spinner from "components/spinner";

const defaultEmptyMessage = "We couldn't find any projects.";

const Projects = ({ ids, loaded, emptyMessage }) =>
  !ids.length ? (
    <h5>{loaded ? emptyMessage || defaultEmptyMessage : <Spinner />}</h5>
  ) : (
    ids.map((id) => <Project id={id} key={id} />)
  );

Projects.propTypes = {
  ids: PropTypes.array,
  loaded: PropTypes.bool,
  emptyMessage: PropTypes.string
};

export default Projects;
