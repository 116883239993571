import api, { extractError } from "api/api";

export const fetchEventLocation = ({ queryKey }: any) => {
  const [_key, id] = queryKey;

  return api
    .get(`/api/v2/event_locations/${id}`)
    .then((resp) => {
      return resp.data?.eventLocation;
    })
    .catch((e) => {
      throw extractError(e);
    });
};
