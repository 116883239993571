import React from "react";
import "twin.macro";

import { H, Button } from "components/typography";
import { Product } from "api/v2/menuCategories.types";

import { currencyFormatter } from "helpers/formatters";

import { useForm, SubmitHandler } from "react-hook-form";

import OptionsDropdown from "../optionsDropdown";
import OptionsRadio from "../optionsRadio";
import { FormValues, updateFormType } from "../wizard.types";
import {
  ResponsiveFloatingBottomBar,
  ModalArticle,
  ScrollSection
} from "./styles";

const OptionsPage: React.FC<{
  product: Product;
  onDismiss: () => void;
  formValues: FormValues;
  updateForm: updateFormType;
  setFormStep: React.Dispatch<React.SetStateAction<number>>;

  isEditing: boolean;
}> = ({
  product,
  onDismiss,
  formValues,
  updateForm,
  setFormStep,
  isEditing
}) => {
  const { handleSubmit, errors, watch, control, register } =
    useForm<FormValues>({
      defaultValues: formValues
    });

  const onSubmit: SubmitHandler<FormValues> = ({ options }) => {
    updateForm({ options }, true);
  };

  return (
    <ModalArticle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <ScrollSection>
          <div tw="flex flex-row justify-between px-6 pt-4">
            <button
              onClick={() => setFormStep(1)}
              tw="bg-transparent font-bold text-lg border-none"
              aria-label="previous"
            >
              <i className="fas fa-chevron-left" tw="text-muted"></i>
            </button>
            <button
              className="close-button"
              onClick={onDismiss}
              tw="bg-transparent font-bold text-lg border-none"
              aria-label="close"
            >
              <i className="fas fa-times" tw="text-muted"></i>
            </button>
          </div>
          <div tw="p-6 pt-0">
            <H.Three id="menu-item-title">{product.name}</H.Three>

            <div tw="flex flex-col gap-6">
              {product.options.map((option) =>
                option.choices.length > 3 ? (
                  <OptionsDropdown
                    control={control}
                    option={option}
                    errors={errors}
                    key={option.id}
                  />
                ) : (
                  <OptionsRadio
                    option={option}
                    register={register}
                    errors={errors}
                    key={option.id}
                  />
                )
              )}
            </div>
          </div>
        </ScrollSection>
        <ResponsiveFloatingBottomBar>
          <Button.Primary
            type="submit"
            tw="w-full"
            disabled={Object.keys(errors).length > 0}
          >
            {isEditing ? "Update " : "Add to "}Cart -{" "}
            {currencyFormatter(product.price * watch("quantity"))}
          </Button.Primary>
        </ResponsiveFloatingBottomBar>
      </form>
    </ModalArticle>
  );
};

export default OptionsPage;
