import Queue, { runWhen } from "helpers/queue";
import { compactObject } from "helpers/helper";

const analytics = () => window.analytics;

const analyticsQueueRunner = () =>
  Queue({
    name: "Analytics",
    loaded: () => analytics()
  });

const withAnalytics = (callback) =>
  analyticsQueueRunner().run(() => {
    try {
      return callback(analytics());
    } catch (err) {
      console.log("GA Err", err);
    }
  });

export const fromApiAuthUser = (user) => {
  if (!user) return {};

  const { home, currentUser, ...userAttributes } = user;

  const { id, ...homeAttributes } = home || {}; // eslint-disable-line

  return {
    ...userAttributes,
    ...homeAttributes
  };
};

export const asSegmentUser = (user) =>
  compactObject({
    ...user,
    address: {
      street: user.street,
      state: user.state,
      postalCode: user.postal_code,
      city: user.city
    },
    avatar: user.image,
    createdAt: user.created_at,
    firstName: user.firstname,
    lastName: user.lastname,
    id: user.uuid,
    utmsource: user.source,
    ZIP: user.postal_code
  });

const runActivate = () =>
  window.dataLayer && window.dataLayer.push({ event: "optimize.activate" });

// Analytics triggers
export const optimizeActivate = () => () =>
  runWhen({ duration: 200, when: () => window.dataLayer })(runActivate);

export const optimizeEvent =
  (...event) =>
  () =>
    withAnalytics((lib) => lib.track(...event));

export const trackPage = (page) => () => withAnalytics((lib) => lib.page(page));

export const identify = (user) => () => {
  const lib = analytics();

  if (lib) {
    lib.identify(user.uuid, asSegmentUser(fromApiAuthUser(user)));
  } else {
    console.log("Segment id failure");
  }
};
