import React, { Component } from "react";

//eslint-disable-next-line react/display-name
const withWrap = (WrappedComponent) => {
  // eslint-disable-line
  return class extends Component {
    // eslint-disable-line

    componentDidMount() {
      // eslint-disable-line
      document.body.classList.add("sessions");
    }

    componentWillUnmount() {
      // eslint-disable-line
      document.body.classList.remove("sessions");
    }

    render() {
      return (
        <React.Fragment>
          <div className="container_lg form-page">
            <div className="flex-container c-vh-100 justify-content-center align-items-center pt-3">
              <div className="second-col">
                <WrappedComponent {...this.props} />
              </div>
            </div>
          </div>
        </React.Fragment>
      );
    }
  };
};

export default withWrap;
