import React, { useState, useEffect, useRef } from "react";
import tw from "twin.macro";
import styled from "styled-components";

import { Button } from "components/typography";
import { useAuth } from "components/AuthProvider";
import HamburgerNav from "./hamburgerNav";

import FailedOrdersBanner from "screens/shared/ctaBanner/failedOrdersBanner";

import { APP_STORE_URL } from "helpers/consts";

const Header: React.FC<{
  sticky?: boolean;
}> = ({ sticky = false, children }) => {
  const auth = useAuth();

  const [isSticking, setisSticking] = useState(false);
  const navRef = useRef<HTMLDivElement>(null);

  const stickNavbar = () => {
    if (sticky && window !== undefined && navRef?.current) {
      setisSticking(window.scrollY > navRef.current.offsetTop);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", stickNavbar);
    return () => {
      window.removeEventListener("scroll", stickNavbar);
    };
  }, []);

  return (
    <>
      <StickyNav isSticking={isSticking} ref={navRef}>
        <div tw="p-6 shadow-md flex justify-between items-center bg-white">
          <Button.Tertiary tw="text-text" as="a" href="/">
            Dwell<span tw="text-primary">Social</span>
          </Button.Tertiary>
          <div tw="text-sm flex gap-6 items-center">
            <div tw="hidden md:flex gap-6">
              <Button.Tertiary
                tw="text-secondary-dark"
                as="a"
                href={APP_STORE_URL}
              >
                Get the App
              </Button.Tertiary>
              {auth.loggedIn ? (
                <Button.Tertiary
                  tw="text-secondary-dark"
                  as="a"
                  href="/dashboard"
                >
                  Dashboard
                </Button.Tertiary>
              ) : (
                <Button.Tertiary tw="text-secondary-dark" as="a" href="/browse">
                  Browse Groups
                </Button.Tertiary>
              )}
              <Button.Tertiary tw="text-secondary-dark" as="a" href="/account">
                {auth.loggedIn ? "Profile" : "Sign in"}
              </Button.Tertiary>
            </div>
            {children}
            <HamburgerNav />
          </div>
        </div>
        <FailedOrdersBanner />
      </StickyNav>
      {isSticking && <div style={{ height: navRef?.current?.offsetTop }} />}
    </>
  );
};

export default Header;

interface StickyNavProps {
  isSticking?: boolean;
}
const StickyNav = styled.div<StickyNavProps>(({ isSticking }) => [
  isSticking ? tw`fixed top-0 left-0 z-20 w-full` : tw`relative`
]);
