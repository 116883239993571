import React from "react";

const Privacy = () => (
  <div className="container card mt-4">
    <p className="p1">
      <span className="s1">
        <b>PRIVACY POLICY</b>
      </span>
    </p>
    <p className="p2">
      <span className="s1">Last Updated: February 5, 2018</span>
    </p>
    <p className="p2">
      <span className="s1">
        DwellSocial, Inc. (“DwellSocial,” “we,” “our” or “us”) is committed to
        respecting the privacy and security of your personal information. The
        following privacy policy (“Privacy Policy”) describes the information we
        collect and how we use it. By accessing our website or otherwise using
        our online tools or communication channels (collectively, the “Site”),
        you consent to our Privacy Policy.
      </span>
    </p>
    <p className="p2">
      <span className="s1">
        We may periodically update this Privacy Policy to reflect material
        changes in how we collect, use, share or store your personal
        information. We encourage you to refer to this Privacy Policy on an
        ongoing basis so that you understand our current Privacy Policy. You
        consent to any changes we make to this Privacy Policy if you continue to
        use the DwellSocial platform after receiving a notice of the change or
        upon our posting of the new Privacy Policy on this website.
        <span className="Apple-converted-space"> </span>
      </span>
    </p>
    <p className="p2">
      <span className="s1">
        <b>COLLECTING INFORMATION</b>
      </span>
    </p>
    <p className="p2">
      <span className="s1">
        Like many websites, we gather information about how visitors use our
        website. In general, you can visit many of our web pages without telling
        us who you are or revealing any personal information about yourself. We
        may track your visit for trends and statistics, but you will remain
        anonymous unless you tell us who you are. Once you choose to give us
        your personal information, you are not anonymous to us. For more
        information, see the “Navigation” section of this Privacy Policy below.
      </span>
    </p>
    <p className="p2">
      <span className="s1">
        We request information from you—which may include your name, address,
        telephone number, email address, age and other profile information
        relevant to our platform—when you:
      </span>
    </p>
    <ul className="ul1">
      <li className="li3">
        <span className="s2"></span>
        <span className="s1">Set up an account or profile</span>
      </li>
      <li className="li3">
        <span className="s2"></span>
        <span className="s1">Purchase services</span>
      </li>
      <li className="li3">
        <span className="s2"></span>
        <span className="s1">Upload content</span>
      </li>
      <li className="li3">
        <span className="s2"></span>
        <span className="s1">
          Request to receive communications or forms (including order forms)
        </span>
      </li>
      <li className="li3">
        <span className="s2"></span>
        <span className="s1">Provide feedback</span>
      </li>
      <li className="li3">
        <span className="s2"></span>
        <span className="s1">Enter into a dialogue on the Site</span>
      </li>
      <li className="li3">
        <span className="s2"></span>
        <span className="s1">
          Participate in a promotion or other website feature
        </span>
      </li>
      <li className="li3">
        <span className="s2"></span>
        <span className="s1">
          Participate in online discussions or utilize bulletin boards and
          message boards
        </span>
      </li>
      <li className="li3">
        <span className="s2"></span>
        <span className="s1">Contact us</span>
      </li>
      <li className="li3">
        <span className="s2"></span>
        <span className="s1">Take surveys</span>
      </li>
      <li className="li3">
        <span className="s2"></span>
        <span className="s1">
          Utilize chat rooms, instant messaging or forums (including news
          groups)
        </span>
      </li>
      <li className="li4">
        <span className="s2"></span>
        <span className="s1">Modify your account or profile</span>
      </li>
    </ul>
    <p className="p2">
      <span className="s1">
        Registered users’ dashboards will also request information concerning
        favorite professionals, to-do lists, friend connections, home project
        details and other user-generated content (e.g., social feeds).
      </span>
    </p>
    <p className="p2">
      <span className="s1">
        We may also collect online footprint information like your internet
        protocol (“IP”) address, browser type and access times through a log of
        all traffic on our web site. In order to better understand your
        preferences and better serve you, we may combine information you give us
        online with other information from DwellSocial sources, transactions and
        communications. We may also combine that information with data that is
        publicly available and data that we receive from other reputable
        sources. All data combined and stored with online data is protected by
        this Privacy Policy.<span className="Apple-converted-space"> </span>
      </span>
    </p>
    <p className="p2">
      <span className="s1">
        To provide you with certain social features, we may collect information
        about your contacts or friends from third party websites that provide
        access to this information, such as Facebook, LinkedIn, Twitter, or
        email services. We respect and follow the privacy policies of those
        third-party services with regard to the data we collect from them.
      </span>
    </p>
    <p className="p2">
      <span className="s1">
        <b>USING INFORMATION</b>
      </span>
    </p>
    <p className="p2">
      <span className="s1">
        We use the information we gather on the Site for the purposes of
        providing our platform, responding to any queries you may have,
        operating and improving the Site and fostering a positive user
        experience. Our platform includes the display of personalized and
        localized professional service offerings, products, content and
        advertising relating to your experiences and interests. The information
        we gather on the Site may be shared with third parties in order to
        deliver our platform.
      </span>
    </p>
    <p className="p2">
      <span className="s1">
        By registering with DwellSocial, populating and updating your profile or
        opting in when presented with choices, you have consented for us to use
        your information to:
      </span>
    </p>
    <ul className="ul1">
      <li className="li3">
        <span className="s2"></span>
        <span className="s1">
          Check for appropriate usage of the platform as defined in our Terms of
          Service
        </span>
      </li>
      <li className="li3">
        <span className="s2"></span>
        <span className="s1">
          Customize and/or personalize your communications experience
        </span>
      </li>
      <li className="li3">
        <span className="s2"></span>
        <span className="s1">
          Better respond to your customer service and support inquiries
        </span>
      </li>
      <li className="li3">
        <span className="s2"></span>
        <span className="s1">
          Communicate with you about your purchase, account information, or
          customer service needs
        </span>
      </li>
      <li className="li3">
        <span className="s2"></span>
        <span className="s1">
          Communicate with you about our products and events, and for other
          promotional purposes
        </span>
      </li>
      <li className="li3">
        <span className="s2"></span>
        <span className="s1">
          Display targeted advertising, announcements, sponsorships or other
          messages
        </span>
      </li>
      <li className="li3">
        <span className="s2"></span>
        <span className="s1">
          Notify of new features, answer questions from contact or feedback
          pages
        </span>
      </li>
      <li className="li3">
        <span className="s2"></span>
        <span className="s1">Improve collaboration among users</span>
      </li>
      <li className="li3">
        <span className="s2"></span>
        <span className="s1">Improve our business and internal operations</span>
      </li>
      <li className="li3">
        <span className="s2"></span>
        <span className="s1">
          Administer competitions, contests, promotions, surveys or other
          website features
        </span>
      </li>
      <li className="li3">
        <span className="s2"></span>
        <span className="s1">Improve our software and content</span>
      </li>
      <li className="li3">
        <span className="s2"></span>
        <span className="s1">Allow you to create an account</span>
      </li>
      <li className="li3">
        <span className="s2"></span>
        <span className="s1">
          Customize the content and/or layout of the web page for each
          individual visitor
        </span>
      </li>
      <li className="li3">
        <span className="s2"></span>
        <span className="s1">Notify you about updates to the Site</span>
      </li>
      <li className="li3">
        <span className="s2"></span>
        <span className="s1">
          Contact you for marketing purposes (which you may opt out of at any
          time)
        </span>
      </li>
      <li className="li3">
        <span className="s2"></span>
        <span className="s1">
          Create a profile for you based on information that you have provided
          to us
        </span>
      </li>
      <li className="li4">
        <span className="s2"></span>
        <span className="s1">
          In order to help us rank the importance of future items to build into
          our platform
        </span>
      </li>
    </ul>
    <p className="p2">
      <span className="s1">
        We will not use your personal information to advertise or communicate
        promotional offers, directly or indirectly, using direct mail marketing
        or telemarketing, unless you give us permission to do so. We will
        collect personally identifiable profile information only from web site
        operators with which we have contractual relationships. We will provide
        reasonable access to profile information that is personally identifiable
        or is associated with personally identifiable information.
      </span>
    </p>
    <p className="p2">
      <span className="s1">
        We may extend any of our rights under this Privacy Policy to third
        parties performing services on our behalf.
      </span>
    </p>
    <p className="p2">
      <span className="s1">
        <b>SHARING INFORMATION</b>
      </span>
    </p>
    <p className="p2">
      <span className="s1">
        We enable other users of our site to view or receive information from
        your profile in order to facilitate the exchange of professional service
        referrals and offerings. Users who connect on the Site can see each
        other’s full profile (including address); to-do list; activity feed
        (including the ability to start conversations, see and participate in
        other conversations, and see uploads); work completed; and engage in
        direct messaging.<span className="Apple-converted-space">  </span>Users
        who are{" "}
      </span>
      <span className="s3">not</span>
      <span className="s1">
        {" "}
        connected can see each other’s name, city (but not address), favorite
        professionals, number of connections, mutual connections and
        (anonymously, with no names associated with it) home location and
        address on-platform.
      </span>
    </p>
    <p className="p2">
      <span className="s1">
        For the purpose of operating our business, we may transfer information
        between DwellSocial and our preferred service providers who use that
        information only to conduct DwellSocial business. For example, they may
        handle our credit card processing, data management, email distribution,
        market research, information analysis and promotions management. We
        provide our preferred service providers with the information they need
        to perform their services and work with them to respect and protect your
        information.<span className="Apple-converted-space"> </span>
      </span>
    </p>
    <p className="p2">
      <span className="s1">
        We may share your profile information with registered professionals and
        unregistered third parties in order for them to contact users with
        product and service suggestions, sponsored posts and other advertising
        methods within our platform. We do not support or facilitate any
        off-platform marketing or advertising to users.
        <span className="Apple-converted-space"> </span>
      </span>
    </p>
    <p className="p2">
      <span className="s1">
        We may provide your information to our wholly-owned companies and
        affiliates, which may be outside the United States. We may also sell or
        transfer your personal information to third parties in connection with
        any merger, acquisition, reorganization, change in capital structure or
        sale of all or substantially all of the assets of DwellSocial.
      </span>
    </p>
    <p className="p2">
      <span className="s1">
        We may sell or transfer non-individualized information to third parties,
        including summary or aggregated anonymous information about all or
        sub-groups of users of our Site.
      </span>
    </p>
    <p className="p2">
      <span className="s1">
        On rare occasions, we may disclose specific information upon
        governmental request, in response to a court order, when required by
        law, to enforce our website policies, or to protect our or others'
        rights, property, or safety. We may also share information with
        companies assisting in fraud protection or investigation. We do not
        provide information to these agencies or companies for marketing or
        commercial purposes.
      </span>
    </p>
    <p className="p2">
      <span className="s1">
        <b>PROTECTING YOUR INFORMATION</b>
      </span>
    </p>
    <p className="p2">
      <span className="s1">
        We use a variety of security measures, including sophisticated
        encryption and authentication tools to maintain the safety of your
        personal information. Regardless of these efforts, no data transmission
        over the Internet can be guaranteed to be 100% secure. Your personal
        information is contained behind secure networks and is only accessible
        to a limited number of persons who have special access rights to such
        systems, and are required to keep the information confidential. When you
        place orders or access your personal information, we offer the use of a
        secure server. Credit card transactions for paid services are through a
        third-party processor, and we do not store your credit card information
        ourselves. Your credit card information is used for purposes of
        processing and completing only those transactions you approve, and the
        information will be disclosed to third parties only as necessary to
        complete a specific transaction. This data is protected and stored by
        the third-party processor. We are never able to access see or display
        more than the card type, the last 4 digits of the credit card number,
        the name of the holder and the expiration date. The processors do not
        save the CVV codes. The processor charges your credit card and deposits
        the money to our account. All processes adhere to PCI-DSS Level 1.
      </span>
    </p>
    <p className="p2">
      <span className="s1">
        Our site may contain links to the websites of one or more third parties.
        We are not responsible for the privacy practices or the content of these
        other websites.
      </span>
    </p>
    <p className="p2">
      <span className="s1">
        Advertising agencies, advertising networks, and other companies who may
        place advertisements on our site may use their own cookies, pixel tags,
        web beacons, web bugs and other technology to collect information about
        you. We do not control these companies' use of such technology and we
        have no responsibility for the use of such technology by these companies
        to gather information about you.
      </span>
    </p>
    <p className="p2">
      <span className="s1">
        <b>NAVIGATION</b>
      </span>
    </p>
    <p className="p2">
      <span className="s1">
        Like other interactive websites, we collect information about your use
        of our site and our services using cookies, log files, and techniques
        used to track who is reading a web page or e-mail, when, and from what
        computer, such as web bugs, web beacons or pixel tags.
      </span>
    </p>
    <p className="p2">
      <span className="s1">
        A cookie is a small removable data file that is stored by your web
        browser on your computer. Cookies allow you to place an order on our
        website and allow us to enhance and personalize your online browsing and
        shopping experience.
      </span>
    </p>
    <p className="p2">
      <span className="s1">For example, we use cookies to:</span>
    </p>
    <ul className="ul1">
      <li className="li3">
        <span className="s2"></span>
        <span className="s1">
          Keep track of shopping cart/record session information
        </span>
      </li>
      <li className="li3">
        <span className="s2"></span>
        <span className="s1">Recognize you when you return to our website</span>
      </li>
      <li className="li3">
        <span className="s2"></span>
        <span className="s1">
          Keep track of click stream data (record user-specific information on
          what pages users access or visit)
        </span>
      </li>
      <li className="li3">
        <span className="s2"></span>
        <span className="s1">Personalize the Site</span>
      </li>
      <li className="li3">
        <span className="s2"></span>
        <span className="s1">So that the platform will function properly</span>
      </li>
      <li className="li4">
        <span className="s2"></span>
        <span className="s1">Analyze visitor behavior</span>
      </li>
    </ul>
    <p className="p2">
      <span className="s1">
        We may track information with pixel images on the Site. You can choose
        to have your computer warn you each time a cookie is being sent or you
        can choose to turn off all cookies. You do this through your browser
        settings. Each browser is a little different, so look at your browser
        Help menu to learn the correct way to modify your cookie settings.
        <span className="Apple-converted-space"> </span>
      </span>
    </p>
    <p className="p2">
      <span className="s1">
        Pixel tags, also known as web beacons or web bugs, are tiny graphic
        images (GI) that are unobtrusively placed on a website or in an e-mail.
        When the HTML code for the GI points to a site to retrieve the image, it
        simultaneously passes information to the our servers, such as the IP
        address of the computer that retrieved the image, the time and duration
        the GI was viewed, the type of browser that retrieved the image, and
        previously set cookie values.
      </span>
    </p>
    <p className="p2">
      <span className="s1">
        We use GI to help us analyze your online behavior. We may use GI to
        collect information about your visit, including the pages you view, the
        links you click and other actions taken in connection with our sites and
        services. We also collect certain standard information that your browser
        sends to every website you visit, such as your IP address, browser type
        and language, access times and referring web site addresses. We also use
        GI to allow us to send you email in a format you can read and to let us
        know when you have opened an email message from us.
      </span>
    </p>
    <p className="p2">
      <span className="s1">
        We also work with other companies who use tracking technologies to serve
        ads on our behalf across the Internet. These companies may collect
        non-personally identifiable information about your visits to our website
        and your interaction with our communications, including advertising. If
        you would like to opt-out of allowing third-party companies to collect
        non-personally identifiable information about you during your visits to
        our site, please contact us at info@dwellsocial.com to update your
        privacy settings.
      </span>
    </p>
    <p className="p2">
      <span className="s1">
        <b>HOW TO REVIEW, MODIFY OR DELETE YOUR INFORMATION</b>
      </span>
    </p>
    <p className="p2">
      <span className="s1">
        We want to communicate with you only if you want to hear from us. If you
        prefer not to receive information from us or would like to update or
        change your personal information or preferences, follow the instructions
        below to review, modify or delete your personal information.
      </span>
    </p>
    <p className="p2">
      <span className="s1">
        To modify the information you have provided during registration, please
        login and update your personal settings.
      </span>
    </p>
    <p className="p2">
      <span className="s1">
        To unsubscribe from an email, please follow the instructions in any
        email you receive.<span className="Apple-converted-space"> </span>
      </span>
    </p>
    <p className="p2">
      <span className="s1">
        If you request your registration information be deleted or if you
        unsubscribe from communications, we may maintain information about sales
        transactions or service inquiries for future service and record keeping
        purposes.
      </span>
    </p>
    <p className="p2">
      <span className="s1">
        <b>
          QUESTIONS, COMPLAINTS AND FEEDBACK
          <span className="Apple-converted-space"> </span>
        </b>
      </span>
    </p>
    <p className="p2">
      <span className="s1">
        We welcome your questions, comments and concerns about privacy. Please
        contact us by email at:<span className="Apple-converted-space"> </span>
      </span>
    </p>
    <p className="p3">
      <span className="s1">
        <b>DwellSocial, Inc.</b>
      </span>
    </p>
    <p className="p3">
      <span className="s1">Attn: Privacy Policy</span>
    </p>
    <p className="p2">
      <span className="s1">
        info@dwellsocial.com
        <b>
          <span className="Apple-converted-space"> </span>
        </b>
      </span>
    </p>
    <p className="p2">
      <span className="s1">
        <b>TERMS OF SERVICE</b>
      </span>
    </p>
    <p className="p2">
      <span className="s1">
        Please also visit our Terms of Service (
        <a href="http://www.dwellsocial.com/pages/terms">
          <span className="s4">http://www.dwellsocial.com/pages/terms</span>
        </a>
        ), which explain other terms governing the use of our website.
        <span className="Apple-converted-space"> </span>
      </span>
    </p>
  </div>
);

export default Privacy;
