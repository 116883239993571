import React from "react";
import "twin.macro";

import { P, H } from "components/typography";
import ShowMoreContainer from "components/ShowMoreContainer";

const Details: React.FC<{ descriptionHtml: string }> = ({
  descriptionHtml
}) => (
  <section tw="mt-6">
    <H.Two tw="mb-2">Details</H.Two>
    <ShowMoreContainer
      showButtonContent="Show Full Details"
      hideButtonContent="Hide Details"
      truncatedHeight={70}
    >
      <P.Medium
        dangerouslySetInnerHTML={{ __html: descriptionHtml }}
        as="div"
      />
    </ShowMoreContainer>
  </section>
);
export default Details;
