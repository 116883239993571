import React from "react";
import PropTypes from "prop-types";
import { withRouter, Redirect, Route } from "react-router-dom";
import * as Sentry from "@sentry/react";

const SentryRoute = Sentry.withSentryRouting(Route);

const paths = [
  ["/u/CarolineGau", "https://mailchi.mp/dwellsocial/carolinegau"],
  ["/u/carolinegau", "https://mailchi.mp/dwellsocial/carolinegau"]
];

const ExternalRedirect = (props) => {
  window.location.href = props.to;
  return null;
};

ExternalRedirect.propTypes = {
  to: PropTypes.any
};

const Rewrites = () => (
  <React.Fragment>
    {paths.map((pathPair, i) => (
      <SentryRoute
        key={i}
        exact={true}
        path={pathPair[0]}
        render={() => <ExternalRedirect to={pathPair[1]} />}
      />
    ))}

    <SentryRoute
      key="dash"
      path="/users/auth"
      render={() => <Redirect to="/dashboard" />}
    />
  </React.Fragment>
);

export default withRouter(Rewrites);
