import React from "react";
import "twin.macro";

import { Link } from "react-router-dom";
import { H, P, A, Alert } from "components/typography";
import ShareEventButton from "components/composites/ShareEvent/inviteButton";
// import Spinner from "components/spinner";
import { readableDate } from "helpers/helper";
import { currencyFormatter } from "helpers/formatters";
import { Membership } from "api/v2/memberships.types";

import OrderSummary from "./orderSummary";
// import Browse from "./browse";

const fulfillmentDate = (membership: Membership) =>
  membership.d2d
    ? readableDate(membership.eventRunAt, true)
    : membership.pickupTime;

const Body: React.FC<{ flashMessage?: string; membership: Membership }> = ({
  flashMessage,
  membership
}) => (
  <>
    {flashMessage && (
      <Alert.Success tw="font-bold mt-16 w-11/12 md:w-3/4 mx-auto">
        {flashMessage}
      </Alert.Success>
    )}
    <article tw="mx-auto px-10" style={{ maxWidth: 800 }}>
      <section tw=" flex flex-col justify-center items-center w-full border-0 border-b border-solid border-secondary pt-16">
        <i className="fas fa-utensils" tw="text-xl pb-2"></i>
        <H.Two as="h1">{membership.restaurantName} is headed your way!</H.Two>
        <P.Medium tw="pb-6">
          Great pick. We’re excited to bring you some of Chicago’s best food.
        </P.Medium>
      </section>
      <section
        tw="py-6 border-0 border-b border-solid border-secondary"
        id="pickup-details"
      >
        <H.Three as="h2">
          {membership.d2d ? "Delivery" : " Pickup"} details
        </H.Three>
        <div tw="flex flex-col md:flex-row gap-2 md:gap-6 pt-2 mb-6">
          <P.Medium tw="flex flex-row items-baseline">
            <i className="fas fa-calendar-day" tw="mr-1" />
            {fulfillmentDate(membership)}
          </P.Medium>
          <P.Medium tw="flex flex-row items-baseline">
            <i className="fas fa-map-marker-alt" tw="mr-1" />
            {membership.d2d
              ? membership.customerAddress
              : `${membership.pickupStreet}, ${membership.pickupCityStateZip}`}
          </P.Medium>
        </div>
        {membership.d2d ? (
          ""
        ) : (
          <>
            {membership.pickupLocationAvatar && (
              <img src={membership.pickupLocationAvatar} tw="w-full rounded" />
            )}
            <P.Medium tw="whitespace-pre-line pt-6">
              {membership.pickupInstructions}
            </P.Medium>
          </>
        )}
      </section>
      <OrderSummary
        lineItems={membership.lineItems}
        notes={membership.orderNotes}
        totalPaid={membership.totalPaid}
      />
      {membership.failedOrder && (
        <section tw="py-6 border-0 border-b border-solid border-secondary">
          <Alert.Danger tw="font-bold flex flex-row justify-between ">
            <span>
              Your {currencyFormatter(membership.totalPaid)} payment for{" "}
              {membership.restaurantName} on{" "}
              {readableDate(membership.eventRunAt)} failed.{" "}
            </span>
            <Link
              to={`/orders/${membership.failedOrder}/retry`}
              tw="font-medium"
              component={A.OnDark}
            >
              Resubmit payment.
            </Link>
          </Alert.Danger>
        </section>
      )}
      <section
        tw="border-0 border-solid border-secondary border-t
          w-full bg-white py-6
          fixed bottom-0 left-0 px-6 z-10
          md:(static px-0 border-0)
          flex flex-col md:flex-row justify-between items-center py-6 gap-4"
      >
        <P.Medium tw="font-bold">
          Your friends might like {membership.restaurantName}:
          <span tw="block font-montserrat pb-0 mb-0 text-xs pb-0 font-normal">
            You each get $10 after they place their first order.
          </span>
        </P.Medium>
        <ShareEventButton
          shareUrl={membership.shareUrl}
          eventId={membership.eventId.toString()}
          primaryBtn
        >
          Share this event with a friend
        </ShareEventButton>
      </section>
    </article>
  </>
);

export default Body;
