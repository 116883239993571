import React from "react";

import Projects from "screens/browse/projects";
import "twin.macro";
import { H } from "components/typography";
import { toSentence } from "helpers/helper";

const PROJECT_COUNT = 4;

const Browse: React.FC<{
  postalCodes: string[];
  locations: string[];
  eventId: string;
}> = ({ postalCodes, locations, eventId }) => (
  <section tw="flex flex-col py-10 bg-secondary items-center">
    <i className="fas fa-utensils" tw="text-xl pb-2"></i>
    <H.Three as="h2" tw="pb-4 w-5/6 md:w-auto text-center">
      Browse more Dwelliveries around {toSentence(locations)}
    </H.Three>
    <section tw="w-full flex flex-col md:flex-row items-center justify-center">
      <Projects
        count={PROJECT_COUNT}
        v2={true}
        postalCodes={postalCodes}
        excludeIds={[eventId]}
      />
    </section>
  </section>
);

export default Browse;
