import React from "react";
import "twin.macro";

import { Event, CheckoutDetails } from "api/v2/event.types";

import { Link } from "react-router-dom";

import { useCart } from "components/CartProvider";
import { Button } from "components/typography";

import EventHeaderCard from "./eventHeaderCard";

import OrderSummaryList, { CartLi } from "components/composites/OrderSummary";

const parsePrice = (price: string | number) =>
  typeof price === "string" ? parseFloat(price) : price;

const OrderSummary: React.FC<{
  eventId: string;
  event: Event;
  checkoutDetails?: CheckoutDetails;
  isLoading: boolean;
}> = ({ eventId, event, checkoutDetails, isLoading }) => {
  const cart = useCart();

  const eventCart = cart.state[eventId];
  const productLineItems = eventCart.items.map((item) => ({
    name: item.product.name,
    price: item.product.price,
    notes: item.notes,
    quantity: item.quantity,
    optionChoices: item.optionChoices
      ? Object.values(item.optionChoices)
      : null,
    sku: null
  }));

  const nonProductLineItems = checkoutDetails?.nonProductLineItems
    ?.filter((li) => !["credit", "promo_code", "d2d"].includes(li.sku))
    .map((li) => ({
      ...li,
      quantity: 1,
      price: parsePrice(li.price)
    }));
  const creditLineItem = checkoutDetails?.nonProductLineItems?.find(
    (li) => li.name === "Credit"
  );
  const promoCodeLineItem = checkoutDetails?.nonProductLineItems?.find(
    (li) => li.name === "Promo Code"
  );
  const d2dLineItem = checkoutDetails?.nonProductLineItems?.find(
    (li) => li.sku === "d2d"
  );

  return (
    <aside tw="w-full lg:w-1/5 bg-secondary py-10 px-6 mb-24 md:mb-0">
      <EventHeaderCard event={event} />
      <OrderSummaryList
        isLoading={isLoading}
        productLineItems={productLineItems}
        nonProductLineItems={nonProductLineItems}
        promoCodeLineItem={
          promoCodeLineItem && {
            ...promoCodeLineItem,
            quantity: 1,
            price: parsePrice(promoCodeLineItem?.price)
          }
        }
        creditLineItem={
          creditLineItem && {
            ...creditLineItem,
            quantity: 1,
            price: parsePrice(creditLineItem?.price)
          }
        }
        d2dLineItem={
          d2dLineItem && {
            ...d2dLineItem,
            quantity: 1,
            price: parsePrice(d2dLineItem?.price)
          }
        }
        subtotal={checkoutDetails?.baseCost}
        totalWithGratuity={checkoutDetails?.preadjustmentTotal}
        totalDue={checkoutDetails?.total}
      >
        <CartLi>
          <Link
            to={`/events/${eventId}`}
            component={(props) => (
              <Button.Info as="a" {...props} tw="w-full text-center py-3" />
            )}
          >
            <i className="fas fa-pencil-alt" /> Edit your order
          </Link>
        </CartLi>
      </OrderSummaryList>
    </aside>
  );
};

export default OrderSummary;
