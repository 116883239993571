import React from "react";
import { Link } from "react-router-dom";
import "twin.macro";

import { useAuth } from "components/AuthProvider";
import { Card } from "components/cards";
import { H, P, A } from "components/typography";
import Clipboard, { ClipboardButton } from "components/Clipboard";
import { createToast } from "screens/shared/toast";
import { readablePhone } from "helpers/helper";
import { currencyFormatter } from "helpers/formatters";

import Memberships from "./memberships";
import * as Api from "api/api";
import * as Button from "components/typography/Button";

const Profile: React.FC = () => {
  const { loggedIn, user } = useAuth();

  if (!loggedIn) return null;

  if (!user)
    return (
      <div className="container">
        <div className="empty">
          <i className="fas fa-user-times fa-5x"></i>
          <h5>That user was not found.</h5>
        </div>
      </div>
    );

  const { referral_url, currentUser, token } = user || {};

  const isCurrentUser = `${user.id}` === `${currentUser?.id}`;

  const onCopySuccess = () => createToast("Referral Link Copied");

  const portalSessionUrl = Api.urlFor("/api/v2/users/stripe_portal");

  return (
    <article
      tw="w-full mx-auto flex flex-col gap-6 px-6"
      style={{ maxWidth: 850 }}
    >
      <H.Three
        as="h1"
        tw="py-12 pb-6 border border-0 border-b border-solid border-secondary"
      >
        My Account
      </H.Three>
      <Card.Tertiary tw="gap-4 flex flex-col md:flex-row">
        <div tw="md:w-1/5">
          <P.Medium tw="font-bold">Account Details</P.Medium>
          {isCurrentUser && (
            <Link to="/account/edit" component={A.Primary}>
              Change
            </Link>
          )}
        </div>
        <div tw="grid grid-cols-1 md:grid-cols-2 md:w-4/5 gap-4">
          <div tw="flex flex-row">
            <i className="fas fa-calendar-day" tw="mr-4 text-muted" />
            <P.Medium>{user.name}</P.Medium>
          </div>
          <div tw="flex flex-row">
            <i className="fas fa-phone" tw="mr-4 text-muted" />
            <P.Medium>{readablePhone(user.phone)}</P.Medium>
          </div>
          <div tw="flex flex-row">
            <i className="fas fa-envelope" tw="mr-4 text-muted" />
            <P.Medium>{user.email}</P.Medium>
          </div>
          <div tw="flex flex-row">
            <i className="fas fa-home" tw="mr-4 text-muted" />
            <P.Medium>
              {user && ![user.street, user.city, user.state].includes(null)
                ? `${user.street} ${user.city}, ${user.state} ${user.postal_code}`
                : "Home address is not set"}
            </P.Medium>
          </div>
        </div>
      </Card.Tertiary>
      <Card.Tertiary tw="gap-4 flex flex-col md:flex-row">
        <P.Medium tw="font-bold md:w-1/5">Credit Balance</P.Medium>
        <div tw="flex flex-row">
          <i className="fas fa-coins" tw="mr-4 text-muted" />
          <P.Medium>
            {currencyFormatter(Math.abs(parseFloat(user.credit_balance)))}
          </P.Medium>
        </div>
      </Card.Tertiary>
      <Card.Tertiary tw="gap-4 flex flex-col md:flex-row md:items-center">
        <P.Medium tw="font-bold md:w-1/5">Referral Link</P.Medium>
        <div tw="flex flex-col md:flex-row w-full md:w-4/5 gap-2 md:items-center">
          <Clipboard
            id="share-link-copy-url"
            content={referral_url}
            onSuccess={onCopySuccess}
            tw="m-0"
          >
            {referral_url.split("&utm")[0]}
          </Clipboard>
          <ClipboardButton
            content={referral_url}
            onSuccess={onCopySuccess}
            tw="min-w-max px-4 self-stretch"
          >
            <i className="fas fa-link" tw="mr-1"></i>Share via URL
          </ClipboardButton>
        </div>
      </Card.Tertiary>

      <Card.Tertiary tw="gap-4 flex flex-col md:flex-row md:items-center">
        <P.Medium tw="font-bold md:w-1/5">Manage Billing</P.Medium>
        <div tw="flex flex-col md:flex-row w-full md:w-4/5 gap-2 md:items-center">
          <i className="fas fa-credit-card" tw="mr-4 text-muted" />
          <form method="POST" action={portalSessionUrl}>
            <input type="hidden" name="token" value={token} />
            <Button.Tertiary>Update Card</Button.Tertiary>
          </form>
        </div>
      </Card.Tertiary>

      <Memberships />
    </article>
  );
};

export default Profile;
