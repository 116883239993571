import React from "react";
import PropTypes from "prop-types";
import UserAvatar from "./userAvatar";
import SiteAvatar from "./site";

export const Avatar = ({ actions, database, type, id, ...props }) => {
  const avatar = type && id;

  if (!type || !id) return null;

  if (type.match(/user/)) {
    return <UserAvatar id={id} {...props} />;
  } else {
    return !avatar ? null : <SiteAvatar src={avatar.src} {...props} />;
  }
};

Avatar.propTypes = {
  actions: PropTypes.object,
  database: PropTypes.object,
  id: PropTypes.any, // If we're only given a site user id
  name: PropTypes.string, // If we're only given a name
  url: PropTypes.string, // If we're just handed an image url
  size: PropTypes.string, // medium,small
  type: PropTypes.string,
  naked: PropTypes.bool
};

export default Avatar;
