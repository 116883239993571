import React, { useEffect } from "react";
import PropTypes from "prop-types";
import connectComponent from "redux/connectComponent";
import { Tabs, Tab } from "../../App/shared/tabs";
import Spinner from "components/spinner";
import { addWrap } from "helpers/helper";

import Container from "components/container";
import SectionHeading from "components/sectionHeading";
import TabCount from "./tabCount";
import OpenProjects from "./openProjects";
import JoinedProjects from "./joinedProjects";
import CompletedProjects from "./completedProjects";

const Dashboard = ({ database }) => {
  const authed = database.authentication.authenticated();

  const tab = database.authentication.tab();

  useEffect(() => {
    document.body.classList.remove("sessions");
    addWrap();
  }, []);

  if (!authed) return <Spinner />;

  const initialTab =
    {
      open: 0,
      "in-progress": 1,
      completed: 2
    }[tab] || 0;

  return (
    <Container style={{ maxWidth: 850 }}>
      <SectionHeading data-testid="dashboard">Dashboard</SectionHeading>
      <Tabs title="projects" initial={initialTab}>
        <Tab title="Open" count={<TabCount type="open" />}>
          <OpenProjects />
        </Tab>
        <Tab title="In Progress" count={<TabCount type="active" />}>
          <JoinedProjects />
        </Tab>
        <Tab title="Completed" count={<TabCount type="complete" />}>
          <CompletedProjects />
        </Tab>
      </Tabs>
    </Container>
  );
};

Dashboard.propTypes = {
  authentication: PropTypes.object,
  database: PropTypes.object
};

export default connectComponent(Dashboard, ["authentication"]);
