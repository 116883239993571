import React from "react";

import Projects from "screens/browse/projects";
import "twin.macro";
import { H } from "components/typography";

const DEFAULT_POSTAL_CODE = "60540"; // Naperville
const PROJECT_COUNT = 4;

const Partners: React.FC = () => (
  <section tw="flex flex-col py-10 bg-secondary items-center">
    <i className="fas fa-utensils" tw="text-xl pb-2"></i>
    <H.Three as="h2" tw="pb-4">
      Chicago’s Best, Brought to You.
    </H.Three>
    <section tw="w-full flex flex-col md:flex-row items-center justify-center">
      <Projects
        browse
        count={PROJECT_COUNT}
        v2={true}
        postalCode={DEFAULT_POSTAL_CODE}
      />
    </section>
  </section>
);

export default Partners;
