import * as authentication from "./database/authentication";
import * as event_locations from "./database/event_locations";
import * as projects from "./database/projects";
import * as line_items from "./database/line_items";
import * as memberships from "./database/memberships";
import * as professionals from "./database/professionals";
import * as relevance from "./database/relevance";
import * as users from "./database/users";
import * as time_slots from "./database/time_slots";
import * as session from "./db/session";

const libraries = {
  authentication,
  event_locations,
  projects,
  line_items,
  memberships,
  professionals,
  relevance,
  session,
  time_slots,
  users
};

const withState = (libs) => (state) =>
  Object.keys(libs).reduce(
    (l, lib) => ({
      ...l,
      [lib]: (...args) =>
        !args.length
          ? libs[lib](state)()
          : args.reduce((res, step) => res(step), libs[lib](state))
    }),
    {}
  );

const database = (state) =>
  Object.keys(libraries).reduce(
    (ac, k) => ({
      ...ac,
      [k]: withState(libraries[k])(state)
    }),
    {}
  );

export default database;
