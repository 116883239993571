import React from "react";
import "twin.macro";

import { LineItem } from "api/v2/memberships.types";
import { H, P } from "components/typography";
import { currencyFormatter } from "helpers/formatters";

import NonProductLi from "./nonProductLi";

const OrderSummary: React.FC<{
  lineItems: LineItem[];
  notes: string | null;
  totalPaid: string | number;
}> = ({ lineItems, notes, totalPaid }) => {
  const { productLineItems, nonProductLineItems, creditLineItem } =
    lineItems.reduce<{
      productLineItems: LineItem[];
      nonProductLineItems: LineItem[];
      creditLineItem?: LineItem;
    }>(
      (acc, li) => {
        if (li.productName) {
          return { ...acc, productLineItems: [...acc.productLineItems, li] };
        } else if (li.sku === "credit") {
          return { ...acc, creditLineItem: li };
        } else {
          return {
            ...acc,
            nonProductLineItems: [...acc.nonProductLineItems, li]
          };
        }
      },
      { productLineItems: [], nonProductLineItems: [] }
    );

  const subtotal = productLineItems.reduce(
    (sum, cur) => sum + cur.price * cur.quantity,
    0
  );

  const total = nonProductLineItems.reduce(
    (sum, cur) => sum + cur.price,
    subtotal
  );

  return (
    <section
      tw="pt-6 border-0 border-b border-solid border-secondary"
      id="order-summary"
    >
      <H.Three as="h2">Your order</H.Three>
      <ul tw="list-none p-0">
        {productLineItems.map((lineItem) => (
          <li
            key={lineItem.id}
            tw="flex flex-row justify-between items-center border-0 border-b border-solid border-secondary py-4"
          >
            <div tw="flex flex-row items-center h-inherit gap-4">
              {lineItem.productAvatarThumb ? (
                <img src={lineItem.productAvatarThumb} tw="w-16 rounded" />
              ) : (
                <div tw="w-16" />
              )}
              <div>
                <P.Medium tw="font-medium">
                  {lineItem.quantity} x {lineItem.productName}
                </P.Medium>
                {lineItem.optionChoices && (
                  <P.Small tw="text-muted">
                    {lineItem.optionChoices
                      .map(
                        (choice) => `${choice.optionName}: ${choice.choiceName}`
                      )
                      .join(", ")}
                  </P.Small>
                )}
                {lineItem.notes && (
                  <P.Small tw="pt-4 text-muted">{lineItem.notes}</P.Small>
                )}
              </div>
            </div>
            <P.Medium tw="">
              {currencyFormatter(lineItem.price * lineItem.quantity)}
            </P.Medium>
          </li>
        ))}
      </ul>
      {notes && (
        <P.Medium tw="text-muted border-0 border-b border-solid border-secondary pb-4 mb-2">
          <span tw="text-text">Notes: </span>
          <br />
          {notes}
        </P.Medium>
      )}
      <ul tw="list-none p-0 pb-4 mb-0">
        <NonProductLi name="Subtotal" price={subtotal} />
        {nonProductLineItems.map((lineItem) => (
          <NonProductLi
            key={lineItem.id}
            name={lineItem.sku}
            price={lineItem.price}
          />
        ))}
      </ul>
      <ul tw="list-none p-0 mb-0 pb-4">
        {creditLineItem && (
          <>
            <NonProductLi
              name="Total"
              price={total}
              tw="border-0 border-t border-solid border-secondary py-6"
            />
            <NonProductLi
              name="Credit"
              price={creditLineItem.price}
              tw="border-0 border-t border-solid border-secondary py-6"
            />
          </>
        )}
        <NonProductLi
          name="Total Paid"
          price={
            typeof totalPaid === "string" ? parseFloat(totalPaid) : totalPaid
          }
          tw="border-0 border-t border-solid border-secondary pt-6"
        />
      </ul>
    </section>
  );
};

export default OrderSummary;
