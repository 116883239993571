import Queue from "helpers/queue";
import { promise } from "helpers/helper";

export const facebookLibrary = () => window.FB;

const loaded = () => facebookLibrary();

const queueRunner = () =>
  Queue({
    name: "Facebook",
    loaded
  });

export const apiCall = (...args) =>
  queueRunner().run(() => facebookLibrary().api(...args));

export const uiCall = (...args) =>
  queueRunner().run(() => facebookLibrary().ui(...args));

// We don't use callbacks for this
export const facebookLogout = () =>
  promise((resolve, reject) =>
    queueRunner().run(() =>
      facebookLibrary().getLoginStatus((statusResponse) =>
        statusResponse && statusResponse.status === "connected"
          ? facebookLibrary().logout(resolve)
          : reject(new Error("Not Logged in"))
      )
    )
  );

const onFacebookLoad = () => queueRunner().drain();

window.onFacebookLoad = onFacebookLoad;
