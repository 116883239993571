import React from "react";
import PropTypes from "prop-types";

const handleFilter = (onClick, keyField) => (tag) => (event) => {
  event.preventDefault();
  return onClick(tag[keyField]);
};

export class Filters extends React.Component {
  state = {
    typedAhead: ""
  };

  onTypeahead = (event) => {
    const typedAhead = this.state.typedAhead || "";

    const key = event.key;
    if (key == "Backspace" || key == "Delete") {
      this.setState({ typedAhead: typedAhead.slice(0, -1) });
    } else if (key.length == 1 && key.match(/[a-zA-Z0-9 ]/)) {
      this.setState({ typedAhead: typedAhead + key });
    } else if (key == "Escape") {
      this.setState({ typedAhead: "" });
    } else {
      // console.log("Doing nothing with", key)
    }
  };

  filterByTypeahead = (tags) =>
    this.state.typedAhead.length
      ? tags.filter((tag) =>
          tag[this.props.field || "name"].includes(this.state.typedAhead)
        )
      : tags;

  colorize = (name, matched) => {
    const pieces = name.split(matched);

    return pieces.map((piece, i) => (
      <React.Fragment key={i}>
        <span>{piece}</span>
        {i === pieces.length - 1 ? "" : <b>{matched}</b>}
      </React.Fragment>
    ));
  };

  colorizeWithTypeahead = (name) =>
    this.state.typedAhead.length
      ? this.colorize(name, this.state.typedAhead)
      : name;

  render() {
    const props = this.props;

    const tags = this.filterByTypeahead(props.tags);

    const keyField = props.keyField || "id";

    if (tags.length == 0) {
      tags.push({ [keyField]: "none", [props.field]: "No Results" });
    }

    const selectedTag =
      !props.tag || !props.tag.slug
        ? {}
        : tags.find((t) => t[keyField] === props.tag?.slug) || {} || {};

    return (
      <div className="btn-group" onKeyDown={this.onTypeahead}>
        <button
          className="btn btn-dropdown btn-sm dropdown-toggle"
          type="button"
          data-toggle="dropdown"
        >
          {props.label || "Type"}
          {selectedTag?.slug
            ? ": " + selectedTag[props.field || "name"] || ""
            : ""}
        </button>
        <div ref={this.dropDownRef} className="dropdown-menu">
          {props.allowBlank ? (
            <a
              href="/"
              key="blank"
              className="dropdown-item"
              onClick={handleFilter(props.onSelect, keyField)({})}
            >
              &nbsp;
            </a>
          ) : (
            ""
          )}
          {tags.map((tag, i) => (
            <a
              key={tag[keyField] || i}
              href="/"
              className={
                "dropdown-item " +
                (props.tag && `${tag[keyField]}` === `${props.tag[keyField]}`
                  ? "active"
                  : "")
              }
              onClick={handleFilter(props.onSelect, keyField)(tag)}
            >
              {this.colorizeWithTypeahead(tag[props.field || "name"])}
            </a>
          ))}
        </div>
      </div>
    );
  }
}

export class Search extends React.PureComponent {
  constructor(props) {
    super(props);
    this.searcherRef = React.createRef();
  }

  onChange = (event) => {
    event.preventDefault();
    this.props.onSearch(this.searcherRef.current.value);
  };

  render() {
    // eslint-disable-line
    return (
      <div className="search">
        <input
          type="text"
          placeholder={this.props.placeholderText}
          ref={this.searcherRef}
          onChange={this.onChange}
        />
      </div>
    );
  }
}

Filters.propTypes = {
  label: PropTypes.string,
  field: PropTypes.string,
  tags: PropTypes.array,
  tag: PropTypes.any,
  keyField: PropTypes.string,
  onSelect: PropTypes.func,
  allowBlank: PropTypes.bool
};

Search.propTypes = {
  placeholderText: PropTypes.string,
  onSearch: PropTypes.func
};

export default {
  Filters: Filters,
  Search: Search
};
