import React from "react";
import PropTypes from "prop-types";

const sizeClass = (size) => (size ? " avatar-" + size : "");

const SiteAvatar = (props) => (
  <img
    src={props.src}
    alt={props.title || props.name}
    title={props.title || props.name}
    className={[
      props.naked ? null : "avatar" + sizeClass(props.size),
      props.className
    ]
      .filter((i) => i)
      .join(" ")}
    style={{ filter: "none", opacity: "1" }}
  />
);

SiteAvatar.propTypes = {
  src: PropTypes.any,
  size: PropTypes.string,
  title: PropTypes.string,
  name: PropTypes.string,
  naked: PropTypes.bool,
  className: PropTypes.any
};

export default SiteAvatar;
