import Api from "@dwellsocial/dwellsocial-api";

export const loadLineItems = (project_membership_id) => (dispatch) =>
  !project_membership_id
    ? Promise.resolve([])
    : Api.lineItem
        .index({ filter: JSON.stringify({ project_membership_id }) })
        .then((line_items) => {
          dispatch({ type: "LOAD_LINE_ITEMS", line_items });

          return line_items;
        });
