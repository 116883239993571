import { compact, yieldSelf } from "helpers/helper";
import * as DataHelper from "reducers/dataHelper";

export * from "./db/projects";
export * from "./db/project_memberships";
export * from "./db/professionals";
export * from "./db/relevance";
export * from "./db/session";
export * from "./db/users";

const DEBUG = false;
const log = (...msg) => DEBUG && console.log(...msg) && null;
export { find, findAllBy, findBy } from "reducers/dataHelper";

// Generic utilities
// dig out nested value (like ruby dig)
export const dig = (path, tree) =>
  path.reduce((ac, node) => (ac && ac[node] ? ac[node] : null), tree);

export const executeIf = (data, callback) =>
  data
    ? callback
      ? callback(data)
      : data
    : log("Redux Service Unavailable", data, callback);

export const withData = (path, filters, props, callback) =>
  executeIf(dig(path, props), (data) =>
    executeIf(
      compact(filters ? DataHelper.findAllBy(data, filters) : data),
      callback
    )
  );

export const withDatum = (path, id, props, callback, finder = "find") =>
  (log("ID", id, path, props, dig(path, props)) || id) &&
  yieldSelf(dig(path, props))((data) =>
    yieldSelf(DataHelper[finder](data, id))((datum) =>
      executeIf(datum, callback)
    )
  );

export const getAll = (path, props, callback) =>
  withData(path, null, props, callback);

export const getAllBy = withData;

export const getOne = withDatum;

export const getOneBy = (path, filters, props, callback) =>
  withDatum(path, filters, props, callback, "findBy");

// Users
export const tab = (props) => props.authentication.tab;

export const globalId = (props) => props.match.params.id;
