import React from "react";
import "twin.macro";
import { LeftContent } from "../containers";

import { P } from "components/typography";
import Carousel from "components/Carousel";

type testimonial = {
  name: string;
  avatar?: string;
  location: string;
  content: string;
};
const testimonialContent: testimonial[] = [
  {
    name: "Tarrah L.",
    location: "Naperville",
    content:
      "Thanks for all that you are doing to bring these restaurants to the suburbs."
  },
  {
    name: "Carol B.",
    location: "Naperville",
    content:
      "It has been really nice to get food from some of our favorite Chicago restaurants in Naperville."
  },
  {
    name: "Christine V.",
    location: "Naperville",
    content:
      "Food was delicious! Exceeded expectations. Please bring more to Naperville!"
  }
];

const Testimonials: React.FC = () => (
  <LeftContent tw="bg-primary rounded text-white p-0">
    <Carousel autoScrollInterval={8000}>
      {testimonialContent.map((testimonial, i) => (
        <div
          key={`testimonial-${i}`}
          tw="p-8 py-9 flex flex-col justify-between"
        >
          <P.Large tw="text-white pb-6">{testimonial.content}</P.Large>
          <div tw="flex flex-row justify-between items-center">
            <div>
              {testimonial.avatar && (
                <img src={testimonial.avatar} tw="rounded-full w-12 mr-2" />
              )}
              <P.Medium as="span" tw="font-bold">
                {testimonial.name}
              </P.Medium>
            </div>
            <P.Medium as="span">
              <i className="fas fa-map-marker-alt" tw="mr-1"></i>
              {testimonial.location}
            </P.Medium>
          </div>
        </div>
      ))}
    </Carousel>
  </LeftContent>
);

export default Testimonials;
