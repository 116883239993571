const getAffiliateParamsFromState = (state) =>
  state.authentication.leadTracking || {};

const getReferralParams = () => {
  const rid = window.sessionStorage.getItem("rid");

  return rid ? { rid, aid: rid } : {};
};

export const getLeadTracking = (state) => ({
  ...getAffiliateParamsFromState(state),
  ...getReferralParams()
});

// Handled by redux for now. After establishing some affiliate terms/conditions, we can revisit and bake into the cookie solution here.
const setAffiliateParams = () => {
  return null;
};

// browser's "restore tabs" functionality breaks the expected session-only behavior of session-cookies. tsessionStorage
const setReferralParams = ({ rid }) =>
  window.sessionStorage.setItem("rid", rid);

export const setLeadTracking = ({ rid }) => {
  // affiliate params already set upstream... for now
  // params.filter(where_key_is_utm_*
  setAffiliateParams();

  // default referral id if utm_content is used in the url (not from the session)
  const referralId = rid;

  if (referralId) setReferralParams({ rid: referralId });
};
