import Api from "@dwellsocial/dwellsocial-api";

const MAX_EVENTS = 50;

export const preserveFetchOrder = (ids, projects) => {
  const orderedIds = ids.reduce((o, id, i) => ({ ...o, [id]: i }), {});

  return projects.sort((a, b) => orderedIds[a.id] - orderedIds[b.id]);
};

// project loading
const idsToBulk = (ids) =>
  ids.length ? { filter: JSON.stringify({ id: ids }) } : undefined;

export const loadProjects =
  (ids = []) =>
  (dispatch) =>
    Api.project.index(idsToBulk(ids)).then((projects) => {
      dispatch({ projects, type: "LOAD_PROJECTS" });

      return preserveFetchOrder(ids, projects);
    });

export const loadProjectsBy = (params, sort) => (dispatch) =>
  Api.project
    .index({
      filter: JSON.stringify(params),
      range: JSON.stringify([0, MAX_EVENTS]),
      ...(sort ? { sort: JSON.stringify(sort) } : {})
    })
    .then((projects) => {
      dispatch({ projects, type: "LOAD_PROJECTS" });
      return projects;
    });

export const loadProject = (id) => (dispatch) =>
  Api.project.show(id).then((project) => {
    if (project) {
      dispatch({ project, type: "LOAD_PROJECT" });
    }
    return project;
  });
