import React from "react";
import "twin.macro";

const ProductAvatar: React.FC<{
  avatar: string;
  onDismiss: () => void;
}> = ({ avatar, onDismiss }) => (
  <div
    tw="w-full h-64 bg-center bg-cover text-right rounded-t pr-6 pt-4 mb-6"
    style={{ backgroundImage: `url(${avatar})` }}
  >
    <button
      className="close-button"
      onClick={onDismiss}
      tw="bg-transparent text-white font-bold text-lg border-none"
      aria-label="close"
    >
      <i
        className="fas fa-times"
        tw="drop-shadow"
        style={{
          filter:
            "drop-shadow(0 10px 8px rgba(0, 0, 0, 0.04)) drop-shadow(0 4px 3px rgba(0, 0, 0, 0.8))"
        }}
      ></i>
    </button>
  </div>
);

export default ProductAvatar;
