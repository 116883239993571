import React from "react";
import "twin.macro";

import DynamicBanner from "./DynamicBanner";

const Banner: React.FC = () => (
  <section tw="bg-primary-dark w-full px-6">
    <DynamicBanner />
  </section>
);

export default Banner;
