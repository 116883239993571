import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { Card as BaseCard } from "components/cards";

export const primaryStyle = tw`
  font-bold font-montserrat text-white 
  border-none bg-primary rounded 
  hover:bg-primary-dark 
  py-2 px-3
  disabled:opacity-50
  `;
export const Primary = styled.button(primaryStyle);

export const secondaryStyle = tw`
  font-bold font-montserrat text-text
  border-none bg-secondary rounded
  hover:bg-secondary-dark
  py-2 px-3`;
export const Secondary = styled.button(secondaryStyle);

export const infoStyle = tw`
  font-bold font-montserrat text-white
  border-none bg-info rounded
  hover:(bg-info-dark text-white)
  py-2 px-3`;
export const Info = styled.button(infoStyle);

export const tertiaryStyle = tw`
font-bold font-montserrat text-primary 
border-none bg-transparent border-transparent 
hover:text-primary-dark`;
export const Tertiary = styled.button(tertiaryStyle);

export const tertiaryInfoStyle = tw`
font-bold font-montserrat text-info 
border-none bg-transparent border-transparent 
hover:text-info-dark`;
export const TertiaryInfo = styled.button(tertiaryInfoStyle);

const StyledCard = styled(BaseCard.Tertiary)(tw`
  border-muted shadow-none
  hover:border-primary 
  focus:(outline-none ring border-primary-dark shadow-lg)
`);
export const Card: React.FC<React.ButtonHTMLAttributes<HTMLButtonElement>> = (
  props
) => <StyledCard as="button" {...props} />;
