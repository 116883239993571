import React from "react";
import "twin.macro";

import { CheckoutDetails } from "api/v2/event.types";

import { FormSection, Legend, Description } from "../styles";
import { A, Alert, Button } from "components/typography";
import Input from "components/forms/input";

import { OrderFormValues } from "./orderForm.types";

const Promocode: React.FC<{
  checkoutDetails: CheckoutDetails;
  setPromocode: React.Dispatch<React.SetStateAction<string | undefined>>;
  orderFormValues?: OrderFormValues;
  register: any;
  getValues: any;
}> = ({ checkoutDetails, setPromocode, register, getValues }) => {
  const promocode = checkoutDetails.nonProductLineItems.find(
    (lineItem) => lineItem.sku === "promo_code"
  )?.notes;

  return (
    <FormSection tw="md:items-center">
      <Description>
        <Legend>Promo Code</Legend>
      </Description>
      {promocode ? (
        <Alert.Success tw="flex flex-row justify-between md:w-3/4">
          <span tw="font-bold">{promocode.toUpperCase()}</span>
          <A.Primary as="button" onClick={() => setPromocode(undefined)}>
            Remove
          </A.Primary>
        </Alert.Success>
      ) : (
        <div tw="w-full flex flex-row gap-2 md:w-3/4 items-start">
          <Input
            error={checkoutDetails.cartErrors?.promo_code_error}
            name={`promocode`}
            id={`promocode`}
            ref={register()}
            type="text"
            tw="w-1/2"
          />
          <Button.Info
            tw="py-3"
            onClick={(e) => {
              e.preventDefault();
              setPromocode(getValues("promocode"));
            }}
          >
            Apply
          </Button.Info>
        </div>
      )}
    </FormSection>
  );
};

export default Promocode;
