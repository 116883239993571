// Wrapper Box for unauthed pages that have a single centered form
import React from "react";
import PropTypes from "prop-types";
import { toastContainer } from "screens/shared/toast";

const outer = "container_lg signup-page";

const inner =
  "flex-container " +
  "c-vh-100 justify-content-center " +
  "align-items-center pt-4";

const Box = (props) => (
  <div className={outer}>
    {toastContainer()}
    <div className={inner}>
      <div className="first-col">{props.children}</div>
    </div>
  </div>
);

Box.propTypes = {
  children: PropTypes.any
};

export default Box;
