import withPagination, { idsToQuery } from "lib/withPagination";
import { pluralize } from "helpers/helper";

// Reusable Redux/Store/Action helpers
// Useful when mapping action to results of promise
// Then executing a dispatch on each result
export const all = (promises) => Promise.all(promises);

export const dispatchAll = (action, dispatch) => (objs) =>
  dispatch(action(objs));

const tap = (obj) => (func) => [func(obj), obj].pop();

export const apply = (action, dispatch) => (obj) =>
  tap(obj)((obj) =>
    Array.isArray(obj) ? obj.map(action).map(dispatch) : dispatch(action(obj))
  );

export const resolveAndApply = (action, dispatch) => (promise) =>
  promise.then(apply(action, dispatch));

export const paginate = (loader, ids, options = {}) =>
  withPagination((idSet) => loader({ ...options, ...idsToQuery(idSet) }))(ids);

// Time Helpers
export const milliseconds = (num) => num;

export const seconds = (num) => num * milliseconds(1000);

export const minutes = (num) => num * seconds(60);

export const hours = (num) => num * minutes(60);

export const days = (num) => num * hours(24);

// API helpers
// export const apiCall = (service, key, timeout, call, ...args) => (dispatch, getState) => {
export const apiCall =
  (call, { key, cacheExpiry, refresh }) =>
  (dispatch, getState) => {
    // cache expiry in milliseconds
    // console.log("Caching API Call - ", key)

    const cacheKey = key;

    const cachedEndpoint =
      (getState().backend.cachedEndpoints || {})[cacheKey] || {};

    const prev = cachedEndpoint.cachedAt;
    const body = cachedEndpoint.cached;
    const now = Date.now();
    const remaining = now - prev;
    const expired = remaining > cacheExpiry || refresh;

    // console.log("Remaining", now, " - ", prev, " = ", remaining, body)

    if (cacheExpiry && prev && body && !expired) {
      // console.log("API Cache:", cacheKey, body)
      return Promise.resolve(body);
    } else {
      // console.log("API Call:", cacheKey, now)
      return call().then((results) => {
        dispatch({
          type: "SET_CACHED_ENDPOINT",
          cachedEndpoint: {
            id: cacheKey,
            cachedAt: now,
            cached: results
          }
        });
        return results;
      });
    }
  };

export const invalidateApiKey = (key) => ({
  type: "CLEAR_CACHED_ENDPOINT",
  id: key
});

export const crudShow =
  (name, caller, cacheFunc = () => ({}), onShow) =>
  (id, refresh = false) =>
  (dispatch) =>
    id &&
    dispatch(apiCall(() => caller(id), cacheFunc({ id, refresh }))).then(
      (object) =>
        tap(object)((obj) => {
          onShow && onShow(obj, dispatch);
          dispatch({ type: `LOAD_${name.toUpperCase()}`, [name]: obj });
        })
    );

export const crudCreate =
  (name, caller) =>
  (...params) =>
  (dispatch) =>
    dispatch(caller(...params)).then((object) => {
      // console.log("Creeating ", object, `LOAD_${name.toUpperCase()}`)
      dispatch({
        type: `LOAD_${name.toUpperCase()}`,
        [name]: object
      });
      return object;
    });

export const crudUpdate =
  (name, caller, onUpdate) =>
  (...params) =>
  (dispatch) =>
    dispatch(caller(...params)).then((object) => {
      onUpdate && onUpdate(object, dispatch);
      dispatch({
        type: `LOAD_${name.toUpperCase()}`,
        [name]: object
      });
      return object;
    });

export const crudIndex =
  (name, caller) =>
  (...params) =>
  (dispatch) =>
    dispatch(caller(...params)).then((objects) => {
      if (!objects.length) return [];

      dispatch({
        type: `LOAD_${pluralize(null, name).toUpperCase()}`,
        [pluralize(null, name)]: objects
      });
      return objects;
    });
