import tw from "twin.macro";
import styled from "styled-components";

const Base = styled.section(
  tw`w-full p-8 py-9 rounded transition-all duration-1000 ease-in-out`
);
export const Primary = styled(Base)(tw`text-white bg-primary `);
export const Secondary = styled(Base)(tw`text-text bg-secondary `);
export const Tertiary = styled(Base)(
  tw`text-text bg-white border border-solid border-secondary shadow-md `
);
