import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Redirect } from "react-router-dom";
import connectComponent from "../../redux/connectComponent";
import Auth from "./auth";

export class Session extends PureComponent {
  static propTypes = {
    actions: PropTypes.object,
    database: PropTypes.object,
    location: PropTypes.object
  };

  constructor(props) {
    super(props);

    this.state = {
      authed: false,
      incomplete: false
    };
  }

  onAuth = () => this.setState({ authed: true });

  // Definitely authenticated but need more
  onIncomplete = () => this.setState({ incomplete: true });

  registrationPath = () => `/registration`;

  confirmationPath = () => `/registration/confirmation`;

  render() {
    const postal_code = this.props.database.authentication.user()?.postal_code;

    if (this.state.incomplete && !postal_code)
      return <Redirect to="/registration/add_home" />;

    if (this.state.incomplete || this.state.authed)
      return (
        <Redirect to={this.props.location.state?.referrer || "/dashboard"} />
      );

    return (
      <Auth
        socialAuth={false}
        onAuth={this.onAuth}
        requiresAddress={false}
        onIncomplete={this.onIncomplete}
        confirmationPath={this.confirmationPath(this.props)}
        registrationPath={
          !this.props.location.state?.checkout && this.registrationPath()
        }
        referrer={this.props.location.state?.referrer}
      />
    );
  }
}

export default connectComponent(Session, ["authentication"]);
