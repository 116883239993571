import React, { useState } from "react";
import "twin.macro";

import { Button, P } from "components/typography";
import { currencyFormatter } from "helpers/formatters";

import {
  useCart,
  actionTypes as cartActionTypes,
  cartItem
} from "components/CartProvider";

import MenuItemDialog from "../menuItemDialog/wizard";
import "@reach/dialog/styles.css";

const CartItem: React.FC<{
  eventId: string;
  item: cartItem;
  index: number;
}> = ({ eventId, item, index }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const cart = useCart();

  const onRemove = () => {
    cart.dispatch({
      type: cartActionTypes.REMOVE,
      value: { cartId: eventId, itemIndex: index }
    });
  };

  return (
    <li tw="p-4 border-0 border-b border-secondary border-solid grid grid-cols-9 gap-2 items-start">
      <P.Medium as="span" tw="text-muted">
        {item.quantity}
      </P.Medium>
      <div tw="col-span-4">
        <P.Medium as="span">{item.product.name}</P.Medium>
        {item.optionChoices &&
          Object.entries(item.optionChoices)?.map(([key, optionChoice]) => (
            <P.Small tw="text-muted" key={key}>
              {optionChoice.choiceName}
            </P.Small>
          ))}
        {item.notes && (
          <P.Small tw="text-muted truncate">NOTE: {item.notes}</P.Small>
        )}
      </div>
      <Button.Tertiary
        aria-label="Edit Item"
        onClick={() => setIsModalOpen(true)}
      >
        <i className="fas fa-pencil-alt" tw="text-muted"></i>
      </Button.Tertiary>
      <Button.Tertiary aria-label="Remove Item" onClick={onRemove}>
        <i className="fas fa-trash" tw="text-muted"></i>
      </Button.Tertiary>
      <P.Medium as="span" tw="col-span-2 col-start-8 text-right">
        {currencyFormatter(item.product.price * item.quantity)}
      </P.Medium>
      <MenuItemDialog
        cartItemIndex={index}
        product={item.product}
        isOpen={isModalOpen}
        onDismiss={() => setIsModalOpen(false)}
        eventId={eventId}
      />
    </li>
  );
};

export default CartItem;
