import Api from "@dwellsocial/dwellsocial-api";

import { loadProjects } from "./projects";
const DEBUG = false;

const logError = (msg) => DEBUG && console.log("err:", msg);

export const loadRelevantEvents = (params) => (dispatch) =>
  Api.relevance
    .projects(params)
    .then((projectIds) => {
      dispatch({ type: "LOAD_RELEVANT_PROJECTS", projects: projectIds });

      if (!projectIds.length) return projectIds;

      return dispatch(loadProjects(projectIds));
    })
    .catch(logError("Suggested Events Error", []));

export const loadSuggestedProjects = loadRelevantEvents;
