import React from "react";
import "twin.macro";

import { P } from "components/typography";

import { ProductOption } from "api/v2/menuCategories.types";
import { Controller, Control, DeepMap, FieldError } from "react-hook-form";

import * as Dropdown from "components/forms/Dropdown";
import { FormValues, OPTION_FORM_PREFIX } from "./wizard.types";

const OptionsDropdown: React.FC<{
  control: Control<FormValues>;
  option: ProductOption;
  errors: DeepMap<FormValues, FieldError>;
}> = ({ control, option, errors }) => {
  return (
    <fieldset tw="m-0 p-0 border-0">
      <P.Small as="legend" tw="font-bold pb-2">
        {option.name}
      </P.Small>
      <Controller
        control={control}
        name={`options.${OPTION_FORM_PREFIX}${option.id}`}
        rules={{
          required: true,
          validate: (value) => value !== "__default"
        }}
        defaultValue="__default"
        render={(inputProps) => (
          <Dropdown.Dropdown
            error={
              errors.options?.[`${OPTION_FORM_PREFIX}${option.id}`] &&
              "Must select an option"
            }
            {...inputProps}
          >
            <Dropdown.Option value="__default">
              Select an item...
            </Dropdown.Option>
            {option.choices.map((choice) => (
              <Dropdown.Option value={choice.id.toString()} key={choice.id}>
                {choice.name}
              </Dropdown.Option>
            ))}
          </Dropdown.Dropdown>
        )}
      />
    </fieldset>
  );
};

export default OptionsDropdown;
