import React from "react";
import FourOhFourWrapper from "components/FourOhFour";

const FourOhFour = () => (
  <FourOhFourWrapper
    title="Sorry, this project is either closed or unavailable."
    testId="project-404"
  />
);

export default FourOhFour;
