// deprecated
import React from "react";
import PropTypes from "prop-types";
import Box from "./index";

const Show = (props) => (props.enabled ? props.children : "");

export const BoxForm = (props) => (
  <Box>
    <form
      action={props.action || "#"}
      className={props.className || "box-form"}
      disabled={props.disabled}
      onSubmit={props.onSubmit}
    >
      <Show enabled={props.title}>
        <h4 className={"box--headline " + props.titleClassName}>
          {props.title}
        </h4>
      </Show>

      <Show enabled={props.subtitle}>
        <p className={"desc--headline " + props.subtitleClassName}>
          {props.subtitle}
        </p>
      </Show>

      {props.children}
      <input
        className="w-button mt-4"
        value={props.submitTitle || "Reset"}
        disabled={props.disabled}
        type="submit"
      />
    </form>
  </Box>
);

BoxForm.propTypes = {
  action: PropTypes.string,
  children: PropTypes.any,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  formProps: PropTypes.object,
  title: PropTypes.string,
  titleClassName: PropTypes.string,
  subtitle: PropTypes.string,
  subtitleClassName: PropTypes.string,
  onSubmit: PropTypes.func,
  submitTitle: PropTypes.string
};

export default BoxForm;
