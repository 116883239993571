const styles = {
  modal: {
    overlay: {
      backgroundColor: "rgba(80, 80, 80,0.5)",
      zIndex: 1000
    },
    content: {
      border: "1px solid #ccc",
      background: "#fff",
      overflow: "auto",
      WebkitOverflowScrolling: "touch",
      borderRadius: "4px",
      outline: "none",
      padding: "0",
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)"
    }
  }
};

export default styles;
