import React from "react";
import "twin.macro";

import { useQuery } from "react-query";
import { fetchAllMemberships } from "api/v2/memberships";
import { Membership } from "api/v2/memberships.types";

import dayjs from "dayjs";

import { H } from "components/typography";

import Spinner from "components/spinner";
import MembershipsList from "./membershipsList";

const Show: React.FC = () => {
  const { data: memberships, isLoading } = useQuery<Membership[]>(
    ["memberships"],
    fetchAllMemberships
  );

  if (isLoading) {
    return (
      <div tw="flex w-full justify-center p-6">
        <Spinner disabled={false} />
      </div>
    );
  }

  const { previousOrders, upComingOrders } =
    memberships
      ?.sort((a, b) => {
        if (a.eventRunAt < b.eventRunAt) {
          return -1;
        } else if (a.eventRunAt > b.eventRunAt) {
          return 1;
        } else {
          return 0;
        }
      })
      .reduce(
        (acc, membership) =>
          dayjs().isAfter(dayjs(membership.eventRunAt))
            ? { ...acc, previousOrders: [...acc.previousOrders, membership] }
            : { ...acc, upComingOrders: [...acc.upComingOrders, membership] },
        { previousOrders: [], upComingOrders: [] } as {
          previousOrders: Membership[];
          upComingOrders: Membership[];
        }
      ) || {};

  return (
    <section tw="mt-6 mb-24">
      {
        <div>
          <section data-testid="upcoming-orders">
            <H.Three
              as="h1"
              tw="pb-6 mb-6 border border-0 border-b border-solid border-secondary"
            >
              My Upcoming Orders
            </H.Three>
            <MembershipsList memberships={upComingOrders || []} />
          </section>
          <section data-testid="previous-orders">
            <H.Three
              as="h1"
              tw="pb-6 mt-12 mb-6 border border-0 border-b border-solid border-secondary"
            >
              My Previous Orders
            </H.Three>
            <MembershipsList memberships={previousOrders || []} />
          </section>
        </div>
      }
    </section>
  );
};

export default Show;
