import api, { extractError } from "api/api";
import { cart } from "components/CartProvider";
import { LeadTracking } from "components/AuthProvider";

export const fetchEvent = ({ queryKey }: any) => {
  const [_key, id] = queryKey;

  return api
    .get(`/api/v2/events/${id}`)
    .then((resp) => resp.data?.event)
    .catch((e) => {
      throw extractError(e);
    });
};

export const fetchCheckoutDetails = (eventId: string, params: any) => () =>
  api
    .get(`/api/v2/events/${eventId}/checkout_details`, {
      params
    })
    .then((resp) => resp.data?.checkoutDetails)
    .catch((e) => {
      throw extractError(e);
    });

export const createOrder = ({
  id,
  clientSecret,
  eventCart,
  gratuity,
  promocode,
  notes,
  timeSlotId,
  leadTracking,

  deliveryStreet,
  deliveryCity,
  deliveryState,
  deliveryPostalCode,
  deliveryType,
  saveDefaultAddress
}: {
  id: string | number;
  clientSecret?: string;
  eventCart: cart;
  gratuity: number | string;
  promocode: string;
  notes?: string;
  timeSlotId?: number | string;
  leadTracking?: LeadTracking;

  deliveryStreet?: string;
  deliveryCity?: string;
  deliveryState?: string;
  deliveryPostalCode?: string;
  deliveryType?: string;
  saveDefaultAddress?: boolean;
}) => {
  const params = {
    transaction_id: clientSecret,
    items: [
      ...eventCart.items.map((item) => ({
        id: item.product.id,
        quantity: item.quantity,
        notes: item.notes,
        product_option_choice_ids:
          item.optionChoices &&
          Object.values(item.optionChoices).map(
            (optionChoice) => optionChoice.choiceId
          )
      })),
      {
        id: null, // needed for array param stringifying / parsing
        key: "tip",
        price: gratuity
      },
      {
        id: null, // needed for array param stringifying / parsing
        key: "promo_code",
        notes: promocode
      }
    ],
    notes: notes,
    time_slot_id: timeSlotId,

    delivery_street: deliveryStreet,
    delivery_city: deliveryCity,
    delivery_state: deliveryState,
    delivery_postal_code: deliveryPostalCode,
    delivery_type: deliveryType,
    save_default_address: saveDefaultAddress,
    lead_params: {
      utm_medium: "Web",
      ...leadTracking
    }
  };

  return api
    .post(`/api/v2/events/${id}/orders`, {
      data: { order: params }
    })
    .then((resp) => resp.data?.order)
    .catch((e) => {
      throw extractError(e);
    });
};

export const deleteOrder = ({
  eventId,
  orderId,
  retry = false
}: {
  eventId: string;
  orderId: string;
  retry?: boolean;
}) =>
  api
    .delete(`/api/v2/events/${eventId}/orders/${orderId}`, {
      data: { retry }
    })
    .then((resp) => resp.data?.order)
    .catch((e) => {
      throw extractError(e);
    });
