import React from "react";
import "twin.macro";
import styled from "styled-components";

import Input, { InputProps } from "./input";
import { Button, P } from "components/typography";

export interface NumberInputProps extends InputProps {
  setValue: (value: number) => void;
  value: number | string;
}

const NumberInput = React.forwardRef<HTMLInputElement, NumberInputProps>(
  ({ min, max, name, setValue, value, error, ...props }, ref) => {
    const parsedValue =
      typeof value === "string" ? (value === "" ? 0 : parseInt(value)) : value;

    const canDecrement = !(typeof min === "number" && parsedValue <= min);
    const canIncrement = !(typeof max === "number" && parsedValue >= max);

    return (
      <section>
        <div tw="flex flex-row gap-2 items-center">
          <Button.Primary
            tw="rounded-full p-0 h-6 w-6"
            disabled={!canDecrement}
            onClick={(e) => {
              e.preventDefault();

              if (canDecrement) {
                setValue(parsedValue - 1);
              }
            }}
          >
            -
          </Button.Primary>
          <InputWithoutArrows
            tw="text-center w-10"
            type="number"
            value={value}
            name={name}
            onChange={(e) =>
              e.target.value !== "" && setValue(parseInt(e.target.value, 10))
            }
            error={!!error}
            {...{ min, max, ...props }}
            ref={ref}
          />
          <Button.Primary
            tw="rounded-full p-0 h-6 w-6"
            disabled={!canIncrement}
            onClick={(e) => {
              e.preventDefault();

              if (canIncrement) {
                setValue(parsedValue + 1);
              }
            }}
          >
            +
          </Button.Primary>
        </div>
        {!!error && <P.Small tw="text-danger">{error}</P.Small>}
      </section>
    );
  }
);

export default NumberInput;

const InputWithoutArrows = styled(Input)`
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
`;
