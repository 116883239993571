import React from "react";
import "twin.macro";
import { H, Button } from "components/typography";
import { Product } from "api/v2/menuCategories.types";

import ProductAvatar from "../productAvatar";

import { currencyFormatter } from "helpers/formatters";

import { useForm, SubmitHandler } from "react-hook-form";
import NumberInput from "components/forms/NumberInput";
import Input from "components/forms/input";
import { FormValues, updateFormType } from "../wizard.types";

import {
  ResponsiveFloatingBottomBar,
  ModalArticle,
  ScrollSection
} from "./styles";

const DescriptionPage: React.FC<{
  product: Product;
  onDismiss: () => void;
  formValues: FormValues;
  updateForm: updateFormType;
  setFormStep: React.Dispatch<React.SetStateAction<number>>;

  isEditing: boolean;
}> = ({ product, onDismiss, formValues, updateForm, setFormStep }) => {
  const { handleSubmit, errors, register, watch, setValue } =
    useForm<FormValues>({
      defaultValues: formValues
    });

  const onSubmit: SubmitHandler<FormValues> = ({ quantity, notes }) => {
    updateForm({ quantity, notes });
    setFormStep(2);
  };

  return (
    <ModalArticle>
      <form onSubmit={handleSubmit(onSubmit)} tw="flex flex-col gap-6">
        <ScrollSection tw="pb-40">
          {product.avatarMedium ? (
            <ProductAvatar
              avatar={product.avatarMedium}
              onDismiss={onDismiss}
            />
          ) : (
            <div tw="text-right pr-6 pt-4">
              <button
                className="close-button"
                onClick={onDismiss}
                tw="bg-transparent font-bold text-lg border-none"
                aria-label="close"
              >
                <i className="fas fa-times" tw="text-muted"></i>
              </button>
            </div>
          )}
          <div tw="p-6 pt-0">
            <H.Three id="menu-item-title">{product.name}</H.Three>
            <div
              dangerouslySetInnerHTML={{ __html: product.descriptionHtml }}
            />

            <Input
              type="text"
              placeholder="Any special requests (ex: allergies, extra spicy, etc.)"
              name="notes"
              ref={register}
              error={errors["notes"] && "Must input valid notes"}
            />
          </div>
        </ScrollSection>

        <ResponsiveFloatingBottomBar tw="flex flex-col md:flex-row gap-6 justify-between">
          <NumberInput
            min={0}
            max={100}
            name="quantity"
            ref={register({ required: true, max: 100, min: 0 })}
            setValue={(value: number) => setValue("quantity", value)}
            value={watch("quantity")}
            error={errors["quantity"] && "Must input a valid quantity"}
          />
          <Button.Primary
            type="submit"
            disabled={Object.keys(errors).length > 0}
          >
            Continue to Selections -{" "}
            {currencyFormatter(product.price * watch("quantity"))}
          </Button.Primary>
        </ResponsiveFloatingBottomBar>
      </form>
    </ModalArticle>
  );
};

export default DescriptionPage;
