import React, { Component } from "react";
import PropTypes from "prop-types";
import Log, { TapLog } from "../../lib/log";
import { Redirect, Link } from "react-router-dom";
import { createToast } from "screens/shared/toast";
import connectComponent from "../../redux/connectComponent";
import Errors, { setError, Error, errorContains } from "components/errors";
import PasswordField from "screens/shared/forms/passwordField";
import withWrap from "components/withWrap";
const withEvent = (event) => (callback) =>
  [event.preventDefault(), callback(event), false].pop();

export class Auth extends Component {
  static propTypes = {
    actions: PropTypes.object,
    onAuth: PropTypes.func,
    onIncomplete: PropTypes.func,
    confirmationPath: PropTypes.string,
    registrationPath: PropTypes.string,
    referrer: PropTypes.string
  };

  state = {
    error: null,
    errors: {},
    disabled: false
  };

  constructor(props) {
    super(props);
    this.emailRef = React.createRef();
    this.passwordRef = React.createRef();
  }

  onLoginHandler = (event) =>
    withEvent(event)(() => this.onLogin(this.formValues()));

  resetErrors = () =>
    this.setState({
      errors: {},
      error: null
    });

  onLogin = (userValues) => {
    if (this.state.disabled) return;

    this.setState({ disabled: true });

    this.resetErrors();

    return this.props.actions
      .login(userValues)
      .then(this.onSuccess)
      .catch(this.onFailure);
  };

  onSuccess = (user) => {
    Log("onLoginSuccess", user);

    this.props.actions
      .refresh()
      .then(() => this.props.actions.optimizeEvent("login"))
      .then(() => this.setState({ disabled: false }))
      .then(() => this.goToDestination(this.props))
      .catch(TapLog("Error Checking Authentication"));
  };

  onFailure = (errorResponse) => {
    this.setState({ disabled: false });
    return setError(this.setState.bind(this))(errorResponse);
  };

  goToDestination = (props) => {
    // console.log("goToDestination", props)
    if (!props.database.authentication.complete()) {
      // console.log("Not Contactable", props)
      this.props.onIncomplete();
    } else if (this.props.onAuth) {
      this.props.onAuth();
    }
  };

  shouldGoToConfirmation = (state) => errorContains(state, /confirm/);

  shouldGoToRegister = (state) => errorContains(state, /exist/);

  goToConfirmation = () => <Redirect to={this.confirmationPath(this.props)} />;

  credentialError = (state) => errorContains(state, /credential|No Session/im);

  formValues = () => ({
    email: this.emailRef.current.value,
    password: this.passwordRef.current.value
  });

  confirmationPath = (props) =>
    props.confirmationPath || "/registration/confirmation";

  registrationPath = (props) => props.registrationPath || "/registration";

  renderNavigationPrompt = (props, state) => {
    //
    // if(this.shouldGoToConfirmation(state)) {
    //   return <p className="input-message-warning">You must <Link to={this.confirmationPath(props)}>confirm</Link> your account.</p>
    // }
    //
    if (this.shouldGoToRegister(state)) {
      return (
        <p className="input-message-warning">
          You have not signed up with this email! &nbsp;
          <Link to={this.registrationPath(props)}>Register today!</Link>
        </p>
      );
    }

    return "";
  };

  render() {
    if (this.shouldGoToConfirmation(this.state)) {
      createToast("You must confirm your account to login!");
      return this.goToConfirmation();
    }

    return (
      <form
        id="new_user"
        onSubmit={this.onLoginHandler}
        acceptCharset="UTF-8"
        className="box-form"
      >
        <h4 className="box--headline pb-5 sm-pad-b-2">Sign in</h4>

        {this.renderNavigationPrompt(this.props, this.state)}
        <Error
          error={this.credentialError(this.state) ? this.state.error : ""}
        />
        <div className="input-form">
          <label htmlFor="email">Email</label>
          <input
            type="email"
            name="Email"
            id="email"
            autoFocus="autofocus"
            ref={this.emailRef}
            required="required"
          />
          <Errors errors={this.state.errors.email} />
        </div>

        <PasswordField
          inputRef={this.passwordRef}
          errors={this.state.errors.password}
        />

        <Link to="/password/new" className="forgot-password-link">
          Forgot your password?
        </Link>

        <input
          className="w-button mt-4"
          value={this.state.disabled ? "Signing In..." : "Sign In"}
          type="submit"
          data-testid="signin"
        />

        <hr className="divider" />

        <p className="box-form--alternative-option">
          Don&#39;t have an account? &nbsp;
          <Link
            to={{
              pathname: this.props.registrationPath || "/registration",
              state: { referrer: this.props.referrer }
            }}
          >
            Sign up
          </Link>
        </p>
      </form>
    );
  }
}

export default withWrap(connectComponent(Auth, ["authentication", "users"]));
// 162
