import React from "react";

import PropTypes from "prop-types";
import connectComponent from "redux/connectComponent";
import { createToast } from "screens/shared/toast";
import { Redirect } from "react-router-dom";
import { Error, setError } from "components/errors";
import BoxForm from "components/box/form";

export class New extends React.PureComponent {
  static propTypes = {
    actions: PropTypes.object,
    resend: PropTypes.any
  };

  state = {
    sent: false,
    error: null,
    resend: false
  };

  constructor(props) {
    super(props);
    this.emailRef = React.createRef();
  }

  reset = (event) => {
    event.preventDefault();
    this.setState({
      error: null // form specific
    });

    this.props.actions
      .resetPassword(this.emailRef.current.value)
      .then(() =>
        createToast("Check email for password reset instructions", {
          onClose: this.close
        })
      )
      .catch(setError(this.setState.bind(this)));
    return false;
  };

  close = () => this.setState({ sent: true });

  render() {
    if (this.state.sent) {
      return <Redirect to="/" />;
    }

    return (
      <BoxForm
        title="Password Reset"
        titleClassName="pb-2"
        subtitle="Enter the email address you use to sign in. We'll email you instructions to reset your password."
        subtitleClassName="mb-2 sm-pad-t-2"
        onSubmit={this.reset}
        submitTitle={this.props.resend ? "Resend" : "Reset"}
      >
        <div className="input-form mb-0">
          <label htmlFor="email">Email</label>
          <input
            type="email"
            name="Email"
            id="email"
            ref={this.emailRef}
            autoFocus={true}
            required={true}
          />
          <Error error={this.state.error} />
        </div>
      </BoxForm>
    );
  }
}

export default connectComponent(New, []);
