import React from "react";
import PropTypes from "prop-types";

// This function takes a component...
export const formElement = (WrappedComponent) =>
  class extends React.Component {
    // eslint-disable-line react/display-name

    static propTypes = {
      id: PropTypes.any,
      title: PropTypes.string,
      name: PropTypes.string,
      className: PropTypes.string,
      children: PropTypes.any
    };

    render() {
      return (
        <div className="form-group">
          <label htmlFor={this.props.id}>{this.props.title}</label>
          <WrappedComponent
            {...this.props}
            id={this.props.id}
            name={this.props.name}
            className={
              "form-control" +
              (this.props.className ? " " + this.props.className : "")
            }
          >
            {this.props.children}
          </WrappedComponent>
        </div>
      );
    }
  };
