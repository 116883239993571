import React from "react";
import "twin.macro";

import { Event, CheckoutDetails } from "api/v2/event.types";

import { FormSection, Legend, Description } from "../styles";
import { P } from "components/typography";
import Timeslot from "./timeslot";

const PickupFormSection: React.FC<{
  checkoutDetails: CheckoutDetails;
  event: Event;
  register: any;
  errors: any;
  watchedTimeSlot?: string;
}> = ({ checkoutDetails, event, register, errors, watchedTimeSlot }) => (
  <>
    {checkoutDetails.enableTimeSlotSelect && (
      <Timeslot
        register={register}
        watchedTimeSlot={watchedTimeSlot}
        hasError={!!errors.timeSlotId}
        timeSlots={event.timeSlots}
        availableTimeSlots={checkoutDetails.availableTimeSlots.map(
          (slot) => slot.id
        )}
      />
    )}
    <FormSection>
      <Description>
        <Legend>Pickup Location</Legend>
      </Description>
      <div tw="md:w-3/4">
        <P.Medium>
          {event.pickupStreet}, {event.pickupCityStateZip}
        </P.Medium>
      </div>
    </FormSection>
  </>
);
export default PickupFormSection;
