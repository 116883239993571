import Log from "lib/log";
import Api from "@dwellsocial/dwellsocial-api";
import * as AccountActions from "./account";
import { dig, queryParameters } from "helpers/helper";

export * from "./account";
export * from "./event_locations";
export * from "./experiments";
export * from "./relevance";
export * from "./line_items";
export * from "./projects";
export * from "./project_memberships";
export * from "./ui";

/////////////////////
// API action callers
// Upon receiving our user Id, fetch relevant data
// For now, this is to keep parity with HAML data transfer
// Later, scope it down to components that need the data
export const reset = () => (dispatch) => dispatch({ type: "RESET" });

const resetReducer = (state, path, type) => (dispatch) =>
  dig(path, state) || dispatch({ type });

// Since our redux state is simply a cache, we can blow away when the schema changes
export const migrateStore = () => (dispatch, getState) =>
  [
    [["projects", "projects"], "RESET_PROJECTS"],
    [["backend", "cachedEndpoints"], "RESET_BACKEND"]
  ].forEach((meta) => resetReducer(getState(), meta[0], meta[1]));

// authentication reduced
export const loadAncillaryData = () => (dispatch) => {
  dispatch(migrateStore());
};

///////////////// Authentication Helpers

// Entry point to (re)trigger account session status
export const checkAuthentication = () => (dispatch, getState) => {
  return dispatch(AccountActions.loadAccount())
    .catch((err) =>
      getState().authentication.userId
        ? Log("checkAuthentication :: Lost backend session") &&
          dispatch(reset())
        : Log("visitor", err)
    )
    .then((user) => {
      dispatch({ type: "CHECK_AUTHENTICATION" });
      if (user && user.id) {
        Log("checkAuthentication", "Loaded Account", user);
        Log("Loading Ancillary bidness", user);

        dispatch(loadAncillaryData(user));
        return user;
      } else {
        Log("checkAuthentication", "Did Not Load Account", user);
        return undefined;
      }
    });
};

export const bumpVersion = () => () => ({ type: "BUMP_VERSION" });

export const refresh = (params) => (dispatch) =>
  dispatch(checkAuthentication(params));

export const setQueryParameters = (loc) => ({
  type: "SET_QUERY_PARAMETERS",
  params: queryParameters(loc)
});

export const loadProfessionals = (params) => () =>
  Api.professional.index(params);
