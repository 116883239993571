import axios, { AxiosError } from "axios";
import Qs from "qs";
import { getAuthToken } from "actions/account";

// format nested params correctly
axios.interceptors.request.use((config) => {
  config.paramsSerializer = (params) => {
    return Qs.stringify(params, {
      arrayFormat: "brackets",
      encode: false
    });
  };

  return config;
});

const apiBase = () =>
  process.env.REACT_APP_API_HOST || "http://api.dwellsocial.local:3000";

export const urlFor = (path: string) => apiBase() + path;

export const extractError = (e: AxiosError) =>
  (e.response && e.response.data && e.response.data.error) || e.message;

const headers = () => ({
  "Content-Type": "application/json",
  ...(getAuthToken() ? { Authorization: `Bearer ${getAuthToken()}` } : {})
});

export default {
  get: <T>(path: string, rest?: T) =>
    axios({
      method: "get",
      url: apiBase() + path,
      headers: headers(),
      ...rest
    }),
  post: <T>(path: string, rest?: T) =>
    axios({
      method: "post",
      url: apiBase() + path,
      headers: headers(),
      ...rest
    }),
  delete: <T>(path: string, rest?: T) =>
    axios({
      method: "delete",
      url: apiBase() + path,
      headers: headers(),
      ...rest
    })
};
