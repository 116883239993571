import React from "react";

// eslint-disable-next-line no-undef
const public500image = process.env.PUBLIC_URL + "/static_assets/images/500.jpg";

const styles = {
  wrapper: {
    backgroundImage: `linear-gradient( rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4) ), url(${public500image})`, // eslint-disable-line
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    color: "white",
    display: "flex",
    position: "fixed",
    width: "100%",
    height: "100%",
    left: 0,
    top: 0,
    transform: "scale(-1, -1)"
  },

  inner: {
    margin: "auto auto",
    transform: "scale(-1, -1)"
  },

  title: {},
  subtitle: {
    fontWeight: "300"
  },
  link: {
    color: "#AAC634"
  }
};

const FiveHundred = () => {
  const goToHomepage = () => window.location.assign("/");

  const title = "Oops, we're not feeling dwell!";

  return (
    <div style={styles.wrapper}>
      <div style={styles.inner}>
        <h1 className="d-none d-md-block" style={styles.title}>
          {title}
        </h1>
        <h2 className="d-xs-block d-md-none m-2" style={styles.title}>
          {title}
        </h2>

        <h1 className="d-none d-md-block" style={styles.subtitle}>
          Let&#39;s go back to the{" "}
          <a href="/" style={styles.link}>
            home page
          </a>
          .
        </h1>
        <p
          className="d-xs-block d-md-none m-2"
          style={{ ...styles.subtitle, fontSize: "1.5rem", color: "white" }}
        >
          Let&#39;s go back to the{" "}
          <span style={{ color: "#AAC634" }} onClick={goToHomepage}>
            home page.
          </span>
        </p>
      </div>
    </div>
  );
};

export default FiveHundred;
