import Api, { Base } from "@dwellsocial/dwellsocial-api";
import { purgeStoredState } from "redux-persist";
import { persistConfig } from "../redux/store.js";
import { facebookLogout } from "../lib/facebook";
import { crudShow, crudUpdate, minutes } from "./helpers";
import { setLeadTracking } from "lib/leadTracking";
import * as ExperimentsActions from "./experiments";

/////////////////
// Analytics
const trackUser = (user) => (dispatch) =>
  dispatch(ExperimentsActions.identify(user));

/////////////////
// State Handlers
const storeAccount = (user) => ({ type: "LOAD_ACCOUNT", ...user });
const storeHome = (home) => ({ type: "UPDATE_HOME", home });
const storeUser = (user) => ({ type: "LOAD_USER", user });
const storeLeadTracking = (leadTracking) => ({
  type: "SET_LEAD_TRACKING",
  leadTracking
});

//////////////////
// Action Handlers
export const captureLeadTracking =
  ({ rid, ...params }) =>
  (dispatch) => {
    dispatch(storeLeadTracking(params));
    setLeadTracking({ rid, ...params });
  };

export const getCreditBalance = (id) => () => Api.user.creditBalance(id);
///////////////
// Api Helpers
const userShow = (id) => Api.user.show(id);

const userShowCache = ({ refresh, id }) => ({
  key: `load-user-${id}`,
  cacheExpiry: minutes(1),
  refresh
});

////////////
// API Calls
// Get user record from API
export const loadUser = crudShow("user", userShow, userShowCache);

// Get current user / session from API
export const loadAccount = () => (dispatch) =>
  Api.session.initialize().then((user) => {
    // update local user record
    dispatch(trackUser(user));
    dispatch(storeAccount(user));
    dispatch(storeUser(user));
    return user;
  });

const _updateUser = (user) => {
  console.log("API", Api);
  return Api.user.update(user.id, user);
};

export const updateUser = crudUpdate(
  "user",
  (user) => () => _updateUser(user),
  (obj, dispatch) => dispatch(loadAccount())
);

export const stashUserId = (userId) => (dispatch) =>
  dispatch({ type: "SET_USER_ID", userId });

export const stashAuthToken = (token) => localStorage.setItem("att", token);

export const getAuthToken = () => localStorage.getItem("att");

export const purgeAuthToken = () => localStorage.removeItem("att");

// Let's logout and purge immediately
export const resetSession = () => (dispatch) =>
  Promise.all([
    // Api.session.destroy().catch(err => console.log("Error logging out", err)), // eslint-disable-line
    Api.session.destroy().catch(() => ""), // eslint-disable-line
    Promise.resolve(dispatch({ type: "RESET" })),
    Promise.resolve(purgeStoredState(persistConfig)),
    Promise.resolve(purgeAuthToken()),
    facebookLogout().catch(() => null)
  ]);

export const login = (userValues) => (dispatch) =>
  Api.session.create(userValues).then((session) => {
    stashAuthToken(session.token);
    dispatch(stashUserId(session.id));
    dispatch(storeUser(session));
    dispatch({ type: "BUMP_VERSION" });
    return session;
  });

// We don't need to dispatch, yet.
export const facebookLogin = () => (dispatch) =>
  Api.session.omniauth("facebook").then((session) => {
    stashAuthToken(session.token);
    dispatch(stashUserId(session.id));
    dispatch(storeUser(session));

    dispatch({ type: "BUMP_VERSION" });
    return session;
  });

export const googleLogin = (payload) => (dispatch) =>
  Api.session.omniauth("google_oauth2", payload).then((session) => {
    stashAuthToken(session.token);
    dispatch(stashUserId(session.id));
    dispatch(storeUser(session));
    dispatch({ type: "BUMP_VERSION" });
    return session;
  });

// part 1: Trigger Confirmation (pt2 handled as direct to backend link)
export const resendConfirmation = (email) => () =>
  Api.confirm.resend({ email });

// part 1: Trigger Password Reset
export const resetPassword = (email) => () => Api.password.reset({ email });

// part 2: Complete Password Reset
export const updatePassword =
  ({ token, password }) =>
  (dispatch) =>
    Api.password.update({ token, password }).then((session) => {
      stashAuthToken(session.token);
      stashUserId(session.id);
      dispatch(storeUser(session));
      dispatch({ type: "BUMP_VERSION" });
      return session;
    });

// Verify (without resetting) pwd token
export const verifyPasswordToken =
  ({ token }) =>
  () =>
    Api.password.verify({ token });

export const registerAccount =
  ({
    email,
    firstname,
    lastname,
    name,
    password,
    street,
    city,
    state,
    postal_code,
    phone
  }) =>
  (dispatch, getState) =>
    Api.user
      .create({
        name,
        firstname,
        lastname,
        email,
        password,
        phone,
        ...(getState().authentication.leadTracking || {}),
        street,
        city,
        state,
        postal_code
      })
      .then((user) => {
        stashAuthToken(user.token);
        dispatch(stashUserId(user.id));
        dispatch({ type: "BUMP_VERSION" });
        dispatch(storeUser(user));
        return user;
      });

export const validateHome = (postal_code) => () => Api.home.create(postal_code);

// update account
export const updateHome = (home) => (dispatch) => dispatch(storeHome(home));

export const setCurrentZip = (zip) => (dispatch) =>
  zip && dispatch({ type: "SET_CURRENT_ZIP", zip });

export const loadCurrentZip = (coordinates) => (dispatch) =>
  Base.get("/api/postal_codes", { filter: JSON.stringify({ coordinates }) })
    .then((postal_codes) => postal_codes[0]?.postal_code)
    .then((postal_code) => dispatch(setCurrentZip(postal_code)));
