import React from "react";
import "twin.macro";
import { LeftContent } from "./containers";

import { P } from "components/typography";
import Carousel from "components/Carousel";

type testimonial = {
  name: string;
  avatar?: string;
  location: string;
  content: string;
};
const testimonialContent: testimonial[] = [
  {
    name: "Linda C.",
    location: "Elmhurst",
    content:
      "You have quadrupled the quality of my life in the suburbs with this outstanding food service. I live in a town with some of the best restaurants within 5 miles but not the real city of Chicago ones. I have picked up 5 times this month (3 more to go) and the way you organize this is very six sigma-ish."
  },
  {
    name: "Jeanne P.",
    location: "Elmhurst",
    content:
      "It made those days when we ordered more joyful. Being able to have a taste of the diversity of city restaurants when we can't be there or inside has been really fun."
  },
  {
    name: "Susan K.",
    location: "Downers Grove",
    content:
      "This was our first order through DwellSocial. We were so pleased with the whole experience. Delicious food from the city, a great price, and I only had to drive 5 minutes to get it!"
  }
];

const Testimonials: React.FC = () => (
  <LeftContent tw="bg-primary rounded text-white p-0">
    <Carousel autoScrollInterval={8000}>
      {testimonialContent.map((testimonial, i) => (
        <div
          key={`testimonial-${i}`}
          tw="p-8 py-9 flex flex-col justify-between"
        >
          <P.Large tw="text-white pb-6">{testimonial.content}</P.Large>
          <div tw="flex flex-row justify-between items-center">
            <div>
              {testimonial.avatar && (
                <img src={testimonial.avatar} tw="rounded-full w-12 mr-2" />
              )}
              <P.Medium as="span" tw="font-bold">
                {testimonial.name}
              </P.Medium>
            </div>
            <P.Medium as="span">
              <i className="fas fa-map-marker-alt" tw="mr-1"></i>
              {testimonial.location}
            </P.Medium>
          </div>
        </div>
      ))}
    </Carousel>
  </LeftContent>
);

export default Testimonials;
