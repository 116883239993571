import React from "react";
import PropTypes from "prop-types"; // eslint-disable-line
import AuthRouter from "./index";

const anonymous = (authProps) => !authProps.authentication.userId;

const AnonymousRoute = (props) => (
  <AuthRouter
    failureUrl="/dashboard"
    {...props}
    successCheck={anonymous}
    shouldReturn={false}
  />
);

export default AnonymousRoute;
