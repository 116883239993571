import React, {
  DetailedHTMLProps,
  InputHTMLAttributes,
  ReactElement
} from "react";
import tw from "twin.macro";
import styled from "styled-components";

import { P } from "components/typography";

export interface InputProps
  extends DetailedHTMLProps<
    InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  error?: string | boolean;
  icon?: ReactElement;
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ error, icon, ...props }, ref) => (
    <section tw="flex flex-col flex-grow flex-shrink">
      <StyledInput hasError={!!error}>
        {icon}
        <input
          tw="border-none outline-none focus:outline-none flex-grow py-3 bg-transparent"
          ref={ref}
          {...props}
        />
      </StyledInput>
      {!!error && <P.Small tw="text-danger">{error}</P.Small>}
    </section>
  )
);
export default Input;

interface StyledInputProps {
  hasError?: boolean;
}
const StyledInput = styled.div<StyledInputProps>(({ hasError }) => [
  tw`font-montserrat rounded px-3 bg-white overflow-x-auto
  border-muted border border-solid 
  focus-within:outline-none focus-within:ring focus-within:border-primary 
  flex flex-row gap-2 items-baseline flex-grow`,
  hasError && tw`border-danger`
]);
