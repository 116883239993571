import React from "react";
import "twin.macro";
import copy from "helpers/clipboard";
import { Button } from "components/typography";

export interface ClipboardProps extends React.HTMLProps<HTMLDivElement> {
  content: string;
  disabled?: boolean;
  onSuccess: () => void;
  onFailure?: () => void;
}

const Clipboard: React.FC<ClipboardProps> = ({
  content,
  disabled,
  onSuccess,
  onFailure,
  ...props
}) =>
  disabled ? (
    <textarea
      tw="font-montserrat rounded p-3 bg-white border-muted border border-solid w-full mt-2 text-left break-all  cursor-pointer text-muted"
      value={content}
    />
  ) : (
    <div
      tw="font-montserrat rounded p-3 bg-white border-muted border border-solid w-full mt-2 text-left break-all  cursor-pointer text-muted"
      data-clipboard={!disabled}
      data-clipboard-content={content}
      onClick={copy({ content, onSuccess, onFailure })}
      {...props}
    >
      {props.children}
    </div>
  );

export type ClipboardButtonProps = {
  id?: string;
  className?: string;
  disabled?: boolean;
  content: string;
  onSuccess?: () => void;
  onFailure?: () => void;
};

export const ClipboardButton: React.FC<ClipboardButtonProps> = (props) => (
  <Button.Primary
    tw="bg-tertiary hover:bg-tertiary-dark"
    className={props.className}
    data-clipboard={!props.disabled}
    data-clipboard-content={props.content}
    onClick={copy(props)}
  >
    {props.children}
  </Button.Primary>
);

export default Clipboard;
