import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import { LinkedEventCard } from "components/cards/EventCard";
import Spinner from "components/spinner";
import Sorry from "screens/browse/sorry";

const Projects = ({ projects }) =>
  projects.map((project, i) => (
    <LinkedEventCard record={project} key={project.id + "-" + i} />
  ));

export const ProjectsList = ({ loadRemote, shouldLoad, effectKey }) => {
  const [loaded, setLoaded] = useState(false);

  const [records, setRecords] = useState([]);

  const finish = () => setLoaded(true);

  useEffect(() => {
    if (shouldLoad) {
      loadRemote().then(setRecords).then(finish);
    }
  }, [effectKey]);

  if (!shouldLoad) return null;

  if (!loaded) return <Spinner />;

  if (!records.length) return <Sorry />;

  return (
    <div className="row row-eq-height row-pad-col-bottoms">
      <Projects projects={records} />
    </div>
  );
};

ProjectsList.propTypes = {
  loadRemote: PropTypes.func,
  shouldLoad: PropTypes.bool,
  effectKey: PropTypes.any
};

export default ProjectsList;
