import api, { extractError } from "api/api";

export const fetchContentBlock = (id) => {
  return api
    .get(`/api/v2/content_blocks/${id}`)
    .then((resp) => resp.data)
    .catch((e) => {
      throw extractError(e);
    });
};
