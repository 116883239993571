import React from "react";
import tw from "twin.macro";
import styled from "styled-components";

import {
  FlashMessage as FlashMessageType,
  flashType
} from "components/CartProvider";
import { P } from "components/typography";

import FadeIn from "components/FadeIn";

const FlashMessage: React.FC<{ flash?: FlashMessageType }> = ({ flash }) => (
  <FadeIn isShown={!!flash}>
    <Message variant={flash?.type}>
      <i className="fas fa-check-circle" />
      <P.Medium as="span" tw="ml-4 font-bold">
        {flash?.message}
      </P.Medium>
    </Message>
  </FadeIn>
);
export default FlashMessage;

const Message = styled.li<{
  variant?: flashType;
}>(() => [
  tw`p-4`,
  ({ variant = flashType.success }) => flashTypeVariants[variant]
]);

const flashTypeVariants = {
  [flashType.success]: tw`bg-success-bg text-success`,
  [flashType.info]: tw`bg-info-bg text-info`
};
