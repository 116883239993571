import React from "react";
import PropTypes from "prop-types";

const slugify = (title) =>
  title
    .toString()
    .toLowerCase()
    .replace(/\s+/g, "-") // Replace spaces with -
    .replace(/[^\w\-]+/g, "") // eslint-disable-line
    .replace(/\-\-+/g, "-") // eslint-disable-line
    .replace(/^-+/, "") // Trim - from start of text
    .replace(/-+$/, ""); // Trim - from end of text

const tabs = (props) => React.Children.toArray(props.children);

const renderTabCount = (count) =>
  count !== undefined ? <span className="badge">{count}</span> : "";

const captureTab = (props, slug) => () =>
  props && props.actions && props.actions.setTab(slug); // eslint-disable-line

const tabHeading = (props, tabsTitle, slug, first, tabInstance) => (
  <li key={slug} className="nav-item">
    <a
      className={"nav-link" + (first ? " active" : "")}
      id={"pills-" + slug + "-tab"}
      data-toggle="pill"
      data-testid={slug}
      href={"#" + tabsTitle + "-" + slug}
      role="tab"
      onClick={captureTab(props, slug)}
      aria-controls={tabsTitle + "-" + slug}
      aria-selected={first ? "true" : "false"}
    >
      {tabInstance.props.titleContent || tabInstance.props.title}
      {renderTabCount(tabInstance.props.count)}
    </a>
  </li>
);

const tabBody = (tabsTitle, slug, first, tabInstance) => (
  <div
    className={"tab-pane fade" + (first ? " show active" : "")}
    id={tabsTitle + "-" + slug}
    role="tabpanel"
    key={slug}
    aria-labelledby={tabsTitle + "-" + slug}
  >
    {tabInstance.props.skipWrapper ? (
      tabInstance
    ) : (
      <div className="row row-eq-height row-pad-col-bottoms">{tabInstance}</div>
    )}
  </div>
);

export const Tab = (props) => <React.Fragment>{props.children}</React.Fragment>;

export const Tabs = (props) => {
  const currentTab = props.initial || 0;

  return (
    <React.Fragment>
      {(!props.bodyOnly && (
        <ul className="nav nav-tabs nav-fill" id="pills-tab" role="tablist">
          {tabs(props).map((tab, i) =>
            tabHeading(
              props,
              slugify(props.title),
              slugify(tab.props.title),
              i === currentTab,
              tab
            )
          )}
        </ul>
      )) ||
        ""}

      {!props.headerOnly && props.subheading ? props.subheading() : ""}

      {!props.headerOnly && (
        <div className="tab-content" id="pills-tabContent">
          {tabs(props).map((tab, i) =>
            tabBody(
              slugify(props.title),
              slugify(tab.props.title),
              i === currentTab,
              tab
            )
          )}
        </div>
      )}
    </React.Fragment>
  );
};

Tabs.propTypes = {
  initial: PropTypes.number,
  titleContent: PropTypes.string,
  title: PropTypes.string,
  children: PropTypes.any,
  headerOnly: PropTypes.bool,
  bodyOnly: PropTypes.bool,
  subheading: PropTypes.func
};

Tab.propTypes = {
  title: PropTypes.string,
  count: PropTypes.any,
  children: PropTypes.any
};

export default {
  Tabs: Tabs,
  Tab: Tab
};
