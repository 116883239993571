import React, { useState, useEffect } from "react";
import styled, { css, keyframes } from "styled-components";

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;
const fadeOut = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`;

export type FadeInProps = {
  isShown?: boolean;
  duration?: number;
  delay?: number;
  style?: any;
};
const FadeIn: React.FC<FadeInProps> = ({
  isShown = true,
  duration = 300,
  delay = 0,
  children,
  ...delegated
}) => {
  const [render, setRender] = useState(isShown);

  const [memoizedChildren, setMemoizedChildren] = useState(children);
  useEffect(() => {
    // If I'm mid animation, I don't want my rendered children to change
    // which they might b/c the condition for change could be the children
    // itself is now null
    if (isShown === render) {
      setMemoizedChildren(children);
    }
  }, [children, render, isShown]);

  useEffect(() => {
    if (isShown) setRender(true);
  }, [isShown]);

  const onAnimationEnd = () => {
    if (!isShown) setRender(false);
  };

  return render ? (
    <Wrapper
      {...delegated}
      style={{
        ...(delegated.style || {}),
        animationDuration: duration + "ms",
        animationDelay: delay + "ms"
      }}
      onAnimationEnd={onAnimationEnd}
      isShown={isShown}
    >
      {memoizedChildren}
    </Wrapper>
  ) : null;
};

const Wrapper = styled.div<{ isShown: boolean }>(
  ({ isShown }) => css`
    @media (prefers-reduced-motion: no-preference) {
      animation-name: ${isShown ? fadeIn : fadeOut};
    }
  `
);
export default FadeIn;
