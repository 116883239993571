import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";

const newPath = (prev, curr) =>
  prev.location.pathname !== curr.location.pathname;

const supportsScrollTo = (win) => win.scrollTo;

const isDown = (win) => win.scrollY > 0;

class ScrollToTop extends React.Component {
  componentDidUpdate(prevProps) {
    this.shouldScroll(prevProps, this.props, window) && this.reset(window);
  }

  reset = (win) => win.scrollTo(0, 0);

  shouldScroll = (oldProps, newProps, win) =>
    supportsScrollTo(win) && newPath(oldProps, newProps) && isDown(win);

  render() {
    return this.props.children;
  }
}

ScrollToTop.propTypes = {
  children: PropTypes.any
};

export default withRouter(ScrollToTop);
