import React from "react";
import styled, { css } from "styled-components";

import { Link } from "react-router-dom";
import { Membership } from "api/v2/memberships.types";

import tw from "twin.macro";

import { H, P, A, Label } from "components/typography";
import { Card } from "components/cards";
import { readableDate } from "helpers/helper";
import { currencyFormatter } from "helpers/formatters";

const MembershipsList: React.FC<{ memberships: Membership[] }> = ({
  memberships
}) => {
  if (memberships.length < 1) {
    return (
      <P.Medium>
        You have no orders here.{" "}
        <Link to={`/dashboard`} component={A.Primary}>
          Browse upcoming events on your dashboard.
        </Link>
      </P.Medium>
    );
  }

  return (
    <ul tw="m-0 p-0 list-none">
      {memberships?.map((membership) => (
        <Card.Tertiary
          as="li"
          key={membership.id}
          tw="p-0 flex flex-col md:flex-row md:h-64 mb-6"
        >
          <RestaurantImage bgAvatar={membership.eventImage || "none"} />
          <div tw="flex flex-col justify-end h-64 md:h-auto md:grid md:grid-cols-2 px-6 md:px-0 py-9 w-full md:w-[calc(100% - 16rem)] z-10 gap-2">
            <H.Three
              as="h2"
              tw="px-0 md:px-6 md:col-span-2  text-white md:text-text"
            >
              {membership.restaurantName}
            </H.Three>
            <div tw="col-span-2 row-span-2 px-0 md:px-6 mb-4">
              {membership.d2d ? (
                <Label.Green tw="mr-2">Delivery to my door</Label.Green>
              ) : (
                <Label.Info tw="mr-2">Pick up</Label.Info>
              )}
            </div>
            <LeftColumn tw="flex flex-row ">
              <i
                className="fas fa-calendar-day"
                tw="text-white md:text-muted mr-2"
              />
              <StyledP>
                {membership.d2d
                  ? readableDate(membership.eventRunAt, true)
                  : membership.pickupTime}
              </StyledP>
            </LeftColumn>
            <RightColumn tw="hidden md:block">
              <StyledP tw="truncate">
                Includes:{" "}
                {membership.lineItems
                  .filter((li) => !!li.productName)
                  .map((li) => `${li.quantity} ${li.productName}`)
                  .join(", ")}
              </StyledP>
            </RightColumn>
            <LeftColumn tw="flex flex-row ">
              <i
                className="fas fa-map-marker-alt"
                tw="text-white md:text-muted mr-2"
              />
              <StyledP>
                {membership.d2d
                  ? membership.customerAddress
                  : `${membership.pickupStreet}, ${membership.pickupCityStateZip}`}
              </StyledP>
            </LeftColumn>
            <RightColumn tw="hidden md:block">
              <StyledP>
                Total: {currencyFormatter(membership.totalPaid)}
              </StyledP>
            </RightColumn>
            <LeftColumn tw="hidden md:block pb-0">
              <StyledP>
                <Link
                  to={`/orders/${membership.id}/receipt#pickup`}
                  component={A.Primary}
                >
                  View {membership.d2d ? "Delivery" : "Pickup"} Details
                </Link>
              </StyledP>
            </LeftColumn>
            <RightColumn tw="hidden md:block pb-0">
              <StyledP>
                <Link
                  to={`/orders/${membership.id}/receipt#order-summary`}
                  component={A.Primary}
                >
                  View Receipt
                </Link>
              </StyledP>
            </RightColumn>
          </div>
          <div tw="md:hidden p-6">
            <StyledP>
              <Link
                to={`/orders/${membership.id}/receipt#pickup`}
                component={A.Primary}
              >
                View {membership.d2d ? "Delivery" : "Pickup"} Details
              </Link>
            </StyledP>
            <StyledP>
              <Link
                to={`/orders/${membership.id}/receipt#order-summary`}
                component={A.Primary}
              >
                View Receipt
              </Link>
            </StyledP>
          </div>
        </Card.Tertiary>
      ))}
    </ul>
  );
};

export default MembershipsList;

const StyledP = styled(P.Small)(tw`text-white md:text-text`);

const LeftColumn = styled.div(
  tw`p-0 md:(pl-6 pr-3 border border-0 border-r border-solid border-secondary)`
);
const RightColumn = styled.div(tw`p-0 md:(pl-3 pr-6)`);

interface RestaurantImageProps {
  bgAvatar: string;
}
const RestaurantImage = styled.section<RestaurantImageProps>(() => [
  tw`w-[calc(100vw - 3rem)] md:w-64 
      h-64 
      bg-cover bg-center 
      rounded-t md:rounded-l 
      absolute md:relative 
      z-0`,
  css`
    background-image: linear-gradient(
        to top,
        rgba(0, 0, 0, 1),
        rgba(0, 0, 0, 0) 75%
      ),
      url(${(props: RestaurantImageProps) => props.bgAvatar || "none"});
    @media (min-width: 768px) {
      background-image: url(${(props: RestaurantImageProps) =>
        props.bgAvatar || "none"});
    }
  `
]);
