import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Router } from "react-router-dom";
import connectComponent from "redux/connectComponent";
import history from "redux/history";
import Tracker from "lib/tracker";
import ahoy from "ahoy.js";
import Routes from "config/routes";
import { leadParameters } from "./helpers/helper";
import ScrollToTop from "App/shared/scrollToTop";
import ErrorBoundary from "components/ErrorBoundary";
import AuthProvider from "components/AuthProvider";
import CartProvider from "components/CartProvider";

import { QueryClient, QueryClientProvider } from "react-query";
import { initializeMonitoring } from "./monitoring";
import { toastContainer as ToastContainer } from "screens/shared/toast";

import GlobalStyles from "./styles/GlobalStyles";

import Banner from "screens/banner";

const queryClient = new QueryClient();

const captureURLData = (actions) => (loc) => {
  ahoy.trackView();
  actions.captureLeadTracking(leadParameters(loc));
  actions.setQueryParameters(loc);
};

const prepareSession = (actions) => {
  actions.migrateStore();
  actions.checkAuthentication();
  actions.bumpVersion();
};

initializeMonitoring();

const App = ({ actions }) => {
  useEffect(() => {
    captureURLData(actions)(history.location);
    prepareSession(actions);
    return history.listen(captureURLData(actions));
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <ErrorBoundary>
        <CartProvider>
          <AuthProvider>
            <GlobalStyles />
            <Router history={history}>
              <ScrollToTop>
                <Banner />
                <Routes />
                <Tracker />
                <ToastContainer />
              </ScrollToTop>
            </Router>
          </AuthProvider>
        </CartProvider>
      </ErrorBoundary>
    </QueryClientProvider>
  );
};

App.propTypes = {
  actions: PropTypes.object
};

export default connectComponent(App, []);
