import React, { useEffect, useState } from "react";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import connectComponent from "redux/connectComponent";

import Spinner from "components/spinner";
import Sorry from "./sorry";

import EventCard from "components/cards/EventCard";
import { Link } from "react-router-dom";

type ProjectProps = {
  actions: any;
  browse: boolean;
  count?: number;
  postalCode?: string;
  postalCodes?: string[];
  excludeIds?: string[];
};
export const Projects: React.FC<ProjectProps> = ({
  actions,
  browse = false,
  postalCode,
  postalCodes,
  count,
  excludeIds
}) => {
  const [loaded, setLoaded] = useState(false);

  const [events, setEvents] = useState<any[]>([]);

  const finish = () => setLoaded(true);

  const singlePostalCode = postalCode || (postalCodes || [])[0];

  useEffect(() => {
    if (singlePostalCode)
      actions
        .loadSuggestedProjects({
          postal_code: singlePostalCode,
          ...(count ? { count } : {})
        })
        .then(setEvents)
        .then(finish)
        .catch(finish);
  }, [singlePostalCode]);

  if (!singlePostalCode)
    return (
      <Container>
        <Typography variant="h5">
          Give us your location to see some relevant groups!
        </Typography>
      </Container>
    );

  if (!loaded)
    return (
      <div style={{ justifyContent: "center", display: "flex" }}>
        <Spinner disabled={false} />
      </div>
    );

  if (!events.length) return <Sorry />;

  const eventsToShow = events
    .filter((e) => !excludeIds || !excludeIds.includes(e.id.toString()))
    .slice(0, count);
  return (
    <>
      {eventsToShow.map((event, i) => (
        <Link
          to={browse ? "/browse" : `/events/${event["id"]}`}
          key={event["id"] + "-" + i}
          data-testid={`event`}
          style={{ display: "contents" }}
        >
          <EventCard record={event} />
        </Link>
      ))}
    </>
  );
};

export default connectComponent(Projects, ["authentication", "relevance"]);
