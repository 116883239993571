import React from "react";
import ReactDOM from "react-dom";

import Pure from "./pure";

import "./index.scss";

import * as serviceWorker from "./serviceWorker";

import { getAuthToken } from "./actions/account";

require("@dwellsocial/dskit/dist/js/scripts.js");

import { Base as base } from "@dwellsocial/dwellsocial-api";

base.setHost(process.env.REACT_APP_API_HOST); // eslint-disable-line

const authToken = getAuthToken();

if (authToken) base.setToken(authToken);

document.title = "DwellSocial";

ReactDOM.render(<Pure />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
