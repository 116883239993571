import React, { createRef } from "react";
import PropTypes from "prop-types";
//import { LocationOn } from "@material-ui/icons";
import { TextField } from "@material-ui/core";
//import Hidden from "@material-ui/core/Hidden";

// import Container from '@material-ui/core/Container';

import connectComponent from "redux/connectComponent";

//import Dialog from "@material-ui/core/Dialog";
//import DialogActions from "@material-ui/core/DialogActions";
//import DialogContent from "@material-ui/core/DialogContent";
//import DialogContentText from "@material-ui/core/DialogContentText";
//import DialogTitle from "@material-ui/core/DialogTitle";
import "./style.css";

const Zip = ({ actions, onChange, database }) => {
  //const [open, setOpen] = useState(false);
  const zipRef = createRef();

  //const handleClickOpen = () => setOpen(true);

  //const handleClose = () => setOpen(false);

  const onSave = (event) => {
    event.preventDefault();

    const value = zipRef.current.value;

    actions.setCurrentZip(value);

    if (onChange) onChange(value);

    //handleClose();
  };

  return (
    <div className="text-right pt-3">
      <TextField
        autoFocus
        margin="dense"
        id="name"
        label={database.authentication.currentZip() ? "Update Zip" : "Add Zip"}
        type="zip"
        defaultValue={database.authentication.currentZip() || ""}
        inputProps={{
          pattern: "[0-9]{5}"
        }}
        inputRef={zipRef}
        fullWidth
        onChange={onSave}
        data-testid="location-tx-field"
      />
    </div>
  );
};

Zip.propTypes = {
  actions: PropTypes.object,
  database: PropTypes.object,
  onChange: PropTypes.func
};

export default connectComponent(Zip, ["authentication"]);
