import React from "react";
import "twin.macro";
import dayjs from "dayjs";

import { Event } from "api/v2/event.types";

import { FormSection, Legend, Description } from "../styles";
import RadioButtonInput from "components/forms/RadioButtonInput";
import ErrorMessage from "components/forms/ErrorMessage";

const TimeSlot: React.FC<{
  register: any;
  watchedTimeSlot?: string;
  hasError: boolean;
  timeSlots: Event["timeSlots"];
  availableTimeSlots: number[];
}> = ({
  register,
  watchedTimeSlot,
  hasError,
  timeSlots,
  availableTimeSlots
}) => {
  return (
    <FormSection tw="md:items-center">
      <Description>
        <Legend hasError={hasError}>Select pickup time</Legend>
        <ErrorMessage hasError={hasError}>
          Please select a time slot
        </ErrorMessage>
      </Description>
      <div tw="flex flex-row gap-2">
        {timeSlots
          .filter((slot) => !slot.full)
          .map((slot) => (
            <RadioButtonInput
              name={`timeSlotId`}
              id={`timeSlotId-${slot.id}`}
              ref={register({ required: true })}
              type="radio"
              value={slot.id.toString()}
              key={slot.id}
              isChecked={
                !watchedTimeSlot ||
                [slot.id.toString(), ""].includes(watchedTimeSlot)
              }
              disabled={!availableTimeSlots.includes(slot.id)}
            >
              {dayjs(slot.time).format("h:mm A")}
            </RadioButtonInput>
          ))}
      </div>
    </FormSection>
  );
};

export default TimeSlot;
