import React, { useState } from "react";
import "twin.macro";
import { Button } from "components/typography";
import InviteDialog from "./inviteDialog";

export type InviteButtonProps = {
  shareUrl: string;
  eventId: string;
  primaryBtn?: boolean;
};
const InviteButton: React.FC<InviteButtonProps> = ({
  shareUrl,
  eventId,
  primaryBtn = false,
  children
}) => {
  const [showDialog, setShowDialog] = useState(false);

  return (
    <>
      {primaryBtn ? (
        <Button.Primary
          tw="w-full lg:w-auto"
          onClick={() => setShowDialog(true)}
        >
          {children}
        </Button.Primary>
      ) : (
        <Button.Secondary
          tw="w-full lg:w-auto"
          onClick={() => setShowDialog(true)}
        >
          {children}
        </Button.Secondary>
      )}

      <InviteDialog
        isOpen={showDialog}
        onDismiss={() => setShowDialog(false)}
        shareUrl={shareUrl}
        eventId={eventId}
      />
    </>
  );
};

export default InviteButton;
