import React, { useState, useEffect } from "react";
import "twin.macro";

import { Button, P } from "components/typography";

import { Link } from "react-router-dom";
import {
  useCart,
  actionTypes as cartActionTypes
} from "components/CartProvider";
import Popover, { usePopover } from "components/Popover";
import { positionRight } from "@reach/popover";

import CartItem from "./cartItem";
import FlashMessage from "./flashMessage";

const CartButton: React.FC<{ eventId: string }> = ({ eventId }) => {
  const cart = useCart();

  const eventCart = cart.state[eventId];
  const itemCount = eventCart?.items.length || 0;

  const {
    btnRef,
    popOverRef,
    isPopoverOpen: isCartOpen,
    setIsPopoverOpen: setIsCartOpen
  } = usePopover();

  const dispatchSeeFlashMessage = () => {
    cart.dispatch({
      type: cartActionTypes.SEE_FLASH_MESSAGE,
      value: { cartId: eventId }
    });
  };

  const [flashTimer, setFlashTimer] = useState<ReturnType<typeof setTimeout>>();
  useEffect(() => {
    if (eventCart?.flashMessage) {
      setIsCartOpen(true);
      setFlashTimer(setTimeout(dispatchSeeFlashMessage, 5000));
    }
  }, [eventCart?.flashMessage?.message]);

  useEffect(() => {
    if (!isCartOpen && eventCart?.flashMessage) {
      flashTimer && clearTimeout(flashTimer);
      dispatchSeeFlashMessage();
    }
  }, [isCartOpen]);

  return (
    <>
      <Button.Primary
        onClick={() => setIsCartOpen((prevState) => !prevState)}
        ref={btnRef}
      >
        <i className="fas fa-shopping-cart" tw="pr-1" /> {itemCount}
      </Button.Primary>
      <Popover
        hidden={!isCartOpen}
        targetRef={btnRef}
        ref={popOverRef}
        position={positionRight}
        tw="z-30"
      >
        <div tw="mt-2 border border-secondary border-solid bg-white shadow rounded max-w-[320px] w-[calc(100vw - 80px)]">
          <ul tw="p-0 m-0 list-none">
            {isCartOpen && <FlashMessage flash={eventCart?.flashMessage} />}
            <ul tw="p-0 m-0 list-none overflow-y-scroll max-h-[calc(100vh - 12rem)]">
              {eventCart?.items.map((item, i) => (
                <CartItem key={i} index={i} eventId={eventId} item={item} />
              ))}
            </ul>
            {itemCount < 1 && (
              <li tw="p-4">
                <P.Small tw="text-muted text-center">
                  Your cart is empty.
                  <br /> Add something delicious!
                </P.Small>
              </li>
            )}
            {itemCount > 0 && (
              <li tw="p-4">
                <Link
                  to={`/events/${eventId}/checkout`}
                  component={(props) => (
                    <Button.Primary
                      as="a"
                      tw="w-full block text-center hover:text-white"
                      {...props}
                    />
                  )}
                >
                  Place Order
                </Link>
              </li>
            )}
          </ul>
        </div>
      </Popover>
    </>
  );
};

export default CartButton;
