// Used to collect phone/address info from users from several places
// (post join, post login for grandfathered accounts, post registration for when contact info was unparseable)
import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import connectComponent from "../../redux/connectComponent";
import { Redirect } from "react-router-dom";
import Errors from "components/errors";
import PhoneField from "../shared/forms/phoneField";
import AddressAutocompleteField from "../shared/forms/addressAutocompleteField";
import { join, tagJoin } from "screens/project/membership";

// import ProjectHeader from "./header"
import withWrap from "components/withWrap";

// form helpers to extract
const Label = (labelProps) => (
  <label htmlFor={labelProps.id}>{labelProps.children}</label>
);

const Input = (inputProps) => (
  <input
    id={inputProps.id}
    ref={inputProps.inputRef}
    type="text"
    className="form-control"
    defaultValue={inputProps.value}
  />
);

const Field = (fieldProps) => (
  <div className={"form-group " + fieldProps.className}>
    <Label id={fieldProps.id}>{fieldProps.label}</Label>
    <Input
      id={fieldProps.id}
      inputRef={fieldProps.inputRef}
      type={fieldProps.type || "text"}
      {...(fieldProps.inputProps || {})}
    />
  </div>
);

export class UpdateInfo extends PureComponent {
  static propTypes = {
    actions: PropTypes.object,
    authentication: PropTypes.object,
    id: PropTypes.any,
    history: PropTypes.object,
    onSuccess: PropTypes.func,
    onFailure: PropTypes.func,
    renderHeading: PropTypes.bool
  };

  state = {
    errors: {},
    contact: {},
    updated: false
  };

  constructor(props) {
    super(props);
    this.initBasicRefs();
    this.initHomeRefs();
  }

  initBasicRefs = () => {
    this.phoneRef = React.createRef();
  };

  initHomeRefs = () => {
    // Home fields for update
    this.streetRef = React.createRef();
    this.unitNumberRef = React.createRef();
    this.cityRef = React.createRef();
    this.stateRef = React.createRef();
    this.postalCodeRef = React.createRef();
  };

  // address = props => DB.normalizedHome(props)
  //
  // phone = props => DB.normalizedPhone(props)
  //
  handleSubmit = (event) => {
    // console.log("Handling Submit", this.addressRef.current.value)
    event.preventDefault();

    const user = {
      phone: this.phoneRef.current.value,
      ...(Object.keys(this.state.contact).length
        ? this.state.contact
        : {
            street: this.streetRef.current.value,
            city: this.cityRef.current.value,
            state: this.stateRef.current.value,
            postal_code: this.postalCodeRef.current.value
          })
    };

    if (!this.validAddress(user)) {
      this.addError("address", "Must provide a valid address");
      return false;
    }

    if (!this.validPhone(user.phone)) {
      this.addError("phone", "Must provide a valid phone");
      return false;
    }

    this.props.actions
      .updateUser(user)
      .then(this.onUpdate)
      .catch(
        (err) =>
          console.log("ERR", err) ||
          (err.then && err.then((errObj) => this.addErrors(errObj.errors)))
      );

    return false;
  };

  addError = (key, error) => this.addErrors({ [key]: error });

  addErrors = (newErrors) => {
    let errors = { ...this.state.errors, ...newErrors };
    this.setState({ errors: errors });
  };

  projectId = (props) => props.id;

  // Go to the project show
  onUpdate = () =>
    this.props.actions.checkAuthentication().then(() =>
      this.projectId(this.props)
        ? join(this.props, this.projectId(this.props))
            .then(() => tagJoin(this.props))
            .catch((err) => console.log("Join after Info Err", err))
            .then(() => this.setState({ updated: true }))
        : this.setState({ updated: true })
    );

  // Address needs to be more than a zipcode
  validAddress = (user) =>
    user?.street?.length && user?.postal_code?.match(/^\d{5}$/);

  validPhone = (phone) => phone && phone.match(/\d/g).length === 10;

  user = (props) => props.database.authentication.user();

  // Autocomplete Address (for non-existing address)
  renderAddressField = () => (
    <div className="input-form">
      <label htmlFor="address">Home Address</label>

      <AddressAutocompleteField
        id="address"
        name="address"
        onChange={this.setAddress}
        required="required"
      />

      <Errors errors={this.state.errors.address} />
    </div>
  );

  // Full fields for editing home
  renderAddressFields = (user) => (
    <React.Fragment>
      <div className="form-row">
        <Field
          id="user-home-street"
          inputRef={this.streetRef}
          label="Street"
          inputProps={{
            size: 20,
            value: user.street,
            autoFill: "street-address"
          }}
          className="col-md-8"
        />
      </div>
      <div className="form-row">
        <Field
          id="user-home-city"
          inputRef={this.cityRef}
          label="City"
          inputProps={{ value: user.city }}
          className="col-md-6"
        />
        <Field
          id="user-home-state"
          inputRef={this.stateRef}
          label="State"
          inputProps={{ value: user.state }}
          className="col-md-3"
        />
        <Field
          id="user-home-postal_code"
          inputRef={this.postalCodeRef}
          label="Postal Code"
          inputProps={{ value: user.postal_code, autoFill: "postal-code" }}
          className="col-md-3"
        />
      </div>
    </React.Fragment>
  );

  // renderHeading()
  //  <div className="container">
  //         <h6 className="green"><strong>Step 1</strong> Verify Your Information</h6>
  //         <p>We need to be sure your address is in the group&#39;s service area and we&#39;ll need a way to reach you.</p>
  //       </div>

  setAddress = (contact) => this.setState({ contact });

  shouldGoToDashboard = (props, state) =>
    !this.projectId(props) && state.updated;

  shouldGoToProject = (props, state) => this.projectId(props) && state.updated;

  render() {
    const user = this.props.authentication.user();

    if (this.shouldGoToProject(this.props, this.state)) {
      return <Redirect to={"/projects/" + this.projectId(this.props)} />;
    }

    if (this.shouldGoToDashboard(this.props, this.state)) {
      return <Redirect to="/dashboard" />;
    }
    return (
      <React.Fragment>
        {this.state.error}

        {this.props.renderHeading && this.props.renderHeading()}
        <div className="container">
          <fieldset>
            <div className="form-group">
              {user?.street?.length && user?.postal_code?.length
                ? this.renderAddressFields()
                : this.renderAddressField(this.props)}
            </div>

            <div className="form-group">
              {this.state.errors.phone && (
                <p className="error">
                  {this.phoneRef.current.value + " " + this.state.errors.phone}
                </p>
              )}
              <PhoneField
                id="user_info_phone"
                title="Mobile Phone"
                name="user_info[phone]"
                inputRef={this.phoneRef}
              />
            </div>
          </fieldset>

          <p className="mt-4">
            <button
              onClick={this.handleSubmit}
              type="submit"
              value="Finish"
              id="finish"
              name="commit"
              className="btn btn-primary"
              data-testid="update-info"
            >
              Finish
            </button>
          </p>
        </div>
      </React.Fragment>
    );
  }
}

export default withWrap(
  connectComponent(UpdateInfo, ["authentication", "users"])
);
