import React from "react";

import Hero from "./hero";
import Partners from "./partners";
import Testimonials from "./testimonials";
import MailingListCta from "components/composites/MailingListCta";
import Footer from "screens/footer";

// TODO: use a smaller webp. header.webp is 1.4MB (too big for homepage)
import headerImg from "./header_web.jpg";

import { LeftContent, Content, Column } from "./containers";
import { P, H } from "components/typography";
import "twin.macro";

const Home: React.FC = () => (
  <article>
    <Hero />
    <Partners />

    <section tw="block lg:flex flex-row py-2.5">
      <Column tw="flex justify-end h-inherit">
        <LeftContent tw="text-center lg:text-left">
          <div>
            <i className="fas fa-heart" tw="text-3xl pb-3"></i>
            <H.Two tw="pb-4">Browse + Order</H.Two>
            <P.Medium tw="lg:pb-5">
              We work with the best restaurants in the city to bring you the
              best food--without the traffic or parking. Meal planning,
              birthdays, anniversaries, BBQs, and your Thursday night dinner
              have never been easier at home!
            </P.Medium>
          </div>
          <div tw="mt-6 lg:mt-0">
            <i className="fas fa-laugh-beam" tw="text-3xl pb-3"></i>
            <H.Two tw="pb-4">Pick up + Enjoy!</H.Two>
            <P.Medium>
              We bring the food to your area--meet us for pickup and take it
              home to your family. Prep and heat your dinner, and enjoy a
              stress-free dinner from an old--or new--favorite!
            </P.Medium>
          </div>
        </LeftContent>
      </Column>
      <Column tw="hidden lg:flex h-inherit">
        <Content
          tw="p-0 bg-cover bg-center my-8"
          style={{ backgroundImage: `url('${headerImg}')` }}
        />
      </Column>
    </section>

    <section tw="block lg:flex flex-row mb-20">
      <Column tw="flex justify-end h-inherit">
        <Testimonials />
      </Column>
      <Column tw="flex mt-6 lg:mt-0 h-inherit">
        <div tw="w-full lg:w-3/4 flex h-inherit">
          <MailingListCta />
        </div>
      </Column>
    </section>
    <Footer />
  </article>
);

export default Home;
