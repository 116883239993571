const updateInfoUrl = (id) => `/projects/${id}/update_info`;
const projectUrl = (id) => `/projects/${id}`;
const goToProject = (props, id) => props.history.push(projectUrl(id));
export const goToPaymentForm = (props, id) =>
  props.history.push(updateInfoUrl(id));

export const onLeave = (props) => (event) => {
  event.preventDefault();
  return (
    window.confirm("Are you sure you wish to leave this group?") &&
    props.actions
      .leaveProject(props.id)
      .then(() => goToProject(props, props.id))
  );
};

// Low Level Join Action
export const join = (props, id, paymentIntentId = null) =>
  props.actions.joinProject(id, paymentIntentId);

export const tagJoin = (props, { value = 0 }) =>
  props.actions.optimizeEvent("join", {
    event_label: "method",
    event_category: "engagement",
    revenue: value
  });

export const joinable = (props, id) =>
  props.database.projects.joinable({
    id,
    user: props.database.authentication.user()
  });
