import React from "react";
import tw from "twin.macro";
import styled from "styled-components";

import { P, List } from "components/typography";
import { currencyFormatter } from "helpers/formatters";

import NonProductLineItem from "./nonProductLineItem";

type LineItem = {
  name: string;
  sku: string | null;
  price: number;
  notes: string | null;
  description?: string | null;
  quantity: number;
  optionChoices?: { choiceName: string; optionName: string }[] | null;
};

const OrderSummary: React.FC<{
  isLoading: boolean;
  productLineItems?: LineItem[];
  nonProductLineItems?: LineItem[];
  promoCodeLineItem?: LineItem;
  creditLineItem?: LineItem;
  d2dLineItem?: LineItem;
  subtotal?: number;
  totalWithGratuity?: number;
  totalDue?: number | string;
}> = ({
  isLoading,
  productLineItems,
  nonProductLineItems,
  promoCodeLineItem,
  creditLineItem,
  d2dLineItem,
  subtotal,
  totalWithGratuity,
  totalDue,
  children
}) => {
  return (
    <CartList isLoading={isLoading}>
      {productLineItems?.map((item, i) => (
        <CartLi key={i}>
          <div tw="min-w-0">
            <P.Small>
              {item.quantity} x {item.name}
            </P.Small>
            {item.optionChoices &&
              Object.entries(item.optionChoices)?.map(([key, optionChoice]) => (
                <P.XSmall tw="text-muted" key={key}>
                  {optionChoice.optionName}: {optionChoice.choiceName}
                </P.XSmall>
              ))}
            {item.notes && (
              <P.XSmall tw="text-muted truncate">NOTE: {item.notes}</P.XSmall>
            )}
          </div>

          <P.Small tw="text-right">
            {currencyFormatter(item.price * item.quantity)}
          </P.Small>
        </CartLi>
      ))}
      {subtotal && <NonProductLineItem name="Subtotal" price={subtotal} />}
      {promoCodeLineItem && (
        <NonProductLineItem
          name={promoCodeLineItem.name}
          price={promoCodeLineItem.price}
        />
      )}
      {nonProductLineItems?.map((item, i) => (
        <NonProductLineItem
          name={item.name}
          price={item.price}
          description={item.description}
          key={`nonproductli_${i}`}
        />
      ))}
      {d2dLineItem && (
        <>
          <NonProductLineItem
            name={d2dLineItem.name}
            price={d2dLineItem.price}
            tw="border-t border-b pb-4 mt-4"
          />
        </>
      )}
      {totalWithGratuity && (
        <NonProductLineItem name="Total" tw="pb-4" price={totalWithGratuity} />
      )}
      {creditLineItem && totalDue !== undefined && (
        <>
          <NonProductLineItem
            name={creditLineItem.name}
            price={creditLineItem.price}
            tw="border-t border-b pb-4"
          />
          <NonProductLineItem
            name="Total Due"
            tw="pb-4 border-b"
            price={totalDue}
          />
        </>
      )}
      {children}
    </CartList>
  );
};

export default OrderSummary;

interface CartListProps {
  isLoading: boolean;
}
const CartList = styled(List.Cart.List)<CartListProps>(({ isLoading }) => [
  tw`mt-4`,
  isLoading && tw`opacity-70`
]);

export const CartLi = styled(List.Cart.Li)(
  tw`flex flex-row justify-between items-start`
);
