import { theme } from "twin.macro";
import { createGlobalStyle } from "styled-components";

// We don't support tailwind's full preflight yet,
// so this is a stopgap copied from twin.macro
// omitting the normalized css (globalPreflightStyles)
// and globalRingStyles
//
// Reference:  https://github.com/ben-rogerson/twin.macro/blob/master/src/config/globalStyles.js

export const globalTransformStyles = {
  "*, ::before, ::after": {
    "--tw-translate-x": "0",
    "--tw-translate-y": "0",
    "--tw-rotate": "0",
    "--tw-skew-x": "0",
    "--tw-skew-y": "0",
    "--tw-scale-x": "1",
    "--tw-scale-y": "1"
  }
};

export const globalTouchActionStyles = {
  "*, ::before, ::after": {
    "--tw-pan-x": "var(--tw-empty,/*!*/ /*!*/)",
    "--tw-pan-y": "var(--tw-empty,/*!*/ /*!*/)",
    "--tw-pinch-zoom": "var(--tw-empty,/*!*/ /*!*/)"
  }
};

export const globalScrollSnapTypeStyles = {
  "*, ::before, ::after": {
    "--tw-scroll-snap-strictness": "proximity"
  }
};

export const globalFontVariantNumericStyles = {
  "*, ::before, ::after": {
    "--tw-ordinal": "var(--tw-empty,/*!*/ /*!*/)",
    "--tw-slashed-zero": "var(--tw-empty,/*!*/ /*!*/)",
    "--tw-numeric-figure": "var(--tw-empty,/*!*/ /*!*/)",
    "--tw-numeric-spacing": "var(--tw-empty,/*!*/ /*!*/)",
    "--tw-numeric-fraction": "var(--tw-empty,/*!*/ /*!*/)"
  }
};

export const globalBoxShadowStyles = {
  "*, ::before, ::after": {
    "--tw-shadow": "0 0 #0000",
    "--tw-shadow-colored": "0 0 #0000"
  }
};

export const globalFilterStyles = {
  "*, ::before, ::after": {
    "--tw-blur": "var(--tw-empty,/*!*/ /*!*/)",
    "--tw-brightness": "var(--tw-empty,/*!*/ /*!*/)",
    "--tw-contrast": "var(--tw-empty,/*!*/ /*!*/)",
    "--tw-grayscale": "var(--tw-empty,/*!*/ /*!*/)",
    "--tw-hue-rotate": "var(--tw-empty,/*!*/ /*!*/)",
    "--tw-invert": "var(--tw-empty,/*!*/ /*!*/)",
    "--tw-saturate": "var(--tw-empty,/*!*/ /*!*/)",
    "--tw-sepia": "var(--tw-empty,/*!*/ /*!*/)",
    "--tw-drop-shadow": "var(--tw-empty,/*!*/ /*!*/)"
  }
};

export const globalBackdropStyles = {
  "*, ::before, ::after": {
    "--tw-backdrop-blur": "var(--tw-empty,/*!*/ /*!*/)",
    "--tw-backdrop-brightness": "var(--tw-empty,/*!*/ /*!*/)",
    "--tw-backdrop-contrast": "var(--tw-empty,/*!*/ /*!*/)",
    "--tw-backdrop-grayscale": "var(--tw-empty,/*!*/ /*!*/)",
    "--tw-backdrop-hue-rotate": "var(--tw-empty,/*!*/ /*!*/)",
    "--tw-backdrop-invert": "var(--tw-empty,/*!*/ /*!*/)",
    "--tw-backdrop-opacity": "var(--tw-empty,/*!*/ /*!*/)",
    "--tw-backdrop-saturate": "var(--tw-empty,/*!*/ /*!*/)",
    "--tw-backdrop-sepia": "var(--tw-empty,/*!*/ /*!*/)"
  }
};

export const globalKeyframeStyles = () => {
  const keyframes = theme("keyframes");
  if (!keyframes) return;

  const output = Object.entries(keyframes).reduce(
    (result, [name, frames]) => ({ ...result, [`@keyframes ${name}`]: frames }),
    {}
  );
  return output;
};

const globalStyles = [
  globalKeyframeStyles(),
  globalTransformStyles,
  globalTouchActionStyles,
  globalScrollSnapTypeStyles,
  globalFontVariantNumericStyles,
  globalBoxShadowStyles,
  globalFilterStyles,
  globalBackdropStyles
];

export default createGlobalStyle(globalStyles);
