import React, { cloneElement, useEffect } from "react";
import PropTypes from "prop-types";
import { capitalize, pluralize } from "helpers/helper";
import connectComponent from "redux/connectComponent";
import Spinner from "components/spinner";

const ReferenceField = ({
  actions,
  children,
  database,
  id,
  preloaded,
  reference,
  record
}) => {
  const model = pluralize(null, reference);

  const object = record || database[model][reference](id);

  const effectKey = `${database.authentication.version()}-${reference}-${id}`;

  useEffect(() => {
    if (!preloaded) record || actions[`load${capitalize(reference)}`](id);
  }, [effectKey]);

  if (!id) return null;

  if (!object) return <Spinner />;

  return cloneElement(children, { record: object });
};

ReferenceField.propTypes = {
  actions: PropTypes.object,
  children: PropTypes.any,
  database: PropTypes.object,
  id: PropTypes.any,
  reference: PropTypes.string,
  record: PropTypes.object,
  field: PropTypes.string,
  preloaded: PropTypes.bool
};

export default connectComponent(ReferenceField, [
  "authentication",
  "avatars",
  "projects",
  "products",
  "professionals"
]);
