import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import connectComponent from "redux/connectComponent";
import * as DB from "helpers/db";
import Projects from "./projects";

const CompletedProjects = (props) => {
  const { actions, authentication } = props;

  const [loaded, setLoaded] = useState(false);

  const userId = authentication.userId;

  useEffect(() => {
    actions.loadProjectMembershipsForUserId(userId).then(() => setLoaded(true));
  }, [authentication.version]);

  return <Projects ids={DB.completeProjectIds(props)} loaded={loaded} />;
};

CompletedProjects.propTypes = {
  actions: PropTypes.object,
  authentication: PropTypes.object
};

export default connectComponent(CompletedProjects, [
  "authentication",
  "projects",
  "project_memberships"
]);
