import React from "react";
import Container from "components/container";
import SectionHeading from "components/sectionHeading";
const Support = () => (
  <Container>
    <SectionHeading>Contact DwellSocial</SectionHeading>
    <h4 className="text-center">We&#39;re happy to help!</h4>
    <br />
    <div className="text-center col-md-6 mx-auto">
      <p>
        <a href="mailto:support@dwellsocial.zendesk.com">Email us</a> or click
        on the bubble in the bottom right corner to chat with us.
      </p>
    </div>
  </Container>
);

export default Support;
