import React from "react";

const copySafari = (el: HTMLTextAreaElement) => {
  const readOnly = el.readOnly;
  el.readOnly = false;
  const range = document.createRange();
  range.selectNodeContents(el);
  const selection = window.getSelection();
  selection?.removeAllRanges();
  selection?.addRange(range);
  el.setSelectionRange(0, 999999);
  el.readOnly = readOnly;
  return el;
};

const copyGeneric = (el: HTMLTextAreaElement) => el.select();

const ios = () => navigator.userAgent.match(/ipad|ipod|iphone/i);

const element = (content: string) => {
  const el = document.createElement("textarea");
  el.value = content;
  el.style.position = "absolute";
  el.style.left = "-9999px";
  return el;
};

const addElement = (el: HTMLElement) =>
  (
    document.querySelectorAll('[role="dialog"]')[0] || document.body
  ).appendChild(el);

const clearElement = (el: HTMLElement) =>
  (
    document.querySelectorAll('[role="dialog"]')[0] || document.body
  ).removeChild(el);

const copy: (props: {
  onSuccess?: () => void;
  onFailure?: () => void;
  content: string;
}) => React.MouseEventHandler = (props) => (event) => {
  event.preventDefault();

  if (navigator.clipboard) {
    navigator.clipboard.writeText(props.content).then(
      function () {
        return props.onSuccess && props.onSuccess();
      },
      function (err) {
        console.error("Could not copy text: ", err);
        return props.onFailure && props.onFailure();
      }
    );
  } else {
    console.log("deprecated");
    // document.execCommand is deprecated but sometimes people use old browsers so
    const el = element(props.content);

    addElement(el);

    // Jump through hoops for ios Select
    const copyMethod = ios() ? copySafari : copyGeneric;

    copyMethod(el);
    document.execCommand("Copy");

    clearElement(el);

    el.blur();

    return props.onSuccess && props.onSuccess();
  }
};

export default copy;
