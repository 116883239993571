import React from "react";
import PropTypes from "prop-types";
import { Filters } from "App/shared/filterOrSearch";
import Container from "@material-ui/core/Container";

import ReferenceArrayField from "components/ReferenceArrayField";

const Filter = ({ records, selected, label, field, ...props }) =>
  records && records.length ? (
    <Filters
      label={label}
      field={field}
      tags={records}
      tag={selected}
      allowBlank={true}
      {...props}
    />
  ) : null;

Filter.propTypes = {
  label: PropTypes.string,
  field: PropTypes.string,
  records: PropTypes.array,
  selected: PropTypes.any
};

export const ProjectsFilters = (props) => {
  const {
    onSelectRestaurant,
    onSelectEventLocation,
    restaurant,
    eventLocation
  } = props;

  return (
    <Container className="nav nav-filters">
      <ReferenceArrayField
        reference="professional"
        sortBy="business_name"
        filters={{ active: true, scheduled: true }}
      >
        <Filter
          label="Restaurant"
          field="business_name"
          selected={{ slug: restaurant }}
          keyField="slug"
          onSelect={onSelectRestaurant}
        />
      </ReferenceArrayField>

      <ReferenceArrayField reference="event_locations" sortBy="name">
        <Filter
          label="Event Location"
          field="name"
          keyField="slug"
          selected={{ slug: eventLocation }}
          onSelect={onSelectEventLocation}
        />
      </ReferenceArrayField>
    </Container>
  );
};

ProjectsFilters.propTypes = {
  restaurant: PropTypes.string,
  eventLocation: PropTypes.string,
  onSelectEventLocation: PropTypes.func,
  onSelectRestaurant: PropTypes.func
};

export default ProjectsFilters;
