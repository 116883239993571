import { domMemoize } from "helpers/helper";

// Execution queue for teh codez
// e.g. IF FB is loaded, run callback. IF not, add to queue. Queue Draining can happen on Library load
const getStorage = (name) => domMemoize(name)(() => []);

const Queue = ({ name, loaded }) => {
  const queueRunner = {
    loaded,
    name: () => name + "Queue",
    run: (callback) => (loaded() ? callback() : queueRunner.queue(callback)),
    storage: () => getStorage(name),

    // queue operations
    queue: (callback) => queueRunner.storage().unshift(callback),
    dequeue: () => queueRunner.storage().pop(),
    drain: (callback = queueRunner.dequeue()) => {
      if (callback) {
        callback();
        return queueRunner.drain(queueRunner.dequeue());
      } else {
        return false;
      }
    },
    reset: () => (queueRunner.storage().length = 0)
  };

  return queueRunner;
};

export const runWhen =
  ({ duration, when }) =>
  (callback) => {
    let intervalId = setInterval(() => {
      if (when()) {
        clearInterval(intervalId);
        return callback();
      }
    }, duration || 100);
  };

export default Queue;
