import React from "react";
import { withRouter } from "react-router-dom";
import connectComponent from "redux/connectComponent";
class Tracker extends React.Component {
  newPath = (prev, next) => prev.location.pathname !== next.location.pathname;

  newPush = (history) => history.action === "PUSH";

  shouldTrack = (prevProps, currentProps) =>
    this.newPath(prevProps, currentProps) && this.newPush(currentProps.history);

  track = (props) => props.actions.trackPage(props.pathname);

  componentDidMount() {
    // track the initial page!
    this.track(this.props);
  }

  componentDidUpdate(prevProps) {
    // eslint-disable-line
    if (this.shouldTrack(prevProps, this.props)) {
      this.track(this.props);
    }
  }

  render() {
    // eslint-disable-line
    return null;
  }
}

export default connectComponent(withRouter(Tracker), []);
