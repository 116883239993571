const initialState = () => ({
  projects: []
});

export default (state = initialState(), action) => {
  switch (action.type) {
    case "LOAD_RELEVANT_PROJECTS":
      return {
        ...state,
        projects: action.projects
      };
    case "RESET":
      return initialState();
    default:
      return state;
  }
};
