import React from "react";
import PropTypes from "prop-types"; // eslint-disable-line
import AuthRouter from "./index";
import { checkedAuthentication } from "helpers/db";
import connectComponent from "redux/connectComponent";

const loggedIn = (authProps) => !!authProps.authentication.userId;

const checkedAuth = (authProps) => checkedAuthentication(authProps || {});

const PrivateRoute = (props) => (
  <AuthRouter
    {...props}
    ready={checkedAuth}
    failureUrl="/session"
    successCheck={loggedIn}
    shouldReturn={true}
  />
);

PrivateRoute.propTypes = {};

export default connectComponent(PrivateRoute, ["authentication"]);
