import React from "react";
import { Route } from "react-router-dom";

import AddHome from "./addHome";
import Confirmation from "./confirmation";
import Show from "./show";
import Thanks from "./thanks";

import { killWrap, addWrap } from "helpers/helper";
import * as Sentry from "@sentry/react";

const SentryRoute = Sentry.withSentryRouting(Route);

class Registration extends React.PureComponent {
  componentDidMount() {
    // eslint-disable-line
    killWrap();
  }

  componentWillUnmount() {
    // eslint-disable-line
    addWrap();
  }

  render() {
    // eslint-disable-line
    return (
      <React.Fragment>
        <SentryRoute path="/registration" exact={true} component={Show} />
        <SentryRoute
          path="/registration/add_home"
          exact={true}
          component={AddHome}
        />
        <SentryRoute
          path="/registration/confirmation"
          exact={true}
          component={Confirmation}
        />
        <SentryRoute path="/registration/thanks" component={Thanks} />
      </React.Fragment>
    );
  }
}

export default Registration;
