import React, { useState, useEffect } from "react";
import connectComponent from "../redux/connectComponent";
import { Redirect } from "react-router-dom";

import {
  useCart,
  actionTypes as cartActionTypes
} from "components/CartProvider";

// Logout
const Reset: React.FC<{ actions?: any }> = ({ actions }) => {
  const cart = useCart();
  const [goHome, setGoHome] = useState(false);

  useEffect(() => {
    actions?.resetSession();

    cart.dispatch({
      type: cartActionTypes.RESET
    });

    setGoHome(true);
  }, []);

  return goHome ? <Redirect to="/" /> : null;
};

export default connectComponent(Reset, []);
