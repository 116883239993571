export interface FormValues {
  notes: string;
  quantity: number;
  options?: Record<string | number, string>;
}

export type updateFormType = (
  newFormValues: Partial<FormValues>,
  isLastStep?: boolean
) => void;

// react-hook-form doesn't like numbers as keys in their objects so here we are
export const OPTION_FORM_PREFIX = "option-";
