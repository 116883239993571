import * as Facebook from "../../lib/facebook";

export const projectInviteUrl = (inviterId, projectId, campaign = "invite") =>
  `${window.location.protocol}//${window.location.hostname}/projects/${projectId}?` +
  `utm_source=dwellsocial` +
  `&utm_campaign=${campaign}` +
  `&utm_medium=facebook` +
  `&aid=${inviterId}` +
  `&utm_group=${projectId}` +
  `&rid=${inviterId}`;

export const individualInviteUrl = (inviterId, path = "/") =>
  `${window.location.protocol}//${window.location.hostname}${path}` +
  "?utm_source=dwellsocial" +
  "&utm_campaign=invite&utm_medium=facebook" +
  `&aid=${inviterId}` +
  `&rid=${inviterId}`;

// In case we want all of the facebook invite parameters, but on an arbitrary path
export const parameterizedInviteUrl = (inviterId, path) =>
  individualInviteUrl(inviterId, path);

export const inviteUrl = (inviterId, projectId, campaign = "invite") =>
  projectId
    ? projectInviteUrl(inviterId, projectId, campaign)
    : individualInviteUrl(inviterId);

export const shareToFacebook = (url) =>
  new Promise((resolve) => {
    // eslint-disable-line no-unused-vars
    resolve(
      Facebook.uiCall({
        method: "share",
        hashtag: "#dwellsocial",
        href: url
      })
    );
  });
