import React from "react";
import Container from "components/container";
import SectionHeading from "components/sectionHeading";
const Support = () => (
  <Container className="mt-4">
    <SectionHeading>Frequently Asked Questions</SectionHeading>
    <br />
    <div className="text-center col-md-6 mx-auto">
      <ul>
        <li style={{ textAlign: "left" }}>
          <strong>Who can I contact for help with an order?</strong>
          <p>
            Email us at{" "}
            <a href="mailto:support@dwellsocial.zendesk.com">
              support@dwellsocial.com
            </a>{" "}
            with any questions that you may have.
          </p>
        </li>
        <li style={{ textAlign: "left" }}>
          <strong>When do I receive pickup instructions?</strong>
          <p>
            We will send you reminders the day before and day of pickup with a
            specific location and time, as details are finalized.
          </p>
        </li>
        <li style={{ textAlign: "left" }}>
          <strong>When do I receive pickup instructions?</strong>
          <p>
            We will send you reminders the day before and day of pickup with a
            specific location and time, as details are finalized.
          </p>
        </li>
      </ul>
    </div>
  </Container>
);

export default Support;
