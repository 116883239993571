import React from "react";
import PropTypes from "prop-types";

const Container = ({ children, className, ...props }) => (
  <div
    className={["container", className].filter((i) => i).join(" ")}
    {...props}
  >
    {children}
  </div>
);

Container.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string
};

export default Container;
