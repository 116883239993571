import React from "react";
import PropTypes from "prop-types";
import * as PR from "./privateRoute";
import * as AR from "./anonymousRoute";

import { Route, Redirect } from "react-router-dom";

import * as Sentry from "@sentry/react";

const SentryRoute = Sentry.withSentryRouting(Route);

export const PrivateRoute = PR;
export const AnonymousRoute = AR;

const DEBUG = false;

const log = (...msg) => DEBUG && console.log("Routing:", ...msg); // eslint-disable-line no-console

const deny = (compProps, routeProps) => {
  log("Denying route", routeProps.location.pathname);

  if (compProps.shouldReturn) {
    log("Denying route return");
    // compProps.actions.stashReturnTo(routeProps.location.pathname)
  }

  // console.log("Redirecting to failure Url", compProps.failureUrl)
  return <Redirect to={compProps.failureUrl} />;
};

const successCheck = (contentProps, routeProps) =>
  contentProps.successCheck(contentProps, routeProps);

const readyCheck = (contentProps, routeProps) =>
  contentProps.ready ? contentProps.ready(contentProps, routeProps) : true;

const renderComponent = (Component, contentProps) => (routeProps) => {
  // eslint-disable-line react/display-name

  if (!readyCheck(contentProps, routeProps)) {
    // console.log("Failed Ready Check")
    return "";
  }

  log("Rendering Component", Component, contentProps, routeProps);
  return successCheck(contentProps, routeProps) ? (
    // <Component {...contentProps} {...routeProps} /> :
    <Component {...routeProps} />
  ) : (
    deny(contentProps, routeProps)
  );
};

const renderRenderer = (renderFunc, contentProps) => (routeProps) => {
  // eslint-disable-line react/display-name

  if (!readyCheck(contentProps, routeProps)) {
    // console.log("Failed Functional Ready Check")
    return ""; // () => ""
  }

  return successCheck(contentProps, routeProps)
    ? renderFunc(contentProps)
    : deny(contentProps, routeProps);
};

const AuthRouter = ({ component: Component, ...rest }) => {
  // Preserve custom renderer, because this wrapper would be stomped.
  const { render, ...theRest } = rest;

  log("Auth Router", Component);

  return Component ? (
    <SentryRoute {...rest} render={renderComponent(Component, theRest)} />
  ) : (
    <SentryRoute {...rest} render={renderRenderer(render, theRest)} />
  );
};

AuthRouter.propTypes = {
  component: PropTypes.any,
  ready: PropTypes.func,
  failureUrl: PropTypes.string.isRequired,
  successCheck: PropTypes.func.isRequired,
  shouldReturn: PropTypes.bool
};

export default AuthRouter; //#connectComponent(AuthRouter, ['authentication'])
