import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import Errors from "components/errors";

class PasswordField extends PureComponent {
  static propTypes = {
    inputRef: PropTypes.any,
    inputProps: PropTypes.object,
    errors: PropTypes.array,
    label: PropTypes.string
  };

  state = {
    plainText: false
  };

  togglePassword = (event) => {
    event.preventDefault();

    this.setState({ plainText: !this.state.plainText });
  };

  render() {
    // TODO: go back to bootstrap form styles
    return (
      <div className="input-form">
        <label htmlFor="password">{this.props.label || "Password"}</label>
        <div className="input-group-attached">
          <input
            type={this.state.plainText ? "text" : "password"}
            name="Password"
            required="required"
            id="password"
            pattern=".{6,}"
            title="Minimum of 6 characters"
            ref={this.props.inputRef}
            {...(this.props.inputProps || {})}
          />

          <button
            type="button"
            className="password-toggler"
            onClick={this.togglePassword}
          >
            <i
              className={
                this.state.plainText ? "fas fa-eye-slash" : "far fa-eye"
              }
            ></i>
          </button>
        </div>
        <Errors errors={this.props.errors} />
      </div>
    );
  }
}
export default PasswordField;
