import React, { useState } from "react";
import tw from "twin.macro";
import styled, { css } from "styled-components";

import {
  ListboxProps,
  ListboxInput,
  ListboxButton,
  ListboxPopover,
  ListboxList,
  ListboxOption
} from "@reach/listbox";
import "@reach/listbox/styles.css";

import { P } from "components/typography";

export interface DropdownProps extends ListboxProps {
  error?: string | boolean;
}

export const Dropdown = React.forwardRef<HTMLInputElement, DropdownProps>(
  ({ error, children, ...props }, ref) => {
    const [focusElementRef, setFocusElementRef] = useState<
      HTMLDivElement | undefined
    >();

    const setInputRef = (element: HTMLDivElement) => {
      setFocusElementRef(element);
    };

    const focusChildInput = () => {
      (focusElementRef?.firstElementChild as HTMLElement | undefined)?.focus();
    };
    if (error) {
      focusChildInput();
    }

    return (
      <ListboxInput tw="relative" defaultValue="popeyes" {...props} ref={ref}>
        <div ref={setInputRef}>
          <StyledListboxButton
            hasError={!!error}
            arrow={<i className="fas fa-sort-down" tw="text-xl text-muted" />}
            // ref={focusElementRef}
          />
        </div>
        <ListboxPopover
          portal={false}
          tw="rounded border-muted absolute top-0 mt-2 p-4 font-montserrat z-10 focus-within:(outline-none shadow-none)"
        >
          <ListboxList>{children}</ListboxList>
        </ListboxPopover>
        {!!error && <P.Small tw="text-danger">{error}</P.Small>}
      </ListboxInput>
    );
  }
);

interface StyledListboxButtonProps {
  hasError?: boolean;
}
const StyledListboxButton = styled(({ hasError, ...props }) => (
  <ListboxButton {...props} />
))<StyledListboxButtonProps>(({ hasError }) => [
  css`
    &&& {
      /* this is to beat reach ui's stylesheets on specificity */
      ${tw`w-full rounded py-2 px-4 font-montserrat border-muted cursor-pointer`};
    }
  `,
  hasError &&
    css`
      &&& {
        /* this is to beat reach ui's stylesheets on specificity */
        ${tw`border-danger`};
      }
    `
]);

export const Option = styled(ListboxOption)(tw`
  p-2 rounded
  [&[data-current-nav]]:(bg-secondary cursor-pointer text-text)
`);
