import { find } from "./base";
import { user as getUser } from "./users";
import { formalizedPhone } from "helpers/helper";
import { getLeadTracking } from "lib/leadTracking";

const AUTH = ["authentication"];

/**
 * Helpers
 */
const has =
  (u) =>
  (...fields) =>
    u && fields.every((field) => !!u[field]);

/**
 * Session
 */
export const authenticated = (state) => () => !!find(state)(AUTH, "userId");

export const userId = (state) => () => find(state)(AUTH, "userId");

export const stripeId = (state) => () => find(state)(AUTH, "stripe_id");

const _user = (state) => getUser(state)(userId(state)());

export const user = (state) => () => _user(state);
export const currentZip = (state) => () => find(state)(AUTH, "zip");

/**
 * Profile completion
 */

export const leadTracking = (state) => () => getLeadTracking(state);

export const hasFullAddress = (state) => () =>
  has(_user(state))("street", "postal_code");

export const hasPhone = (state) => () => has(_user(state))("phone");

export const getPhone = (state) => () => formalizedPhone(_user(state)?.phone);

export const complete =
  (state) =>
  (full = true) =>
    has(_user(state))(
      ...(full ? ["street", "phone", "postal_code"] : ["postal_code"])
    );

/**
 * General Application State
 */
export const tab = (state) => () => find(state)(AUTH, "tab");

export const version = (state) => () => find(state)(AUTH, "version");
