import React from "react";
import tw from "twin.macro";
import styled, { css } from "styled-components";
import { ToastContainer, toast } from "react-toastify";

const StyledToastContainer = styled(ToastContainer)(() => [
  css({
    "& .Toastify__toast-body": tw`font-montserrat font-medium text-base`,
    "& .Toastify__toast--success": tw`bg-primary`,
    "& .Toastify__progress-bar": tw`bg-primary-dark`
  })
]);
export const toastContainer = (props) => <StyledToastContainer {...props} />;

const toastColor = {
  white: { type: "default" },
  green: { type: "success" },
  blue: { type: "info" },
  yellow: { type: "warning" },
  red: { type: "error" }
};

const getColor = (color) => toastColor[color] || toastColor["green"];

export const createToast = (text, options = {}) => {
  let defaultOptions = {
    position: "top-center",
    autoClose: 3000
  };

  let customOptions = Object.assign(
    defaultOptions,
    options,
    getColor(options.color)
  );

  // using success call forces all toasts to be green.
  // console.log("Toasting", text, customOptions,  // eslint-disable-line
  toast(text, customOptions);
  // )
};
