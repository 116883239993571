import React from "react";

import { Link, useParams, useRouteMatch, Redirect } from "react-router-dom";
import { useQuery } from "react-query";
import { fetchEvent } from "api/v2/events";

import "twin.macro";
import {
  hoverableUnderline,
  HoverableUnderlineContainer
} from "styles/utilities";
import styled from "styled-components";
import { A, H, P, Button, Label, Alert } from "components/typography";
import { Card } from "components/cards";
import { useAuth } from "components/AuthProvider";

import CartButton from "./header/cartButton";
import Spinner from "components/spinner";
import dayjs from "dayjs";

import Header from "screens/header";
import Footer from "screens/footer";
import Details from "./details";
import Menu from "./menu";
import InviteButton from "components/composites/ShareEvent/inviteButton";
import Browse from "./browse";

import MailingListCta from "components/composites/MailingListCta";

import { toSentence } from "helpers/helper";
import heroImg from "../home/hero.jpeg";
import { Event } from "api/v2/event.types";

const TYPEFORM_URL = "https://form.typeform.com/to/M98OeJca";

const openTypeform = () => {
  window.open(TYPEFORM_URL, "_blank", "noreferrer");
};

const WaitlistButton = () => (
  <Button.Primary tw="w-full lg:w-auto" onClick={openTypeform}>
    Join Waitlist{" "}
  </Button.Primary>
);

const Show: React.FC = () => {
  const auth = useAuth();

  const isReceiptRoute =
    useRouteMatch("/projects/:id/receipt") ||
    useRouteMatch("/events/:id/receipt");

  const { id: eventId } = useParams<{ id: string }>();

  const { data: event, isLoading } = useQuery<Event>(
    ["event", eventId],
    fetchEvent
  );

  if (isLoading) {
    return (
      <div tw="flex w-full justify-center p-6">
        <Spinner disabled={false} />
      </div>
    );
  }

  if (!eventId || !event) return <Redirect to="/404project" />;

  // some comms may still link to /projects/:id/receipt
  // so we should support it as long as possible
  if (event.membership && isReceiptRoute !== null) {
    return <Redirect to={`/orders/${event.membership.id}/receipt`} />;
  }

  const isPublished = ["complete", "closed", "open"].includes(event.status);
  const eventIsActive = isPublished && !event.expired;

  return (
    <>
      <Header sticky>
        {event.membership && event.currentUserHasOrdered ? (
          <Link
            to={`/orders/${event.membership.id.toString()}/receipt`}
            tw="w-full lg:w-auto"
          >
            <Button.Primary tw="w-full lg:w-auto" aria-label="View Order">
              <i className="fas fa-receipt" />
            </Button.Primary>
          </Link>
        ) : (
          eventIsActive && event.joinable && <CartButton eventId={eventId} />
        )}
      </Header>
      <article tw="px-6 lg:px-24 py-12 max-w-screen-xl mx-auto">
        <section tw="mb-6">
          {!isPublished && (
            <Alert.Info tw="mb-6">
              This event is a draft and not ready for orders yet.
            </Alert.Info>
          )}
          {!event.joinable && (
            <Alert.Danger tw="mb-6">
              {event.expired ? (
                <>
                  <span tw="font-bold">This event is expired.</span> This event
                  has passed and is no longer accepting orders.
                </>
              ) : (
                <>
                  <span tw="font-bold">
                    This event is no longer accepting orders.
                  </span>{" "}
                  Looks like you got here a little late.
                </>
              )}{" "}
              {auth.loggedIn ? (
                <Link to="/dashboard">Click here to return to Dashboard.</Link>
              ) : (
                <Link to="/browse">Click here to browse other events.</Link>
              )}
            </Alert.Danger>
          )}
          <Label.Info>{dayjs(event.runAt).format("dddd, MMM D")}</Label.Info>
          <div tw="flex flex-col lg:flex-row justify-between">
            <H.One tw="mt-4">{event.restaurantName}</H.One>
            {isPublished && (
              <div tw="flex flex-col lg:flex-row gap-2 items-center">
                {!event.expired &&
                  (event.joinable ? (
                    <InviteButton shareUrl={event.shareUrl} eventId={eventId}>
                      Invite a Friend
                    </InviteButton>
                  ) : (
                    <WaitlistButton />
                  ))}
                {event.membership && event.currentUserHasOrdered && (
                  <Link
                    to={`/orders/${event.membership.id}/receipt`}
                    tw="w-full lg:w-auto"
                  >
                    <Button.Primary tw="w-full lg:w-auto">
                      View Order
                    </Button.Primary>
                  </Link>
                )}
              </div>
            )}
          </div>
          <div tw="flex flex-col md:flex-row gap-2 mt-6 md:mt-0">
            <span tw="text-muted font-bold text-sm">
              <i className="fas fa-map-marker-alt" tw="mr-1"></i>
              {event.deliveryZone || toSentence(event.locations)}
            </span>
            {event.d2dExclusive ? null : (
              <span tw="text-muted font-bold text-sm">
                <i className="fas fa-car" tw="mr-1"></i>
                {event.pickupStreet}
              </span>
            )}
            <span tw="text-muted text-sm font-thin hidden md:block">•</span>
            <Link
              to={`/restaurants/${event.restaurantSlug}`}
              component={A.Primary}
              tw="text-sm"
            >
              More from {event.restaurantName}
            </Link>
          </div>
        </section>
        <section
          tw="h-80 w-full rounded-lg bg-center bg-cover"
          style={{ backgroundImage: `url(${event.image})` }}
        ></section>
        {event.d2dExclusive ? null : (
          <section tw="flex flex-col gap-2 md:(flex-row gap-4)">
            {event.d2dEnabled && (
              <P.Small tw="font-bold uppercase mt-6">
                <i
                  className="fas fa-shopping-bag"
                  tw=" bg-secondary rounded-full p-2 mr-2"
                />
                Direct to Door Delivery Available
              </P.Small>
            )}
            <P.Small tw="font-bold uppercase mt-6">
              <i
                className="fas fa-hand-holding-heart"
                tw=" bg-secondary rounded-full p-2 mr-2"
              />
              Pickup Available
            </P.Small>
          </section>
        )}
        <Menu
          eventId={eventId}
          showJoinButton={
            eventIsActive &&
            event.joinable &&
            !(event.membership && event.currentUserHasOrdered)
          }
        />
        <Details descriptionHtml={event.descriptionHtml} />

        {!auth.loggedIn && (
          <section tw="flex flex-col lg:flex-row gap-6 py-8">
            <div
              tw="w-full md:w-1/2-gutter flex h-inherit"
              style={{ minHeight: 300 }}
            >
              <a href="/browse" tw="flex h-inherit">
                <CardWithUnderline
                  tw="flex flex-col justify-end pr-1/4 bg-cover bg-bottom"
                  style={{
                    backgroundImage: `
                radial-gradient(circle, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 50%, rgba(0,0,0,1) 100%),
                linear-gradient(to top, rgba(0,0,0,0.9) 0%, rgba(0,0,0,0.2) 80%),
                url('${heroImg}') `
                  }}
                >
                  <P.Large tw="text-white pb-2">
                    Not mouth-watering enough for you? Try browsing other groups
                    in your area.
                  </P.Large>
                  <UnderlinedLinkContent tw="text-white w-max">
                    Find food now{" "}
                    <i className="fas fa-long-arrow-alt-right"></i>
                  </UnderlinedLinkContent>
                </CardWithUnderline>
              </a>
            </div>
            <div tw="flex w-full md:w-1/2-gutter h-inherit">
              <MailingListCta />
            </div>
          </section>
        )}
      </article>
      {auth.loggedIn && (
        <Browse
          postalCodes={event.postalCodes}
          locations={event.locations}
          eventId={eventId}
        />
      )}
      <Footer />
    </>
  );
};

export default Show;

const UnderlinedLinkContent = styled(P.Medium)(() => hoverableUnderline);
const CardWithUnderline = styled(Card.Primary)(() => [
  HoverableUnderlineContainer(UnderlinedLinkContent)
]);
