import React from "react";

import Projects from "screens/browse/projects";
import "twin.macro";
import { H } from "components/typography";
import { useAuth } from "components/AuthProvider";

const PROJECT_COUNT = 4;

const Browse: React.FC<{
  eventId: string;
}> = ({ eventId }) => {
  const auth = useAuth();

  return (
    <section tw="flex flex-col py-10 bg-secondary items-center px-6">
      <i className="fas fa-laugh-beam" tw="text-xl pb-2"></i>
      <H.Three as="h2" tw="pb-4 text-center">
        Browse other restaurants you might enjoy
      </H.Three>
      <section tw="w-full flex flex-col md:flex-row items-center justify-center">
        <Projects
          count={PROJECT_COUNT}
          v2={true}
          postalCode={auth.postalCode}
          excludeIds={[eventId]}
        />
      </section>
    </section>
  );
};

export default Browse;
