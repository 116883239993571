import React from "react";

import Hero from "./hero";
import Partners from "../partners";
import Testimonials from "./testimonials";
import MailingListCta from "components/composites/MailingListCta";
import Footer from "screens/footer";

import headerImg from "./sponsor.png";

import { LeftContent, Content, Column } from "../containers";
import { P, H } from "components/typography";
import "twin.macro";

const Home: React.FC = () => (
  <article>
    <Hero />
    <Partners />

    <section tw="block lg:flex flex-row py-2.5">
      <Column tw="flex justify-end h-inherit">
        <LeftContent tw="text-center lg:text-left">
          <div>
            <i className="fas fa-heart" tw="text-3xl pb-3"></i>
            <H.Two tw="pb-4">Browse + Order</H.Two>
            <P.Medium tw="lg:pb-5">
              We work with the best restaurants in the city to bring you the
              best food—without the traffic or parking. Choose from more than 40
              of the top restaurants in the city.
            </P.Medium>
          </div>
          <div tw="mt-6 lg:mt-0">
            <i className="fas fa-laugh-beam" tw="text-3xl pb-3"></i>
            <H.Two tw="pb-4">Pick up + Enjoy!</H.Two>
            <P.Medium>
              We bring the food to several neighborhoods in Naperville, and also
              offer at-home delivery. Prep and heat your dinner, and enjoy and
              at-home restaurant experience that tastes better than takeout.
            </P.Medium>
          </div>
        </LeftContent>
      </Column>
      <Column tw="block h-inherit">
        <a
          href="https://food.dwellsocial.com/naperville-our-partners/"
          tw="h-full pb-0 lg:pb-16 block"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Content
            tw="p-0 bg-cover bg-center my-8 h-64 lg:h-inherit"
            style={{ backgroundImage: `url('${headerImg}')` }}
          />
        </a>
      </Column>
    </section>

    <section tw="block lg:flex flex-row mb-20">
      <Column tw="flex justify-end h-inherit">
        <Testimonials />
      </Column>
      <Column tw="flex mt-6 lg:mt-0 h-inherit">
        <div tw="w-full lg:w-3/4 flex h-inherit">
          <MailingListCta />
        </div>
      </Column>
    </section>
    <Footer />
  </article>
);

export default Home;
