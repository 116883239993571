import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import connectComponent from "redux/connectComponent";
import history from "redux/history";
import { Redirect } from "react-router-dom";
import { queryParameters } from "helpers/helper";
import BoxForm from "components/box/form";
import PasswordField from "screens/shared/forms/passwordField";

import { createToast } from "screens/shared/toast";

const getToken = (props) =>
  props.match.params.token ||
  queryParameters(history.location).reset_password_token;

const Edit = ({ actions, ...props }) => {
  const [password, setPassword] = useState();
  const [result, setResult] = useState();
  const [errors, setErrors] = useState({});

  const token = getToken(props);

  useEffect(() => {
    token
      ? actions.verifyPasswordToken({ token }).catch(() => setResult("restart"))
      : setResult("notoken");
  }, [token]);

  const onChange = (event) => {
    setPassword(event.target.value);
  };

  const update = (event) => {
    event.preventDefault();

    const payload = {
      token,
      password
    };

    actions
      .updatePassword(payload)
      .then(() =>
        actions
          .refresh()
          .then(() => setResult("done"))
          .catch(() => setResult("done"))
      )
      .catch((err) => {
        props.database.authentication.authenticated()
          ? setResult("noop")
          : setResult("restart");

        return setErrors(err);
      });

    return false;
  };

  switch (result) {
    case "noop":
      createToast(
        "Password not reset. You're already logged in to DwellSocial!"
      );
      return <Redirect to="/dashboard" />;
    case "done":
      createToast("Password successfully reset!");
      return <Redirect to="/dashboard" />;
    case "restart":
      createToast("Your password reset token has expired.");
      return <Redirect to="/password/resend" />;
    case "notoken":
      createToast("Token required for password reset.");
      return <Redirect to="/password/resend" />;
    default:
      break;
  }

  return (
    <BoxForm
      title="Change your password"
      onSubmit={update}
      disabled={!token || !password || password.length < 6}
      submitTitle="Change Password"
    >
      <PasswordField
        errors={errors.reset_password_token || errors.password}
        label="New Password"
        inputProps={{
          autoFocus: true,
          autoComplete: "off",
          value: password,
          onChange: onChange
        }}
      />
    </BoxForm>
  );
};

Edit.propTypes = {
  actions: PropTypes.object,
  database: PropTypes.object,
  match: PropTypes.object
};

export default connectComponent(Edit, ["authentication"]);
