import { objectMap, snakeify } from "helpers/helper";

const defaultSort = () => ({ run_at: "ASC" });

const snakeifyKeys = (obj) =>
  objectMap(obj, (entries) =>
    entries.filter((pair) => pair[1]).map(([k, v]) => [snakeify(k), v])
  );

const applyDateFilters = (obj) => {
  const { starts, ends, ...params } = obj;

  return {
    ...params,
    ...(starts && ends ? { run_at_within: [starts, ends] } : {})
  };
};

const applyFilterPrecedence = (obj) => {
  const {
    professionalId,
    professional_id,
    event_location_id,
    postal_code,
    restaurantId,
    restaurant_id,
    ...params
  } = obj;

  return {
    ...params
  };
};

export const query = (obj) => [
  applyFilterPrecedence(applyDateFilters(snakeifyKeys(obj))),
  defaultSort()
];
