// import PropTypes from 'prop-types'
import { findBy, findAllBy } from "./base";

const LINE_ITEMS = ["line_items", "line_items"];

export const line_item = (state) => (project_membership_id) =>
  findBy(state)(LINE_ITEMS, { project_membership_id });

const sortItems = (items) => [
  ...items.filter((i) => i.product_id).sort((a, b) => a.position - b.position),
  ...items.filter((i) => !i.product_id)
];

export const line_items = (state) => (params) =>
  sortItems(findAllBy(state)(LINE_ITEMS, params));

export const hasCartItems = (state) => (project_membership_id) =>
  project_membership_id &&
  (line_items(state)({ project_membership_id }) || []).filter(
    (line_item) =>
      !["service_fee", "fee", "tax", "tip_percentage", "credit"].includes(
        line_item.sku
      )
  ).length > 0;

export const fee = (state) => (project_membership_id) =>
  line_items(state)({ project_membership_id, sku: "fee" });

export const serviceFee = (state) => (project_membership_id) =>
  line_items(state)({ project_membership_id, sku: "service_fee" });

export const tip = (state) => (project_membership_id) =>
  line_items(state)({ project_membership_id, sku: "tip_percentage" });

export const tax = (state) => (project_membership_id) =>
  line_items(state)({ project_membership_id, sku: "tax" });

export const paidFee = (state) => (project_membership_id) => {
  if (!project_membership_id) return false;

  return (fee(state)(project_membership_id) || []).length > 0;
};

export const paidServiceFee = (state) => (project_membership_id) => {
  if (!project_membership_id) return false;

  return (serviceFee(state)(project_membership_id) || []).length > 0;
};
