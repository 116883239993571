import React, { useState } from "react";
import "twin.macro";

import { useQuery } from "react-query";
import { Event, CheckoutDetails } from "api/v2/event.types";
import { fetchEvent, fetchCheckoutDetails } from "api/v2/events";

import {
  useParams,
  Redirect,
  Link,
  Switch,
  Route,
  useRouteMatch,
  useLocation,
  useHistory
} from "react-router-dom";
import { Button, H, Alert, Breadcrumb } from "components/typography";
import Spinner from "components/spinner";

import { useCart, cart } from "components/CartProvider";
import { useAuth, LeadTracking } from "components/AuthProvider";

import OrderSummary from "./orderSummary";
import OrderForm, { OrderFormValues } from "./orderForm";
import PaymentFormPage from "./paymentFormPage";
import PaymentCreditForm from "./paymentCreditForm";

const checkoutDetailsParams = (
  eventCart?: cart | "",
  gratuity?: string,
  promocode?: string,
  deliveryType?: string,
  leadTracking?: LeadTracking
) =>
  typeof eventCart === "object"
    ? {
        products: eventCart.items.map((item) => ({
          id: item.product.id,
          quantity: item.quantity
        })),
        ...(gratuity !== undefined && gratuity !== "" ? { tip: gratuity } : {}),
        ...(promocode !== undefined && promocode !== ""
          ? { promo_code: promocode }
          : {}),
        delivery_type: deliveryType,
        lead_params: {
          ...leadTracking
        }
      }
    : {};

const Checkout: React.FC = () => {
  const { id: eventId } = useParams<{ id: string }>();
  const { path, url } = useRouteMatch();
  const locationState = useLocation().state as { error: string | undefined };
  const cart = useCart();
  const auth = useAuth();
  const history = useHistory();

  const paymentRouteMatch = useRouteMatch("/events/:id/checkout/payment");
  const [gratuity, setGratuity] = useState<string>();
  const [orderFormValues, setOrderFormValues] = useState<OrderFormValues>();
  const [promocode, setPromocode] = useState<string>();
  const [deliveryType, setdeliveryType] = useState<string>();
  const { data: event, isLoading } = useQuery<Event>(
    ["event", eventId],
    fetchEvent
  );

  const {
    isLoading: checkoutDetailsIsLoading,
    data: checkoutDetails,
    isRefetching: checkoutDetailsIsRefetching
  } = useQuery<CheckoutDetails>(
    ["checkoutDetails", eventId, gratuity, promocode, deliveryType],
    fetchCheckoutDetails(
      eventId as string,
      checkoutDetailsParams(
        eventId && cart.state[eventId],
        gratuity,
        promocode,
        deliveryType,
        auth.leadTracking || {}
      )
    ),
    { keepPreviousData: true }
  );

  if (!auth.loggedIn)
    return (
      <Redirect
        to={{
          pathname: "/session",
          state: { checkout: true, referrer: url }
        }}
      />
    );

  if (isLoading || !checkoutDetails) {
    return (
      <div tw="flex w-full justify-center p-6">
        <Spinner disabled={false} />
      </div>
    );
  }
  if (!eventId || !event) return <Redirect to="/404project" />;
  if (!cart.state[eventId]) return <Redirect to={`/events/${eventId}`} />;

  const numericTotal =
    typeof checkoutDetails.total === "string"
      ? parseInt(checkoutDetails.total, 10)
      : checkoutDetails.total;

  return (
    <div tw="flex flex-col lg:flex-row">
      <article tw="w-full lg:w-4/5 md:min-h-screen px-6 lg:px-24 pt-10">
        <Link
          to={`/events/${eventId}`}
          component={(props) => <Button.Tertiary as="a" {...props} />}
        >
          <i className="fas fa-long-arrow-alt-left" /> Back to menu
        </Link>
        <Breadcrumb.Nav tw="py-4">
          {orderFormValues && paymentRouteMatch ? (
            <>
              <Breadcrumb.Link
                href={url}
                onClick={(e) => {
                  e.preventDefault();
                  history.push(url);
                }}
              >
                Order Details
              </Breadcrumb.Link>
              <Breadcrumb.Separator />
              <Breadcrumb.Location>Payment Details</Breadcrumb.Location>
            </>
          ) : (
            <Breadcrumb.Location>Order Details</Breadcrumb.Location>
          )}
        </Breadcrumb.Nav>
        <H.Two
          as="h1"
          tw="pb-4 mb-0 border-0 border-solid border-secondary border-b"
        >
          Complete your order
        </H.Two>
        {locationState?.error && (
          <Alert.Danger>
            <strong>Error: </strong>
            {locationState?.error}
          </Alert.Danger>
        )}
        <Switch>
          {orderFormValues && (
            <Route path={`${path}/payment`}>
              {numericTotal === 0 ? (
                <PaymentCreditForm
                  eventId={eventId}
                  orderFormValues={orderFormValues}
                  event={event}
                  d2d={checkoutDetails.d2d}
                  promocode={promocode}
                />
              ) : (
                <PaymentFormPage
                  amount={checkoutDetails.total}
                  eventId={eventId}
                  orderFormValues={orderFormValues}
                  event={event}
                  d2d={checkoutDetails.d2d}
                  promocode={promocode}
                />
              )}
            </Route>
          )}
          <Route path={path}>
            <OrderForm
              event={event}
              checkoutDetails={checkoutDetails}
              setGratuity={setGratuity}
              setPromocode={setPromocode}
              setdeliveryType={setdeliveryType}
              setOrderFormValues={setOrderFormValues}
              orderFormValues={orderFormValues}
            />
          </Route>
        </Switch>
      </article>
      <OrderSummary
        eventId={eventId}
        event={event}
        checkoutDetails={checkoutDetails}
        isLoading={checkoutDetailsIsLoading || checkoutDetailsIsRefetching}
      />
    </div>
  );
};

export default Checkout;
