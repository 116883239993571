import React from "react";
import "twin.macro";

import { useAuth } from "components/AuthProvider";
import { Link, useParams, Redirect } from "react-router-dom";
import { useQuery } from "react-query";
import { fetchRestaurant } from "api/v2/restaurants";
import { Restaurant } from "api/v2/restaurant.types";
import BrowseZipForm from "components/composites/BrowseZipForm";
import InviteButton from "components/composites/ShareEvent/inviteButton";
import Projects from "screens/browse/projects";
import EventsMap from "./eventsMap";
import ShowMoreContainer from "components/ShowMoreContainer";
import Spinner from "components/spinner";
import dayjs from "dayjs";

import { P, H, Label, Button } from "components/typography";

const costToDollarSigns = (cost?: number) =>
  cost ? [...Array(cost)].map(() => "$").join("") : undefined;

const Show: React.FC = () => {
  const { id: restaurantId } = useParams<{ id: string }>();
  const auth = useAuth();

  const { data: restaurant, isLoading } = useQuery<Restaurant>(
    ["restaurant", restaurantId],
    fetchRestaurant
  );

  if (isLoading) {
    return (
      <div tw="flex w-full justify-center p-6">
        <Spinner disabled={false} />
      </div>
    );
  }

  if (!restaurantId || !restaurant) return <Redirect to="/404project" />;

  return (
    <article tw="pt-12 w-full">
      <section tw="px-6 lg:px-24 min-w-full lg:min-w-[1280px] max-w-screen-xl mx-auto">
        <section tw="mb-6">
          <div tw="flex flex-col lg:flex-row justify-between items-center">
            <H.One tw="mt-4">{restaurant.name}</H.One>
            <InviteButton shareUrl={restaurant.shareUrl} eventId={""}>
              Invite a Friend
            </InviteButton>
          </div>
          <P.Medium tw="text-muted">
            {[restaurant.cuisine, costToDollarSigns(restaurant.cost)]
              .filter((n) => n)
              .join(" • ")}
          </P.Medium>
        </section>
        <section
          tw="h-80 w-full rounded-lg bg-center bg-cover"
          style={{ backgroundImage: `url(${restaurant.avatar})` }}
        ></section>
        {restaurant.landingPageDescription && (
          <section tw="mt-6">
            <H.Two tw="mb-2">About {restaurant.name}</H.Two>
            <ShowMoreContainer
              showButtonContent="View More"
              hideButtonContent="Hide Details"
              truncatedHeight={70}
            >
              <P.Medium>{restaurant.landingPageDescription}</P.Medium>
            </ShowMoreContainer>
          </section>
        )}
      </section>

      <H.Two tw="my-6 px-6 lg:px-24 min-w-full lg:min-w-[1280px] max-w-screen-xl  mx-auto">
        {restaurant.name}'s upcoming events
      </H.Two>

      {restaurant.openEvents.length < 1 ? (
        <section tw="px-6 lg:px-24 min-w-full lg:min-w-[1280px] max-w-screen-xl mx-auto">
          <H.Three>
            Sorry, looks like there are no events for this restaurant at the
            time.
          </H.Three>
          {auth.loggedIn ? (
            <section tw="mb-6">
              <P.Medium tw="text-muted py-4">
                Browse more Dwelliveries in your community:
              </P.Medium>
              <section tw="w-full flex flex-col md:flex-row items-center justify-center text-left">
                <Projects count={4} v2={true} postalCode={auth.postalCode} />
              </section>
            </section>
          ) : (
            <section tw="flex flex-col items-center">
              <P.Medium tw="text-muted py-4">
                Enter your zipcode to find food coming to your community:
              </P.Medium>
              <BrowseZipForm tw="w-80" />
            </section>
          )}
        </section>
      ) : (
        <section tw="flex flex-col flex-col-reverse lg:(grid grid-cols-2) bg-secondary">
          <ul tw="list-none flex flex-col gap-6 pl-0 w-full pl-6 lg:pl-24 my-12 min-w-full lg:min-w-[640px] max-w-screen-sm  lg:max-h-[750px] overflow-y-scroll justify-self-end">
            {restaurant.openEvents.map((openEvent) =>
              openEvent.locations.map((loc) => (
                <Link
                  to={`/events/${openEvent.id}`}
                  key={`${openEvent.id}_${loc}`}
                >
                  <li key={`${openEvent.id}_${loc}`} tw="mr-12">
                    <Button.Card tw="flex flex-row p-6 justify-between">
                      <div tw="flex flex-row gap-3">
                        <Label.Info tw="flex flex-col items-center">
                          <span tw="text-2xs">
                            {dayjs(openEvent.runAt).format("dddd")}
                          </span>
                          <span>{dayjs(openEvent.runAt).format("MMM D")}</span>
                        </Label.Info>
                        <H.Three tw="mt-3 mb-0 text-text">{loc}</H.Three>
                      </div>
                      {openEvent.d2dEnabled && (
                        <i
                          className="fas fa-shopping-bag"
                          tw=" bg-secondary rounded-full p-2 mr-2"
                        />
                      )}
                    </Button.Card>
                  </li>
                </Link>
              ))
            )}
          </ul>
          <EventsMap events={restaurant.openEvents} />
        </section>
      )}
    </article>
  );
};

export default Show;
