import tw from "twin.macro";
import styled from "styled-components";

import { P } from "components/typography";

export const FormSection = styled.fieldset(
  tw`
    border-0 border-solid border-secondary border-b 
    px-0 py-6 m-0 
    flex flex-col md:flex-row gap-6`
);

interface Legend {
  hasError?: boolean;
}

export const Legend = styled(P.Medium)<Legend>(({ hasError }) => [
  tw`m-0 p-0 text-base font-bold font-montserrat`,
  hasError && tw`text-danger`
]);

export const Description = styled.div(tw`md:w-1/4`);

export const ResponsiveFloatingSubmit = styled.section(
  tw`
    border-0 border-solid border-secondary border-t 
    w-full bg-white py-6
    fixed bottom-0 left-0 px-6 
    md:(static px-0 border-0)
  `
);
