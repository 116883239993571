import React, { useState, useEffect } from "react";
import { GoogleMap, useJsApiLoader, Circle } from "@react-google-maps/api";
import { theme } from "twin.macro";
import Spinner from "components/spinner";

const MILES_TO_METERS = 1609.34;
const BIRD_FLIES_TO_CAR_DRIVES_RATIO = 0.73;
const DEFAULT_RADIUS = 2.2;
const containerStyle = {
  width: "100%",
  height: "440px"
};

const circleOptions = {
  strokeColor: theme`colors.info`,
  strokeOpacity: 0.0, //0.8,
  strokeWeight: 2,
  fillColor: theme`colors.info`,
  fillOpacity: 0.15,
  clickable: false,
  draggable: false,
  editable: false,
  visible: true,
  zIndex: 1
  // radius: 2.2 * MILES_TO_METERS
};

const renderCircle: React.FC<{
  lat: number;
  lng: number;
  radius: number;
}> = ({ lat, lng, radius }) => (
  <Circle
    key={lat}
    center={{ lat, lng }}
    options={{ ...circleOptions, radius }}
  />
);

const D2dMap: React.FC<{
  radius: number;
  lat: number;
  lng: number;
  coordinates: {
    street: string;
    cityStateZip: string;
    lat: number;
    lng: number;
  }[];
  fromAddress?: string;
  deliveryAddress: string;
  setDeliveryDistance: React.Dispatch<React.SetStateAction<number | undefined>>;
}> = ({
  radius,
  lat,
  lng,
  coordinates,
  // fromAddress,
  deliveryAddress,
  setDeliveryDistance
}) => {
  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY || ""
  });

  const [directionsService, setDirectionsService] = useState<
    undefined | google.maps.DirectionsService
  >();
  const [directionsRenderers, setdirectionsRenderers] = useState<
    google.maps.DirectionsRenderer[]
  >([]);

  const adjustedRadius =
    (radius || DEFAULT_RADIUS) *
    BIRD_FLIES_TO_CAR_DRIVES_RATIO *
    MILES_TO_METERS;

  const appendRoute = (
    ds: any,
    coordinate: { lat: number; lng: number },
    i: number
  ) =>
    ds
      .route({
        // origin: fromAddress || { lat: coordinate.lat, lng: coordinate.lng },
        origin: { lat: coordinate.lat, lng: coordinate.lng },
        destination: deliveryAddress,
        travelMode: google.maps.TravelMode.DRIVING
      })
      .then((result: google.maps.DirectionsResult) => {
        const distance = result.routes[0].legs[0].distance?.value;

        directionsRenderers[i].setDirections(result);

        return distance;
      })
      .catch((e: any) => {
        alert("Could not display directions due to: " + e);
      });

  useEffect(() => {
    if (
      !directionsService ||
      !directionsRenderers.length ||
      !deliveryAddress ||
      deliveryAddress === ", ,  "
    ) {
      return;
    }

    Promise.all(
      coordinates.map((coordinate, i) => {
        return appendRoute(directionsService, coordinate, i);
      })
    ).then((distances) => setDeliveryDistance(Math.min(...distances)));
  }, [directionsService, deliveryAddress]);

  if (loadError) {
    return <div>Map cannot be loaded right now, sorry.</div>;
  }

  if (!isLoaded) {
    return <Spinner />;
  }

  const circles = (
    coordinates && coordinates.length > 0 ? coordinates : [{ lat, lng }]
  ).map((coordinate) => ({
    lat: coordinate.lat,
    lng: coordinate.lng,
    radius: adjustedRadius
  }));

  return (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={{ lat, lng }}
      zoom={12}
      onLoad={(map) => {
        setDirectionsService(new google.maps.DirectionsService());

        setdirectionsRenderers(
          coordinates.map(
            () =>
              new google.maps.DirectionsRenderer({
                draggable: true,
                suppressPolylines: true,
                map
              })
          )
        );

        // new google.maps.Marker({
        //   position: { lat, lng },
        //   map,
        //   title: "Home"
        // });
      }}
    >
      {circles.map(renderCircle)}
    </GoogleMap>
  );
};

export default D2dMap;
