import React from "react";
import "twin.macro";
import { Button } from "components/typography";

import PropTypes from "prop-types";
import connectComponent from "redux/connectComponent";
import { useHistory } from "react-router-dom";

import Input from "components/forms/input";

import { useForm, SubmitHandler } from "react-hook-form";

interface IFormInput {
  zip: string;
}
export const BrowseZipForm: React.FC<{ actions: any; className?: string }> = ({
  actions,
  className
}) => {
  const { handleSubmit, errors, register } = useForm<IFormInput>({});

  const history = useHistory();
  const onSubmit: SubmitHandler<IFormInput> = ({ zip }) => {
    actions.setCurrentZip(zip);
    history.push(`/browse/${zip}`);
  };
  return (
    <form
      tw="flex flex-row items-stretch"
      onSubmit={handleSubmit(onSubmit)}
      className={className}
    >
      <Input
        aria-label="Zip code"
        style={{ width: "99%" }}
        type="text"
        placeholder="Zip code"
        name="zip"
        id="zip"
        data-testid="zip_code"
        ref={register({ required: true })}
        error={errors["zip"] && "Must input a zip code"}
        icon={<i className="fas fa-map-marker-alt" tw="text-muted"></i>}
      />

      <Button.Primary tw="ml-2 w-20" type="submit">
        Go
      </Button.Primary>
    </form>
  );
};

BrowseZipForm.propTypes = {
  actions: PropTypes.object
};

export default connectComponent(BrowseZipForm, ["authentication"]);
