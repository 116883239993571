import React from "react";

const Spinner: React.FC<{ disabled?: boolean; style?: any }> = ({
  disabled = false,
  ...props
}) =>
  !disabled ? (
    <div
      {...props}
      className="spinner-border mx-auto mt-auto"
      data-testid="spinner"
    />
  ) : null;

export default Spinner;
