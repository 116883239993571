import React, { useState, useEffect } from "react";
import "twin.macro";

import { P } from "components/typography";
import { fetchContentBlock } from "api/v2/contentBlocks";

// Default banner
import Giftcard from "./giftcard";

const DynamicBanner: React.FC = () => {
  const [content, setContent] = useState();

  useEffect(() => {
    fetchContentBlock("homepage-banner")
      .then(({ body }) => body)
      .then(setContent)
      .catch(() => console.log("no banner"));
  }, []);

  if (!content) {
    return <Giftcard />;
  } else {
    return (
      <>
        <style>
          {`
          .dynamic-banner {
            text-align: center;
            padding: 0 4 0 4 ;
            padding-bottom: 0;
            margin: 16 0 16 0;
            font-family: montserrat;
          }
          .dynamic-banner p {
            color: white;
            font-weight: bold;
            padding-bottom: 0;
          }

          .dynamic-banner a, .dynamic-banner a:hover {
            color: white;
            font-family: montserrat;
            text-decoration: underline;
            background-color: transparent;
            border: none;
          }
          .dynamic-banner a:hover {
            opacity: 0.6;
          }
        `}
        </style>
        <P.Medium
          className="dynamic-banner"
          tw="font-bold text-white text-center py-4"
          dangerouslySetInnerHTML={{ __html: content }}
        />
      </>
    );
  }
};

export default DynamicBanner;
