import React from "react";
import { Route } from "react-router-dom";
import * as Sentry from "@sentry/react";

const SentryRoute = Sentry.withSentryRouting(Route);

import New from "./new";
import Edit from "./edit";

const Password = () => (
  <React.Fragment>
    <SentryRoute path="/password/new" component={New} />
    <SentryRoute
      path="/password/resend"
      render={(props) => <New {...props} resend={true} />}
    />
    <SentryRoute
      exact={true}
      path="/users/password/edit/:token"
      component={Edit}
    />
    <SentryRoute exact={true} path="/users/password/edit" component={Edit} />
    <SentryRoute exact={true} path="/password/edit/:token" component={Edit} />
    <SentryRoute exact={true} path="/password/edit" component={Edit} />
  </React.Fragment>
);

export default Password;
