import React, { useState, useEffect, useRef } from "react";
import "twin.macro";

import { Button } from "components/typography";

export type ShowMoreContainerProps = {
  showButtonContent?: string;
  hideButtonContent?: string;
  truncatedHeight: number;
  showGradient?: boolean;
};

const getHeight = (
  initialFullHeight: number | undefined,
  isTruncated: boolean,
  truncatedHeight: number
) => {
  if (!initialFullHeight) {
    return "auto";
  }
  if (initialFullHeight <= truncatedHeight) {
    return "auto";
  }
  return isTruncated ? truncatedHeight : initialFullHeight;
};

const ShowMoreContainer: React.FC<ShowMoreContainerProps> = ({
  showButtonContent = "Show more",
  hideButtonContent = "Hide",
  truncatedHeight,
  showGradient = false,
  children
}) => {
  const [isTruncated, setIsTruncated] = useState(false);
  const [initialFullHeight, setInitialFullHeight] = useState<
    number | undefined
  >();
  const textBoxRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    setInitialFullHeight(textBoxRef.current?.getBoundingClientRect().height);
    setIsTruncated(true);
  }, []);

  const calculatedHeight = getHeight(
    initialFullHeight,
    isTruncated,
    truncatedHeight
  );
  return (
    <div tw="relative">
      {showGradient && isTruncated && typeof calculatedHeight === "number" && (
        <div
          style={{
            marginTop: calculatedHeight / 2,
            height: calculatedHeight / 2
          }}
          tw="bg-gradient-to-t from-white w-full absolute"
        />
      )}
      <div
        style={{
          height: calculatedHeight,
          overflow: "hidden"
        }}
        tw="mb-4 motion-reduce:transition-none transition-all duration-500 ease-in-out"
        ref={textBoxRef}
        data-testid="container"
      >
        {children}
      </div>

      {initialFullHeight && initialFullHeight > truncatedHeight && (
        <Button.Secondary
          tw="w-full"
          onClick={() => setIsTruncated((currentValue) => !currentValue)}
        >
          {isTruncated ? showButtonContent : hideButtonContent}
        </Button.Secondary>
      )}
    </div>
  );
};

export default ShowMoreContainer;
