import { combineReducers } from "redux";
import AuthenticationReducer from "./authenticationReducer";
import EventLocationReducer from "./eventLocationReducer";
import ExperimentReducer from "./experimentReducer";
import LineItemsReducer from "./lineItemsReducer";
import ProjectsReducer from "./projectsReducer";
import ProjectMembershipsReducer from "./projectMembershipsReducer";
import ProfessionalsReducer from "./professionalsReducer";
import BackendReducer from "./backendReducer";
import RelevanceReducer from "./relevanceReducer";
import UsersReducer from "./usersReducer";

import storage from "redux-persist/lib/storage"; // defaults to localStorage for web and AsyncStorage for react-native

const appReducer = combineReducers({
  authentication: AuthenticationReducer,
  backend: BackendReducer,
  event_locations: EventLocationReducer,
  experiments: ExperimentReducer,
  line_items: LineItemsReducer,
  projects: ProjectsReducer,
  project_memberships: ProjectMembershipsReducer,
  professionals: ProfessionalsReducer,
  relevance: RelevanceReducer,
  users: UsersReducer
});

const rootReducer = (state, action) => {
  if (action.type === "RESET") {
    storage.removeItem("persist:root");
    state = undefined;
  }

  return appReducer(state, action);
};

export default rootReducer;
