import Api from "@dwellsocial/dwellsocial-api";

// Used by Dashboard
export const loadProjectMembershipsForUserId = (user_id) => (dispatch) =>
  Api.membership
    .index({ filter: JSON.stringify({ user_id }) })
    .then((project_memberships) => {
      dispatch({
        type: "LOAD_PROJECT_MEMBERSHIPS",
        project_memberships
      });

      return project_memberships;
    });
