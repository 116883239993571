import React from "react";
import { Provider } from "react-redux";
import { CookiesProvider } from "react-cookie";
import { PersistGate } from "redux-persist/integration/react";
import { ThemeProvider } from "@material-ui/core/styles";
import theme from "./theme";
import App from "./App";
import configureStore from "./redux/store";

const configuredStore = configureStore();

// expose store when run in Cypress
if (window.Cypress) {
  window.store = configuredStore;
}
const Pure = () => (
  <Provider store={configuredStore.store}>
    <PersistGate loading={null} persistor={configuredStore.persistor}>
      <ThemeProvider theme={theme}>
        <CookiesProvider>
          <App />
        </CookiesProvider>
      </ThemeProvider>
    </PersistGate>
  </Provider>
);

export default Pure;
