import React from "react";
import tw from "twin.macro";
import styled from "styled-components";

import { P, List } from "components/typography";
import { currencyFormatter } from "helpers/formatters";
import ReactTooltip from "react-tooltip";

const NonProductLineItem: React.FC<{
  name: string;
  price: number | string;
  description?: string | null;
  className?: string;
}> = ({ name, price, description, className }) => {
  const parsedPrice = typeof price === "string" ? parseFloat(price) : price;
  return (
    <CartLi tw="border-b-0 pb-0" className={className}>
      <P.Small tw="font-bold capitalize">
        {name}{" "}
        {description && (
          <i
            className="fas fa-question-circle"
            tw="text-sm"
            data-toggle="tooltip"
            data-tip={description}
          />
        )}
      </P.Small>
      <P.Small tw="text-right">{currencyFormatter(parsedPrice)}</P.Small>
      <ReactTooltip place="bottom" effect="solid" tw="w-4/6 md:w-1/6" />
    </CartLi>
  );
};

export default NonProductLineItem;

const CartLi = styled(List.Cart.Li)(
  tw`flex flex-row justify-between items-start`
);
