import React from "react";
import tw from "twin.macro";
import styled from "styled-components";

export const Location = styled.p(
  tw`font-montserrat pb-0 mb-0 text-xs text-muted`
);
export const Link = styled.a(
  tw`font-montserrat text-xs text-muted underline hover:(underline text-primary) bg-transparent border-none`
);
export const Nav = styled.nav(tw`text-muted`);
export const Separator: React.FC = () => <Location tw="mx-1">/</Location>;
