import React from "react";
import "twin.macro";

import { H, P } from "components/typography";
import { useAuth } from "components/AuthProvider";

import BrowseZipForm from "components/composites/BrowseZipForm";
import Projects from "screens/browse/projects";
import Header from "screens/header";
import Footer from "screens/footer";

const FourOhFour: React.FC = () => {
  const auth = useAuth();

  return (
    <>
      <Header />
      <article
        tw="flex flex-col items-center my-auto pt-6 text-center"
        data-testid="project-404"
      >
        <section tw="mx-auto w-96 flex flex-col items-center">
          <i className="far fa-frown-open" tw="text-2xl"></i>
          <H.One>Sorry, looks like this page doesn’t exist.</H.One>
          <P.Large tw="mb-8">
            The event, restaurant, or page you were looking for either doesn’t
            exist or no longer exists. But don’t fret, delicious food is within
            your grasp.
          </P.Large>
          <div tw="text-2xl">
            <i className="fas fa-utensils pr-2"></i>
            <i className="far fa-grin-hearts"></i>
          </div>
        </section>
        {auth.loggedIn ? (
          <section tw="mb-6">
            <P.Medium tw="text-muted py-4">
              Browse more Dwelliveries in your community:
            </P.Medium>
            <section tw="w-full flex flex-col md:flex-row items-center justify-center text-left">
              <Projects count={4} v2={true} postalCode={auth.postalCode} />
            </section>
          </section>
        ) : (
          <section tw="flex flex-col items-center">
            <P.Medium tw="text-muted py-4">
              Enter your zipcode to find food coming to your community:
            </P.Medium>
            <BrowseZipForm tw="w-80" />
          </section>
        )}
      </article>
      <Footer />
    </>
  );
};

export default FourOhFour;
