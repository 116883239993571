import tw from "twin.macro";
import styled from "styled-components";

const Base = styled.span(
  tw`font-bold font-montserrat border-none text-xs rounded py-1.5 px-3`
);

export const Green = styled(Base)(tw`bg-primary text-white`);

export const Info = styled(Base)(tw`bg-info text-white`);
