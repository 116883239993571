import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import connectComponent from "../../redux/connectComponent";
import { Redirect } from "react-router-dom";
import Account from "./account";

export class Registration extends PureComponent {
  static propTypes = {
    actions: PropTypes.object,
    database: PropTypes.object,
    location: PropTypes.object
  };

  state = {
    incomplete: false,
    registered: false
  };

  componentDidMount() {
    if (this.props.database.authentication.authenticated())
      this.setState({ registered: true });
  }

  complete = () => this.setState({ registered: true });

  log = (err) => console.log("Join after Info Err", err);

  onRegisterSuccess = (props) =>
    props.actions.checkAuthentication().then(this.complete).catch(this.log);

  onRegistration = () => this.setState({ registered: true });

  onIncomplete = () => this.setState({ incomplete: true });

  renderHeader = () => (
    <React.Fragment>
      <h4 className="box--headline">The #1 at Home Restaurant Experience</h4>
      <p className="desc--headline">
        Bringing the best restaurants from Chicago to your area.
      </p>
    </React.Fragment>
  );

  render() {
    const postal_code = this.props.database.authentication.user()?.postal_code;

    if (this.state.incomplete && !postal_code) {
      return <Redirect to="/registration/add_home" />;
    }

    if (this.state.incomplete || this.state.registered)
      return (
        <Redirect to={this.props.location.state?.referrer || "/dashboard"} />
      );

    return (
      <Account
        onRegister={this.onRegistration}
        onIncomplete={this.onIncomplete}
        requiresAddress={true}
        renderHeader={this.renderHeader}
        sessionPath="/session"
        referrer={this.props.location.state?.referrer}
      />
    );
  }
}

export default connectComponent(Registration, ["authentication", "users"]);
