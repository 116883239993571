import React from "react";
import tw from "twin.macro";

import { useForm, SubmitHandler } from "react-hook-form";
import { Button } from "components/typography";
import { P, H } from "components/typography";
import Input from "components/forms/input";
import { emailPattern } from "helpers/validation";

import styled from "styled-components";

import * as Card from "components/cards/Card";

import useMailingListSubscriptionApi from "./useMailingListSubscriptionApi";

import FadeIn from "components/FadeIn";

import Spinner from "components/spinner";
interface IFormInput {
  email: string;
  zip: string;
}

const MailingListCta: React.FC = () => {
  const { handleSubmit, errors, register } = useForm<IFormInput>({});

  const { isSubmitting, isSubmittedSuccessfully, hasSubmissionErrors, submit } =
    useMailingListSubscriptionApi();

  const onSubmit: SubmitHandler<IFormInput> = ({ email, zip }) => {
    submit({ email, zip });
  };

  if (isSubmittedSuccessfully) {
    return (
      <FadeIn tw="flex w-full" duration={1000}>
        <Card.Primary tw="flex justify-center items-center">
          <H.Three as="h2" tw="text-center text-white">
            Thanks for subscribing!
          </H.Three>
        </Card.Primary>
      </FadeIn>
    );
  }

  return (
    <Card.Secondary tw="flex flex-col justify-center">
      <H.Three as="h2" tw="mb-2.5">
        Stay up to date with DwellSocial
      </H.Three>
      <P.Medium tw="pb-6">
        Don’t miss out on new restaurants and pickups coming to you soon.
      </P.Medium>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div
          tw="flex gap-2 items-stretch
          flex-col md:flex-row lg:flex-col xl:flex-row"
        >
          <Input
            style={{ width: "99%" }}
            type="text"
            placeholder="Email address"
            name="email"
            ref={register({ required: true, pattern: emailPattern })}
            error={errors["email"] && "Must input a valid email"}
            icon={<i className="fas fa-envelope" tw="text-muted"></i>}
          />

          <Input
            style={{ width: "99%" }}
            type="text"
            placeholder="Zip code"
            name="zip"
            ref={register({ required: true, pattern: /\d{5}/ })}
            error={errors["zip"] && "Must input a valid zip code"}
            icon={<i className="fas fa-map-marker-alt" tw="text-muted"></i>}
          />
        </div>
        <Button.Primary
          tw="w-full mt-2 h-14"
          type="submit"
          disabled={isSubmitting}
        >
          Subscribe{" "}
          <Spinner
            style={{ width: "1rem", height: "1rem" }}
            disabled={!isSubmitting}
          />
        </Button.Primary>
        {hasSubmissionErrors && (
          <ErrorMessage>
            Something went wrong with subscribing. Please try again
          </ErrorMessage>
        )}
      </form>
    </Card.Secondary>
  );
};
export default MailingListCta;

const ErrorMessage = styled(P.Small)(tw`text-danger pb-0`);
