import React, { Component } from "react";

import PropTypes from "prop-types";
import Log, { TapLog } from "../../lib/log";
import { Link } from "react-router-dom";
import connectComponent from "../../redux/connectComponent";
import Errors, {
  setErrors,
  errorContains,
  errorsContain
} from "components/errors";
import PasswordField from "screens/shared/forms/passwordField";
import withWrap from "components/withWrap";
import infoSVG from "@dwellsocial/dskit/dist/img/icons/info.svg";
import ReactModal from "react-modal";
import styles from "./styles";
import { PhoneField } from "../shared/forms/phoneField";
import ReactTooltip from "react-tooltip";

const withEvent = (event) => (callback) =>
  [event.preventDefault(), callback(event), false].pop();

export class Account extends Component {
  static propTypes = {
    actions: PropTypes.object,
    onRegister: PropTypes.func,
    onIncomplete: PropTypes.func,
    confirmationPath: PropTypes.string,
    registrationPath: PropTypes.string,
    sessionPath: PropTypes.string,
    heading: PropTypes.any,
    renderHeader: PropTypes.func,
    title: PropTypes.string,
    referrer: PropTypes.string
  };

  state = {
    error: null,
    errors: {},
    outOfArea: false,
    disabled: false,
    modal: false,
    contact: {},
    userState: null
  };

  constructor(props) {
    super(props);
    this.emailRef = React.createRef();
    this.passwordRef = React.createRef();
    this.registrationFormRef = React.createRef();
    this.emailSignupRef = React.createRef();
    this.userNameRef = React.createRef();
    this.userEmailRef = React.createRef();
    this.userPasswordRef = React.createRef();
    this.userPostalCodeRef = React.createRef();
    this.phoneRef = React.createRef();
  }

  componentDidMount() {
    this.resetErrors();
  }

  projectId = (props) =>
    props && props.match && props.match.params && props.match.params.id;

  onRegisterHandler = (event) =>
    withEvent(event)(() => this.onRegister(this.formValues()));

  resetErrors = () =>
    this.setState({
      errors: {},
      error: null
    });

  setClientSideError = (field, err) => {
    err &&
      this.setState({
        errors: {
          ...this.state.errors,
          [field]: [err]
        }
      });
  };

  clearClientSideError = (field) => {
    const { [field]: val, ...errs } = this.state.errors; // eslint-disable-line
    this.setState({
      errors: errs
    });
  };

  validateField = (field) => this.validateForm(field);

  validateForm = (field = null) => {
    const values = this.formValues();
    let validated = true;

    if ((!field || field == "name") && values.name.length < 3) {
      this.setClientSideError("name", "Name Required");
      validated = false;
    }

    if (
      (!field || field == "email") &&
      (values.email.length < 3 || !values.email.match(/@/))
    ) {
      this.setClientSideError("email", "Email Required");
      validated = false;
    }

    if ((!field || field == "password") && values.password.length < 6) {
      this.setClientSideError(
        "password",
        "Password required (at least 6 characters)" + values.password
      );
      validated = false;
    }

    if ((!field || field == "phone") && values.phone.length < 6) {
      this.setClientSideError("phone", "Phone required");
      validated = false;
    }

    ["postal_code"].forEach((f) => {
      if (
        (!field || field == f) &&
        this.state.errors[f] &&
        this.state.errors[f].length
      ) {
        this.setClientSideError(field, `${f} required`);
        validated = false;
      }
    });

    return validated;
  };

  register = (user) =>
    this.props.actions
      .registerAccount(user)
      .then(this.onSuccess)
      .catch(this.onFailure);

  exclude = () => () => this.setState({ outOfArea: true });

  onRegister = (userValues) => {
    if (this.state.disabled) return; // console.log("Form already submitting... button disabled")

    this.setState({ disabled: true });

    this.resetErrors();

    if (!this.validateForm()) {
      this.setState({ disabled: false });
      return false;
    }

    return this.register(userValues).catch((err) => {
      this.exclude(this.props)(err);
    });
  };

  onSuccess = (user) => {
    Log("onRegisterSuccess", user);
    return this.props.actions
      .refresh()
      .then(() => this.props.actions.optimizeEvent("sign_up"))
      .then(() => this.setState({ disabled: false }))
      .then(() => this.goToDestination(this.props))
      .catch(TapLog("Error Checking Authentication"));
  };

  onFailure = (errorResponse) => {
    this.setState({ disabled: false });
    return setErrors(this.setState.bind(this))(errorResponse);
  };

  goToDestination = (props) => {
    if (!props.database.authentication.complete(true)) {
      props.onIncomplete();
    } else if (this.props.onRegister) {
      props.onRegister();
    }
  };

  shouldGoToSignIn = (state) => errorsContain(state, /existing/);

  credentialError = (state) => errorContains(state, /credential|No Session/im);

  formValues = () => ({
    name: this.userNameRef.current.value,
    email: this.userEmailRef.current.value,
    password: this.userPasswordRef.current.value,
    postal_code: this.userPostalCodeRef.current?.value,
    phone: this.phoneRef.current && this.phoneRef.current.value,
    ...(Object.keys(this.state.contact).length ? this.state.contact : {})
  });

  registrationPath = (props) => props.registrationPath || "/registration";

  renderNavigationPrompt = (props, state) => {
    if (this.shouldGoToSignIn(state)) {
      return (
        <p className="input-message-warning">
          You already have an account!{" "}
          <Link
            to={{
              pathname: props.sessionPath,
              state: { referrer: this.props.referrer }
            }}
            data-testid="signin-instead"
          >
            Sign In
          </Link>{" "}
          instead.
        </p>
      );
    }

    return "";
  };

  openModal = (event) => {
    event.preventDefault();
    this.setState({ modal: true });
  };

  closeModal = (event) => {
    event.preventDefault();
    this.setState({ modal: false });
  };

  renderRegistration = (props, state) => (
    <React.Fragment>
      <form
        action="/"
        className="box-form"
        onSubmit={this.onRegisterHandler}
        ref={this.registrationFormRef}
      >
        {this.props.renderHeader ? (
          this.props.renderHeader()
        ) : (
          <React.Fragment>
            <h4 className="box--headline pb-5 sm-pad-b-2">Sign up</h4>
          </React.Fragment>
        )}

        <hr className="divider" />

        <p className="box-form--alternative-option mb-3">
          Already a member? &nbsp;
          <Link
            to={{
              pathname: this.props.sessionPath || "/session",
              state: { referrer: this.props.referrer }
            }}
            data-testid="signin"
          >
            Sign in
          </Link>
        </p>

        {this.renderForm(props, state)}

        <ReactModal
          isOpen={this.state.modal}
          style={styles.modal}
          contentLabel={this.props.title}
        >
          <div id="dimming-bg">
            <div className="w-modal why-info m-0">
              <p>
                We require your <strong>phone number</strong> so we can send you
                pickup and delivery details. We also require your{" "}
                <strong>home address</strong> so we can confirm your home
                delivery eligibility.
              </p>
              <button
                className="w-button"
                id="closeModal"
                type="button"
                onClick={this.closeModal}
              >
                Understood
              </button>
            </div>
          </div>
        </ReactModal>

        <React.Fragment>
          <div
            className="why-explanation show-only-desktop"
            data-toggle="tooltip"
            data-placement="left"
            title=""
            data-tip="We require your <b>phone number</b> so we <br/>can send you pickup and delivery details. <br/>We also require your <b>home address</b> so we <br/>can confirm your home delivery eligibility."
          >
            <img src={infoSVG} alt="Info Icon" />
            <p>Why do we need this information?</p>
          </div>
          <ReactTooltip html={true} />

          <div
            className="why-explanation show-only-tablet"
            id="whyModal"
            onClick={this.openModal}
          >
            <img src={infoSVG} alt="Info Icon" />
            <p>Why do we need this information?</p>
          </div>
        </React.Fragment>

        <input
          type="submit"
          className="w-button"
          value={
            this.state.disabled ? "Signing Up..." : "Sign Up and Place an Order"
          }
          data-testid="signup"
        />
      </form>
    </React.Fragment>
  );

  setUserState = (userState) => this.setState({ userState });

  renderForm = (props, state) => (
    <React.Fragment>
      {this.renderNavigationPrompt(props, state)}

      <div className="input-form">
        <label htmlFor="fullname">Full Name</label>
        <input
          type="text"
          autoComplete="name"
          name="Full Name"
          required="required"
          ref={this.userNameRef}
          onFocus={() => this.clearClientSideError("name")}
          onBlur={() => this.validateField("name")}
          id="fullname"
        />
        <Errors errors={this.state.errors.name} />
      </div>

      <div className="input-form">
        <label htmlFor="email">Email</label>
        <input
          type="email"
          name="Email"
          id="email"
          ref={this.userEmailRef}
          required="required"
          autoComplete="email"
          onFocus={() => this.clearClientSideError("email")}
          onBlur={() => this.validateField("email")}
        />
        <Errors errors={this.state.errors.email} />
      </div>

      <PasswordField
        inputRef={this.userPasswordRef}
        errors={this.state.errors.password}
        inputProps={{
          onFocus: () => this.clearClientSideError("password"),
          onBlur: () => this.validateField("password")
        }}
      />

      <div className="input-form">
        <label htmlFor="postal_code">Postal Code</label>

        <input
          type="text"
          className="form-control"
          style={{ minHeight: 56 }}
          id="postal_code"
          ref={this.userPostalCodeRef}
          name="postal_code"
          autoComplete="postal-code"
          onFocus={() => this.clearClientSideError("postal_code")}
          onBlur={() => this.validateField("postal_code")}
          pattern="[0-9]{5}"
          required="required"
        />

        <Errors errors={this.state.errors.postalCode} />
      </div>

      <div className="input-form">
        <label htmlFor="phone">Mobile Number</label>
        <PhoneField
          id="phone"
          title="Mobile Number"
          name="user_info[phone]"
          required="required"
          onFocus={() => this.clearClientSideError("phone")}
          onBlur={() => this.validateField("phone")}
          inputRef={this.phoneRef}
          style={{ minHeight: 56 }}
        />
        <Errors errors={this.state.errors.phone} />
      </div>
    </React.Fragment>
  );

  shouldGoToNoThanks = (state) => state.outOfArea;

  render() {
    if (this.shouldGoToNoThanks(this.state)) {
      return (
        <div className="row justify-content-center mt-5 pt-5">
          <div className="col-lg-8 col-sm-12">
            <div className="spaced">
              <h1>Sorry, we are not in your area... yet!</h1>
              <p>We&#39;ll let you know as soon as we launch in your area.</p>
            </div>
          </div>
        </div>
      );
    }

    return this.renderRegistration(this.props, this.state);
  }
}

export default withWrap(
  connectComponent(Account, ["authentication", "professionals", "users"])
);
