import React, { createContext, useReducer, useEffect } from "react";

import { Product } from "api/v2/menuCategories.types";

export enum actionTypes {
  ADD = "ADD",
  UPDATE = "UPDATE",
  REMOVE = "REMOVE",
  CLEAR = "CLEAR",
  SET_NOTES = "SET_NOTES",
  SET_GRATUITY = "SET_GRATUITY",
  SEE_FLASH_MESSAGE = "SEE_FLASH_MESSAGE",
  RESET = "RESET"
}

export enum flashType {
  success = "SUCCESS",
  info = "INFO"
}
export type FlashMessage = { message: string; type: flashType };

type optionChoice = {
  choiceId: number;
  choiceName: string;
  optionName: string;
};

export type cartItem = {
  notes: string;
  optionChoices?: Record<string | number, optionChoice>;
  product: Product;
  quantity: number;
};

export type cart = {
  items: cartItem[];
  notes: string;
  gratuity?: number;
  gratuityPercentage?: number | "custom";
  flashMessage?: FlashMessage;
};
type CartActions =
  | {
      type: actionTypes.ADD;
      value: { cartId: string | number; item: cartItem };
    }
  | {
      type: actionTypes.UPDATE;
      value: { cartId: string | number; item: cartItem; itemIndex: number };
    }
  | {
      type: actionTypes.REMOVE;
      value: { cartId: string | number; itemIndex: number };
    }
  | {
      type: actionTypes.CLEAR;
      value: { cartId: string | number };
    }
  | {
      type: actionTypes.SET_NOTES;
      value: { cartId: string | number; notes: string };
    }
  | {
      type: actionTypes.SET_GRATUITY;
      value: {
        cartId: string | number;
        gratuity: number;
        gratuityPercentage: cart["gratuityPercentage"];
      };
    }
  | {
      type: actionTypes.SEE_FLASH_MESSAGE;
      value: { cartId: string | number };
    }
  | {
      type: actionTypes.RESET;
    };

type state = Record<string | number, cart>;

const itemWithDefaults = (value: cartItem): cartItem => ({
  ...value,
  optionChoices: value.optionChoices || {}
});

const newCart: cart = { notes: "", items: [], gratuityPercentage: undefined };
const reducer = (state: state, action: CartActions): state => {
  switch (action.type) {
    case actionTypes.ADD: {
      const cart = state[action.value.cartId] || newCart;
      const item = itemWithDefaults(action.value.item);

      return {
        ...state,
        [action.value.cartId]: {
          ...cart,
          items: [...cart.items, item],
          flashMessage: {
            message: `${item.quantity} items added`,
            type: flashType.success
          }
        }
      };
    }
    case actionTypes.UPDATE: {
      const cart = state[action.value.cartId] || newCart;
      const item = itemWithDefaults(action.value.item);

      return {
        ...state,
        [action.value.cartId]: {
          ...cart,
          items: cart.items.map((cartItem, i) =>
            i === action.value.itemIndex ? item : cartItem
          ),
          flashMessage: {
            message: `${item.quantity} items updated`,
            type: flashType.success
          }
        }
      };
    }
    case actionTypes.REMOVE: {
      const cart = state[action.value.cartId] || newCart;

      return {
        ...state,
        [action.value.cartId]: {
          ...cart,
          items: [
            ...cart.items.slice(0, action.value.itemIndex),
            ...cart.items.slice(action.value.itemIndex + 1)
          ],
          flashMessage: {
            message: "Item removed",
            type: flashType.info
          }
        }
      };
    }
    case actionTypes.CLEAR: {
      const { [action.value.cartId]: _toBeDeleted, ...rest } = state;

      return rest;
    }
    case actionTypes.SET_NOTES: {
      const cart = state[action.value.cartId] || newCart;
      return {
        ...state,
        [action.value.cartId]: { ...cart, notes: action.value.notes }
      };
    }
    case actionTypes.SET_GRATUITY: {
      const cart = state[action.value.cartId] || newCart;
      return {
        ...state,
        [action.value.cartId]: {
          ...cart,
          gratuity: action.value.gratuity,
          gratuityPercentage: action.value.gratuityPercentage
        }
      };
    }
    case actionTypes.SEE_FLASH_MESSAGE: {
      const cart = state[action.value.cartId] || newCart;
      return {
        ...state,
        [action.value.cartId]: { ...cart, flashMessage: undefined }
      };
    }
    case actionTypes.RESET: {
      return {};
    }
    default:
      return state;
  }
};

export type cartContext = {
  state: state;
  dispatch: React.Dispatch<CartActions>;
};
const CartContext = createContext<cartContext | undefined>(undefined);

const CartProvider: React.FC = ({ children }) => {
  const initialCart = JSON.parse(localStorage.getItem("persistedCart") || "{}");
  const [state, dispatch] = useReducer(reducer, initialCart);

  useEffect(() => {
    localStorage.setItem("persistedCart", JSON.stringify(state));
  }, [state]);

  return (
    <CartContext.Provider value={{ state, dispatch }}>
      {children}
    </CartContext.Provider>
  );
};

export const useCart = () => {
  const context = React.useContext(CartContext);
  if (context === undefined) {
    throw new Error("useCart must be used within a CartProvider");
  }
  return context;
};

export default CartProvider;
