import React, { useState } from "react";
import "twin.macro";
import dayjs from "dayjs";
import { useHistory } from "react-router-dom";

import {
  FormSection,
  Legend,
  Description,
  ResponsiveFloatingSubmit
} from "./styles";
import Spinner from "components/spinner";
import { Button } from "components/typography";
import ErrorMessage from "components/forms/ErrorMessage";

import { useMutation, useQueryClient } from "react-query";
import { createOrder, deleteOrder } from "api/v2/events";
import { Event } from "api/v2/event.types";

import {
  PaymentElement,
  useStripe,
  useElements
} from "@stripe/react-stripe-js";

import { useAuth } from "components/AuthProvider";
import { useCart } from "components/CartProvider";
import { OrderFormValues } from "./orderForm";

const PaymentForm: React.FC<{
  clientSecret: string;
  orderFormValues: OrderFormValues;
  eventId: string;
  event: Event;
  d2d: boolean;
  promocode?: string;
}> = ({ clientSecret, eventId, orderFormValues, event, d2d, promocode }) => {
  const cart = useCart();
  const auth = useAuth();
  const queryClient = useQueryClient();
  const stripe = useStripe();
  const elements = useElements();
  const history = useHistory();
  const [formIsComplete, setFormIsComplete] = useState(false);

  const [stripeError, setStripeError] = useState<string>();
  const [isLoading, setIsLoading] = useState(false);

  const deleteOrderMutation = useMutation(deleteOrder);

  const createOrderMutation = useMutation(createOrder, {
    onSuccess: async (data) => {
      queryClient.invalidateQueries("event");

      if (!stripe || !elements) {
        setStripeError("Stripe not loaded. This shouldn't happen");
        return;
      }

      const { error } = await stripe.confirmPayment({
        elements,
        confirmParams: {
          return_url: `${location.protocol}//${window.location.hostname}/orders/${data.id}/receipt`
        }
      });
      console.log("Order has been created. Processing Stripe payment.");

      if (error.message) {
        console.log("Stripe processing failed:", error.message);

        deleteOrderMutation.mutate(
          {
            eventId,
            orderId: data.orderId as string
          },
          {
            onSuccess: () => {
              setStripeError(error.message);

              // fetch a new payment intent
              queryClient.invalidateQueries("paymentIntent");

              console.log("Order has been failed accordingly.");
            }
          }
        );
      }

      setIsLoading(false);
    },
    onError: (error) => {
      history.push({
        pathname: `/events/${eventId}/checkout`,
        state: { error }
      });
    }
  });

  if (!stripe || !elements || deleteOrderMutation.isLoading) {
    return (
      <div tw="flex w-full justify-center p-6">
        <Spinner disabled={false} />
      </div>
    );
  }

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();

    setIsLoading(true);

    createOrderMutation.mutate({
      id: eventId,
      clientSecret,
      eventCart: cart.state[eventId],
      ...orderFormValues,
      leadTracking: auth.leadTracking || {},
      promocode: promocode || ""
    });
  };

  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      <FormSection tw="pt-4">
        <Description>
          <Legend hasError={!!stripeError} tw="pt-3">
            Payment
          </Legend>
          <ErrorMessage hasError={!!stripeError}>{stripeError}</ErrorMessage>
        </Description>
        <div tw="flex flex-row gap-2">
          <PaymentElement
            id="payment-element"
            onChange={(e) => setFormIsComplete(e.complete)}
          />
        </div>
      </FormSection>
      <ResponsiveFloatingSubmit>
        <Button.Primary
          tw="w-full"
          type="submit"
          disabled={isLoading || !stripe || !elements || !formIsComplete}
          id="submit"
        >
          {isLoading || !stripe || !elements ? (
            <Spinner disabled={false} />
          ) : (
            `Place ${d2d ? "delivery" : "pickup"} order for ${dayjs(
              event.runAt
            ).format("dddd, MMM D")}`
          )}
        </Button.Primary>
      </ResponsiveFloatingSubmit>
    </form>
  );
};

export default PaymentForm;
