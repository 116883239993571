import { update, remove } from "./dataHelper";
const initialState = () => ({
  queryParameters: {},
  cachedEndpoints: {}
});

export default (state = initialState(), action) => {
  switch (action.type) {
    case "SET_QUERY_PARAMETERS":
      return {
        ...state,
        queryParameters: action.params
      };
    case "SET_CACHED_ENDPOINT":
      return {
        ...state,
        cachedEndpoints: update(
          state.cachedEndpoints || {},
          action.cachedEndpoint
        )
      };
    case "CLEAR_CACHED_ENDPOINT":
      return {
        ...state,
        cachedEndpoints: !action.id.match(/\*/)
          ? remove(state.cachedEndpoints || {}, { id: action.id })
          : Object.entries(state.cachedEndpoints || {}).reduce(
              (ac, entry) =>
                entry[0].match(action.id)
                  ? ac
                  : { ...ac, [entry[0]]: entry[1] },
              {}
            )
      };
    case "RESET_BACKEND":
      return {
        ...state,
        cachedEndpoints: {}
      };
    case "RESET":
      return initialState();
    default:
      return state;
  }
};
