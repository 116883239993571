import React, { useEffect, useState } from "react";
import "twin.macro";
import dayjs from "dayjs";

import { useQuery } from "react-query";
import { fetchMembership } from "api/v2/memberships";
import { Membership } from "api/v2/memberships.types";

import { useParams, useLocation, useHistory, Redirect } from "react-router-dom";
import {
  useCart,
  actionTypes as cartActionTypes
} from "components/CartProvider";
import { optimizeEvent } from "actions/experiments";

import Spinner from "components/spinner";

import Browse from "./browse";

import Header from "screens/header";
import Footer from "screens/footer";
import Body from "./body";

const useURLSearchParams = () => {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
};

export const Receipt: React.FC = () => {
  const cart = useCart();

  const { id: membershipId } = useParams<{
    id: string;
  }>();
  const history = useHistory();
  const urlSearchParams = useURLSearchParams();
  const location = useLocation();
  const locationState = location.state as {
    orderCreated?: boolean;
  };

  const { data: membership, isLoading } = useQuery<Membership>(
    ["membership", membershipId],
    fetchMembership
  );

  const [flashMessage, setFlashMessage] = useState<string>();

  // stripe redirects us to the receipt upon a successful order,
  // so we have to handle post successful order stuff here
  useEffect(() => {
    if (!membership) return;

    cart.dispatch({
      type: cartActionTypes.CLEAR,
      value: { cartId: membership.eventId }
    });
    const payment_intent = urlSearchParams.get("payment_intent");
    const redirect_status = urlSearchParams.get("redirect_status");

    const orderJustCreated =
      locationState?.orderCreated ||
      (payment_intent && redirect_status === "succeeded");

    if (!orderJustCreated) return;

    optimizeEvent("join", {
      action: "join",
      label: "method",
      category: "engagement",
      value: membership.totalPaid
    })();

    setFlashMessage(
      `Your order for ${membership.restaurantName} on ${dayjs(
        membership.eventRunAt
      ).format("MMM D")} was successfully placed.`
    );

    history.replace(location.pathname); //clear the location state so we only trigger these events once
  }, [membership?.eventId]);

  if (isLoading) {
    return (
      <div tw="flex w-full justify-center p-6">
        <Spinner disabled={false} />
      </div>
    );
  }

  if (!membership) return <Redirect to="/404project" />;

  return (
    <>
      <Header />
      <Body flashMessage={flashMessage} membership={membership} />
      <Browse eventId={membership.eventId.toString()} />
      <div tw="pb-32 md:pb-0">
        <Footer />
      </div>
    </>
  );
};

export default Receipt;
