import React from "react";
import Spinner from "components/spinner";

import styled, { css } from "styled-components";
import { P, H } from "components/typography";
import tw from "twin.macro";
import { isString, toSentence } from "helpers/helper";
import { Link } from "react-router-dom";
import {
  hoverableUnderline,
  HoverableUnderlineContainer
} from "styles/utilities";

import dayjs from "dayjs";

export type eventCardProps = {
  record?: {
    id: string;
    business_name: string;
    name: string;
    locations: any;
    run_at: string;
    status: string;
    avatar: string;
    professional_avatar: string;
  };
};

export const EventCard: React.FC<eventCardProps> = ({ record }) => {
  if (!record) return <Spinner disabled={false} />;

  const {
    business_name,
    name,
    run_at,
    avatar,
    locations,
    professional_avatar
  } = record;

  const loc = isString(locations) ? locations : toSentence(locations);

  return (
    <Card bgAvatar={professional_avatar || avatar}>
      <UnderlinedTitle>{business_name || name}</UnderlinedTitle>
      <P.Small tw="text-white">
        {run_at && (
          <span tw="mr-2">
            <i className="fas fa-calendar-day" tw="mr-1"></i>
            {dayjs(run_at).format("ddd, MMM D")}
          </span>
        )}
        {loc && (
          <span>
            <i className="fas fa-map-marker-alt" tw="mr-1"></i>
            {loc}
          </span>
        )}
      </P.Small>
    </Card>
  );
};

const UnderlinedTitle = styled(H.Three)(() => hoverableUnderline);

interface CardProps {
  bgAvatar: string;
}
const Card = styled.section<CardProps>(() => [
  tw`rounded-md w-5/6 md:w-64 h-64 bg-gray-700 p-4 bg-cover bg-center text-white flex flex-col justify-end m-2`,
  css`
    background-image: linear-gradient(
        to top,
        rgba(0, 0, 0, 1),
        rgba(0, 0, 0, 0) 75%
      ),
      url(${(props: CardProps) => props.bgAvatar || "none"});
  `,
  HoverableUnderlineContainer(UnderlinedTitle)
]);

export const LinkedEventCard: React.FC<eventCardProps> = ({ record }) => (
  <Link
    to={`/events/${record?.id}`}
    style={{ display: "contents" }}
    data-testid={`event`}
  >
    <EventCard record={record} />
  </Link>
);

export default EventCard;
