import React from "react";
import "twin.macro";

import { Event } from "api/v2/event.types";
import { Order } from "api/v2/order.types";

import EventHeaderCard from "screens/event/checkout/eventHeaderCard";

import OrderSummaryList from "components/composites/OrderSummary";

const OrderSummary: React.FC<{
  event: Event;
  order?: Order;
  isLoading: boolean;
}> = ({ event, order, isLoading }) => {
  const productLineItems = order?.productLineItems.map((li) => ({
    ...li,
    name: li.productName || ""
  }));
  const nonProductLineItems = order?.nonProductLineItems
    ?.filter(
      (li) => li.sku && !["credit", "promo_code", "d2d"].includes(li.sku)
    )
    .map((li) => ({
      ...li,
      name: li.sku || ""
    }));
  const creditLineItem = order?.nonProductLineItems?.find(
    (li) => li.sku === "credit"
  );
  const promoCodeLineItem = order?.nonProductLineItems?.find(
    (li) => li.sku === "promo_code"
  );
  const d2dLineItem = order?.nonProductLineItems?.find(
    (li) => li.sku === "d2d"
  );

  return (
    <aside tw="w-full md:w-1/5 bg-secondary py-10 px-6">
      <EventHeaderCard event={event} />
      <OrderSummaryList
        isLoading={isLoading}
        productLineItems={productLineItems}
        nonProductLineItems={nonProductLineItems}
        promoCodeLineItem={
          promoCodeLineItem && {
            ...promoCodeLineItem,
            name: "Promo Code"
          }
        }
        creditLineItem={
          creditLineItem && {
            ...creditLineItem,
            name: "Credit"
          }
        }
        d2dLineItem={
          d2dLineItem && {
            ...d2dLineItem,
            name: "Direct to Door Delivery"
          }
        }
        subtotal={order?.baseCost}
        totalWithGratuity={order?.preadjustmentTotal}
        totalDue={order?.total}
      ></OrderSummaryList>
    </aside>
  );
};

export default OrderSummary;
