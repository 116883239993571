// Error helpers that bridge the API responses with state and form
import React from "react";
import PropTypes from "prop-types";
// import Log from 'lib/log'

const extractMessage = (error) =>
  error && (error.message || error.error || error.errors || error);

// errors: {existing_email: ["Email already exists. Sign in or recover your account."]}
//
// Object Prototype
//
// (this should probably moved into the API Client library
export const setErrors = (handler) => (errorPromise) =>
  Promise.resolve(errorPromise)
    .then(extractMessage) //response => (response.errors || response.error || response))
    .then((errors) => {
      handler({ errors });
      return errors;
    });

export const setError = (handler) => (errorPromise) =>
  Promise.resolve(errorPromise)
    .then(extractMessage) //response => (response.error || response)
    .then((error) => {
      handler({ error });
      return error;
    });

const matchFunction = (str) => {
  // console.log("Match function", str, str.match)
  if (!str || !str.match) return () => false;
  return str && str.match && str.match.bind(str);
};

export const errorsContain = (state, matcher) =>
  Object.entries(extractMessage(state)).find((errors) =>
    errors.find((error) => {
      const matchFunc = matchFunction(error);
      // console.log("Saying matchFunc", matcher, error, matchFunc)
      return matchFunc(matcher);
    })
  );

export const errorContains = (state, matcher) => {
  const matcha = matchFunction(state.error || "");

  // console.log("Matcher for error", state.error, "", matcha)

  return matcha && matcha(matcher);
  // (
  // matchFunction((state.error || ""))(matcher))
};
export const Error = (props) =>
  props.error ? <p className="input-message-error">{props.error}</p> : "";

Error.propTypes = {
  error: PropTypes.any
};

const Errors = (props) => {
  if (!props.errors) return "";
  // console.log("Rendering <Errors errors=" + props.errors + " />")
  return (props.errors || []).map((error, i) => (
    <Error key={i} {...{ error }} />
  ));
};

export default Errors;
