import React, { createContext } from "react";

import connectComponent from "redux/connectComponent";

type CurrentUser = {
  id: number;
  created_at: Date;
  updated_at: Date;
  role: string | null;
  image: string | null;
  firstname: string;
  lastname: string;
  location: string | null;
  current_provider: string | null;
  current_uid: string | null;
  friendships_count: string | null;
  uuid: string;
  brid: string;
  node_id: string | null;
  sponsor_id: string | null;
  billing_record_id: string | null;
  city: string | null;
  state: string | null;
  postal_code_id: number;
  street_number: string | null;
  deleted_at: string | null;
  skip_publish: string | null;
  newly_created: string | null;
  user_id: number;
  user_name: string;
  name: string;
  uid: string | null;
  last_sign_in_at: Date;
  confirmed_at: Date;
  domain: string | null;
};

interface User extends CurrentUser {
  email: string;
  phone: string;
  street: string | null;
  lat: string | null;
  lng: string | null;
  street_name: string | null;
  unit_number: string | null;
  stripe_id: string | null;
  salesforce_id: string | null;
  token: string;
  referral_url: string;
  credit_balance: string;
  postal_code: string;
  currentUser: CurrentUser;
  userId: number;
}

export type LeadTracking = {
  utm_campaign: string;
  utm_source: string;
  utm_medium: string;
  utm_content: string;
  utm_group: string;
  aid: string;
  rid: string;
};

export type AuthContextValue = {
  loggedIn: boolean;
  userId?: string;
  postalCode?: string;
  user?: User;
  leadTracking: LeadTracking;
};

export const AuthContext = createContext<AuthContextValue | undefined>(
  undefined
);

export const useAuth = () => {
  const context = React.useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useAuth must be used within a AuthProvider");
  }
  return context;
};

/* Hello. The goal of AuthProvider is to eventually replace our
 * usage of redux to manage auth. As we go through and redesign
 * everthing, there's a lot of dependency on certain auth logic
 * but replacing it enitrely is a big task. so let's do it
 * incrementally:
 * 1. corral all the new auth-related calls here
 * 2. replace redux calls here with hooks
 * 3. ???
 * 4. profit
 */
const AuthProvider: React.FC<{ database: any }> = ({ database, children }) => {
  const value: AuthContextValue = {
    loggedIn: database.authentication.authenticated(),
    userId: database.authentication.userId(),
    postalCode: database.authentication.user()?.postal_code,
    user: database.authentication.user(),
    leadTracking: database.authentication.leadTracking() || {}
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export default connectComponent(AuthProvider, ["authentication", "users"]);

// For storybook. And maybe  tests if you wanted to
export const MockAuthProvider: React.FC<AuthContextValue> = ({
  children,
  ...value
}) => <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
