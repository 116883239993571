import React from "react";
import "twin.macro";

import { P } from "components/typography";
import { currencyFormatter, snakeToWords } from "helpers/formatters";

const NonProductLi: React.FC<{
  name: string | null;
  price: number;
  className?: string;
}> = ({ name, price, className }) => (
  <li
    tw="flex flex-row justify-between items-center py-2"
    className={className}
  >
    <P.Medium tw="font-bold capitalize">
      {name ? snakeToWords(name) : ""}
    </P.Medium>
    <P.Medium>{currencyFormatter(price)}</P.Medium>
  </li>
);

export default NonProductLi;
