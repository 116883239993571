import React, { useState } from "react";
import "twin.macro";

import { FormSection, Legend, Description } from "../styles";
import { Button } from "components/typography";
import RadioButtonInput from "components/forms/RadioButtonInput";
import ErrorMessage from "components/forms/ErrorMessage";

import Input from "components/forms/input";

const Gratuity: React.FC<{
  register: any;
  defaultGratuityPercentage: number;
  gratuityLabel: string;
  enableGratuity: boolean;
  subtotal: number;
  watchedGratuity?: string;
  hasError: boolean;
}> = ({
  register,
  defaultGratuityPercentage,
  gratuityLabel,
  enableGratuity,
  subtotal,
  watchedGratuity,
  hasError
}) => {
  const [showGratuityPercent, setShowGratuityPercent] = useState(true);

  if (!enableGratuity) return null;

  const gratuitySlots = [15, 18, 20, defaultGratuityPercentage]
    .filter((value, index, self) => self.indexOf(value) === index) // unique
    .sort();

  return (
    <FormSection tw="items-center" data-testid="gratuity">
      <Description>
        <Legend hasError={hasError}>{gratuityLabel}</Legend>
        <ErrorMessage hasError={hasError}>
          Please select or input a tip
        </ErrorMessage>
      </Description>
      {showGratuityPercent ? (
        <div tw="flex flex-col md:flex-row gap-2 w-full md:w-3/4">
          <div tw="flex flex-row gap-2" style={{ marginBottom: 2 }}>
            {gratuitySlots.map((slot) => {
              const value = ((slot / 100.0) * subtotal).toString();

              return (
                <RadioButtonInput
                  tw={"bg-info  hover:(bg-info-dark text-white)"}
                  data-testid={"gratuity-label"}
                  name={`gratuity`}
                  id={`gratuity-${slot}`}
                  ref={register({ required: true })}
                  type="radio"
                  value={value}
                  key={slot}
                  isChecked={[value, undefined, ""].includes(watchedGratuity)}
                >
                  {slot}%
                </RadioButtonInput>
              );
            })}
          </div>
          <div>
            <Button.Secondary
              tw="px-6 py-3 w-full md:w-auto"
              onClick={() => setShowGratuityPercent(false)}
            >
              Set Custom Amount
            </Button.Secondary>
          </div>
        </div>
      ) : (
        <div tw="flex flex-col md:flex-row gap-2 w-full md:w-3/4">
          <Input
            icon={<i className="fas fa-dollar-sign" />}
            name={`gratuity`}
            ref={register({ required: true })}
            type="text"
            aria-label="gratuity"
          />
          <Button.Secondary
            tw="px-6"
            onClick={() => setShowGratuityPercent(true)}
          >
            Select Percentage
          </Button.Secondary>
        </div>
      )}
    </FormSection>
  );
};

export default Gratuity;
