import { getOne, getOneBy, getAll, getAllBy } from "../db";

// helpers
export const yieldSelf = (f) => (a) => f(a);

// API
export const find = (state) => (path, id) => getOne(path, id, state);

export const findBy = (state) => (path, params) =>
  getOneBy(path, params, state);

export const findAllBy = (state) => (path, params) =>
  getAllBy(path, params, state);

export const findAll = (state) => (path) => getAll(path, state);

export const positionSorter = (a, b) => a.position - b.position;
