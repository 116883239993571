const DEFAULT_PER_PAGE = 50;

const last = (ar) => ar[ar.length - 1];

const readyForNewChunk = (chunks, size) =>
  !chunks.length || last(chunks).length >= size;

const pushIdToChunk = (size) => (chunks, id) =>
  chunks.concat([
    // The very beginning or ready for a new chunk
    (readyForNewChunk(chunks, size) ? [] : chunks.pop()).concat([id])
  ]);

const chunkIds = (ids, size) => ids.reduce(pushIdToChunk(size), [[]]);

const withPagination =
  (fetcher, options = { perPage: DEFAULT_PER_PAGE }) =>
  (ids) =>
    chunkIds([...new Set(ids)], options.perPage).map(fetcher);

export const idsToQuery = (ids) => ({ filter: JSON.stringify({ id: ids }) });

export default withPagination;
