import React, { DetailedHTMLProps, InputHTMLAttributes } from "react";
import tw from "twin.macro";
import styled from "styled-components";

import { Button } from "components/typography";

export interface RadioButtonInputProps
  extends DetailedHTMLProps<
    InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  isChecked: boolean;
  disabled?: boolean;
}

const RadioButtonInput = React.forwardRef<
  HTMLInputElement,
  RadioButtonInputProps
>(({ id, disabled = false, isChecked, children, className, ...props }, ref) => (
  <div tw="flex-grow">
    {!disabled && (
      <input
        tw="hidden"
        type="radio"
        id={id}
        {...props}
        ref={ref}
        defaultChecked={isChecked}
      />
    )}
    <Radiolabel
      data-testid="label"
      as="label"
      className={className}
      htmlFor={id}
      isChecked={isChecked}
      disabled={disabled}
    >
      {children}
    </Radiolabel>
  </div>
));
export default RadioButtonInput;

// TODO: figure out why react-hook-form doesn't set checked on radio buttons
interface Radiolabel {
  isChecked?: boolean;
  disabled: boolean;
}
const Radiolabel = styled(Button.Primary)<Radiolabel>(
  ({ isChecked, disabled }) => [
    tw`m-0 cursor-pointer opacity-70 peer-checked:opacity-100 py-3 w-full text-center`,
    isChecked && tw`opacity-100`,
    disabled && tw`cursor-not-allowed opacity-50`
  ]
);
