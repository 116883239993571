export const inviteAnalyticsParams = (projectId, userId) =>
  `utm_campaign=invite` +
  `&utm_source=dwellsocial` +
  `&utm_medium=share` +
  `&aid=${userId}` +
  `&rid=${userId}` +
  `&utm_group=${projectId}`;

export const inviteUrl = (projectId, userId) =>
  `${location.protocol}//${window.location.hostname}/projects/${projectId}?` +
  inviteAnalyticsParams(projectId, userId) +
  `&rid=${userId}&aid=${userId}`;
