import api, { extractError } from "api/api";

export const fetchContentData = ({ queryKey }: any) => {
  const [_key, slug] = queryKey;

  return api
    .get(`/api/v2/contents/${slug}`)
    .then((resp) => {
      return resp.data.content.content;
    })
    .catch((e) => {
      throw extractError(e);
    });
};
