import React, { useState } from "react";
import "twin.macro";

import {
  GoogleMap,
  useJsApiLoader,
  Marker,
  InfoWindow
} from "@react-google-maps/api";
import dayjs from "dayjs";

import { RestaurantEvent } from "api/v2/restaurant.types";

import Spinner from "components/spinner";
import { A, H, Label, P } from "components/typography";
import { Link } from "react-router-dom";
import { toSentence } from "helpers/helper";

const containerStyle = {
  width: "100%",
  height: "inherit",
  minHeight: 600
};

interface ParsedEvent extends RestaurantEvent {
  lat: number;
  lng: number;
}

export const parseEvents = (events: RestaurantEvent[]): ParsedEvent[] => {
  return events.reduce((ac, e) => {
    return ac.concat(
      e.coordinates.map(({ lat, lng }) => ({
        ...e,
        lat: parseFloat(lat),
        lng: parseFloat(lng)
      }))
    );
  }, [] as ParsedEvent[]);
};

const EventsMap: React.FC<{ events: RestaurantEvent[] }> = ({ events }) => {
  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY || ""
  });

  const [selectedEvent, setSelectedEvent] = useState<ParsedEvent | undefined>();

  if (loadError) {
    return <div>Map cannot be loaded right now, sorry.</div>;
  }

  if (!isLoaded) {
    return <Spinner />;
  }

  const markerIcon = {
    url: `${window.location.protocol}//${window.location.hostname}/utensils_w_circle.svg`,
    scaledSize: new window.google.maps.Size(35, 35)
  };

  const parsedEvents = parseEvents(events);

  return (
    <GoogleMap
      mapContainerStyle={containerStyle}
      onLoad={(map) => {
        const bounds = new window.google.maps.LatLngBounds();
        parsedEvents.forEach((e) =>
          bounds.extend({
            lat: e.lat,
            lng: e.lng
          })
        );

        map.fitBounds(bounds);
      }}
    >
      {parsedEvents.map((e) => (
        <Marker
          position={{
            lat: e.lat,
            lng: e.lng
          }}
          key={`${e.id}_${e.lat}`}
          onClick={() => setSelectedEvent(e)}
          icon={markerIcon}
        />
      ))}
      {selectedEvent && (
        <InfoWindow
          position={{
            lat: selectedEvent.lat,
            lng: selectedEvent.lng
          }}
          onCloseClick={() => setSelectedEvent(undefined)}
          options={{ pixelOffset: new window.google.maps.Size(0, -25) }}
        >
          <Link to={`/events/${selectedEvent.id}`}>
            <div tw="p-2">
              <Label.Info tw="text-2xs">
                {dayjs(selectedEvent.runAt).format("dddd MMM D")}
              </Label.Info>
              <H.Three tw="mt-3 mb-0 text-text">
                {toSentence(selectedEvent.locations)}
              </H.Three>
              <P.Small tw="text-muted pt-2">
                Offers pickup
                {selectedEvent.d2dEnabled && " and direct to door delivery"}.
              </P.Small>
              <A.OnLight as="span">View event</A.OnLight>
            </div>
          </Link>
        </InfoWindow>
      )}
    </GoogleMap>
  );
};

export default EventsMap;
