import React from "react";
import "twin.macro";

import { P } from "components/typography";

const ErrorMessage: React.FC<{ hasError: boolean }> = ({
  hasError,
  children,
  ...props
}) =>
  hasError ? (
    <P.Small tw="text-danger" {...props}>
      {children}
    </P.Small>
  ) : null;

export default ErrorMessage;
