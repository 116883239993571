import { createTheme } from "@material-ui/core/styles";

const green = "#33aa44"; // eslint-disable-line
const darkGreen = "#2d983d"; // eslint-disable-line
const black = "#3e3f42";
const grey = "#9ea0a5"; // eslint-disable-line
const lightGrey = "#a0a1a6";

const theme = createTheme({
  palette: {
    common: {
      black: black,
      white: "#ffffff"
    },
    background: {
      paper: "#fff",
      default: "#fafafa"
    },
    primary: {
      light: lightGrey,
      main: black,
      dark: black,
      contrastText: lightGrey
    }
  },
  typography: {
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "Lato",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(",")
  }
});
export default theme;
