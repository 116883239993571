import React from "react";

import Container from "components/container";
import SectionHeading from "components/sectionHeading";

const OutOfArea = () => (
  <Container>
    <SectionHeading size="large">
      Sorry, we are not in your area... yet!
    </SectionHeading>

    <Container>
      <p>We&#39;ll let you know as soon as we launch in your area.</p>
    </Container>
  </Container>
);

export default OutOfArea;
