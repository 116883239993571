import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actions from "../actions";
import database from "./database";

// We just map everything to everything. Use inline mapping for components that need special behavior
const mapStateToProps = (fields: any) => (state: any) => {
  const selectedState = fields
    ? fields.reduce(
        (ac: any, field: any) => ({
          ...ac,
          [field]: state[field]
        }),
        {}
      )
    : state;

  const db = database(selectedState);

  return {
    ...selectedState,
    db,
    database: db
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  actions: bindActionCreators(actions, dispatch)
});

const connectComponent = (Component: any, fields: any) =>
  connect<unknown, unknown, typeof Component>(
    mapStateToProps(fields),
    mapDispatchToProps
  )(Component);

export default connectComponent;
