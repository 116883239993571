import Api from "@dwellsocial/dwellsocial-api";
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import connectComponent from "redux/connectComponent";

import ReactAvatar from "react-avatar";

const color = "#EAEDF3";
const fgColor = "#9EA0A5";

// Only used for currently authed user
const UserAvatar = ({ actions, id, name, ...props }) => {
  const [src, setSrc] = useState();
  useEffect(() => {
    if (id) {
      Api.avatar.show(`users_${id}`).then(({ avatar }) => setSrc(avatar));
    }
  }, [id]);

  if (!src) return null;

  const options = {
    className: "avatar",
    color,
    fgColor,
    name,
    src,
    round: true,
    size: props.size == "medium" ? "48" : "36"
  };

  return <ReactAvatar {...options} />;
};

UserAvatar.propTypes = {
  actions: PropTypes.object,
  id: PropTypes.any,
  name: PropTypes.string,
  size: PropTypes.string
};

export default connectComponent(UserAvatar, ["avatars", "users"]);
