import React from "react";
import "twin.macro";

import { P, A } from "components/typography";

// const message = "Give the gift of iconic food this holiday season with a ";
// const linkedMessage = "DwellSocial Gift Card!";
// const messageUrl = "https://dwellsocial.typeform.com/giftcards?";

const message =
  "We've expanded home delivery and eliminated pickups for most events. ";
const linkedMessage = "Learn more here.";
const messageUrl = "https://food.dwellsocial.com/home-delivery-on-dwellsocial/";

const Giftcard: React.FC = () => (
  <P.Medium tw="font-bold text-white text-center py-4">
    <span tw="md:hidden">
      {message} {linkedMessage}
    </span>
    <span tw="hidden md:inline-block">{message}</span>{" "}
    <A.OnDark
      href={messageUrl}
      tw="font-medium"
      target="_blank"
      rel="noopener noreferrer"
    >
      {linkedMessage}
    </A.OnDark>
  </P.Medium>
);

export default Giftcard;
