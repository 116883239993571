import api, { extractError } from "api/api";

export const fetchOrder = ({ queryKey }: any) => {
  const [_key, id] = queryKey;

  return api
    .get(`/api/v2/orders/${id}`)
    .then((resp) => resp.data?.order)
    .catch((e) => {
      throw extractError(e);
    });
};

export const retryOrder = ({
  id,
  clientSecret
}: {
  id: string | number;
  clientSecret?: string;
}) => {
  return api
    .post(`/api/v2/orders/${id}/retry_payment`, {
      data: { transaction_id: clientSecret }
    })
    .then((resp) => resp.data?.order)
    .catch((e) => {
      throw extractError(e);
    });
};

export const fetchFailedOrders = () => {
  return api
    .get(`/api/v2/orders/failed`)
    .then((resp) => resp.data?.orders)
    .catch((e) => {
      throw extractError(e);
    });
};
