// import PropTypes from 'prop-types'
import { findBy, findAllBy } from "./base";

const MEMBERSHIPS = ["project_memberships", "project_memberships"];

export const membership = (state) => (params) =>
  findBy(state)(MEMBERSHIPS, params);

// membership.propTypes = {
//   project_id: PropTypes.any,
//   user_id: PropTypes.any
// }
//
// export membership;

export const memberships = (state) => (params) =>
  findAllBy(state)(MEMBERSHIPS, params);

// memberships.propTypes = {
//   project_id: PropTypes.any,
//   user_id: PropTypes.any,
//   status: PropTypes.string
// }
//
// export memberships

/***
 * by status
 */
const all = (state) => (user_id, status) =>
  memberships(state)({ user_id, status });

export const active = (state) => (user_id) => all(state)(user_id, "active");

export const complete = (state) => (user_id) => all(state)(user_id, "complete");

export const paidFee =
  (state) =>
  ({ user_id, project_id }) => {
    const pm = findBy(state)(MEMBERSHIPS, { user_id, project_id });

    return (pm?.paid_fee || 0.0) > 0.0;
  };
