import history from "redux/history";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

export const initializeMonitoring = () => {
  // eslint-disable-next-line
  if (process.env.REACT_APP_SENTRY_DSN) {
    Sentry.init({
      // eslint-disable-next-line
      dsn: process.env.REACT_APP_SENTRY_DSN,
      // This sets the sample rate to be 10%. You may want this to be 100% while
      // in development and sample at a lower rate in production
      replaysSessionSampleRate: 0.1,
      // If the entire session is not sampled, use the below sample rate to sample
      // sessions when an error occurs.
      replaysOnErrorSampleRate: 1.0,

      integrations: [
        new Integrations.BrowserTracing({
          routingInstrumentation: Sentry.reactRouterV5Instrumentation(history)
        }),
        new Sentry.Replay()
      ],

      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: 1.0
    });
  }
};
