import React from "react";
import PropTypes from "prop-types";

const SectionHeading = (props) => (
  <section className="heading" {...props}>
    <div className="content">
      {props.size == "large" ? (
        <h2>{props.children}</h2>
      ) : (
        <h5>{props.children}</h5>
      )}
    </div>
  </section>
);

SectionHeading.propTypes = {
  children: PropTypes.any,
  size: PropTypes.string
};

export default SectionHeading;
