import React from "react";
import "twin.macro";
import { H } from "components/typography";
import { Product } from "api/v2/menuCategories.types";

import ProductAvatar from "./productAvatar";

import { Dialog } from "@reach/dialog";
import "@reach/dialog/styles.css";

const NonAddablePage: React.FC<{
  product: Product;
  isOpen: boolean;
  onDismiss: () => void;
}> = ({ product, isOpen, onDismiss }) => (
  <Dialog
    isOpen={isOpen}
    onDismiss={onDismiss}
    tw="p-0 w-11/12 md:w-1/3 rounded mt-36"
    aria-labelledby="menu-item-title"
  >
    <article>
      {product.avatarMedium ? (
        <ProductAvatar avatar={product.avatarMedium} onDismiss={onDismiss} />
      ) : (
        <div tw="text-right pr-6 pt-4">
          <button
            className="close-button"
            onClick={onDismiss}
            tw="bg-transparent font-bold text-lg border-none"
            aria-label="close"
          >
            <i className="fas fa-times" tw="text-muted"></i>
          </button>
        </div>
      )}
      <div tw="p-6 pt-0">
        <H.Three id="menu-item-title">{product.name}</H.Three>
        <div dangerouslySetInnerHTML={{ __html: product.descriptionHtml }} />
      </div>
    </article>
  </Dialog>
);

export default NonAddablePage;
