import React, { useState } from "react";
import "twin.macro";
import { H, P } from "components/typography";
import FacebookShareButton from "components/FacebookShareButton";
import { DialogOverlay, DialogContent } from "@reach/dialog";
import "@reach/dialog/styles.css";

import Clipboard, { ClipboardButton } from "components/Clipboard";
import { createToast } from "screens/shared/toast";

import { useAuth } from "components/AuthProvider";
import { inviteAnalyticsParams } from "api/projectApi";
const InviteDialog: React.FC<{
  isOpen: boolean;
  onDismiss: () => void;
  shareUrl: string;
  eventId: string;
}> = ({ isOpen, onDismiss, eventId, shareUrl }) => {
  const [copyable, setCopyable] = useState(true);

  const onCopySuccess = () => createToast("Invite Link Copied");
  const onCopyFailure = () => {
    createToast(
      "Error copying link to clipboard. Please copy/paste the link below.",
      { color: "red" }
    );
    setCopyable(false);
  };

  const auth = useAuth();
  const shareUrlWithParams =
    shareUrl +
    (shareUrl.includes("?") ? "&" : "?") +
    inviteAnalyticsParams(eventId, auth.userId);

  return (
    <DialogOverlay tw="z-50" isOpen={isOpen} onDismiss={onDismiss}>
      <DialogContent
        tw="p-6 w-11/12 md:w-4/12 rounded flex flex-col items-center text-center z-50"
        aria-labelledby="invite-title"
      >
        <button
          className="close-button"
          onClick={onDismiss}
          tw="bg-transparent text-secondary font-bold text-lg border-none absolute self-end -mr-4 -mt-4"
          aria-label="close"
        >
          <i className="fas fa-times" />
        </button>
        <i
          className="fas fa-hand-holding-heart"
          id="invite-title"
          tw="text-xl"
        ></i>
        <H.Three as="h1">Share with your friends & neighbors!</H.Three>
        <P.Medium tw="pb-6">
          Refer a friend to DwellSocial and each receive $10 after their first
          order.
        </P.Medium>
        <section tw="border-0 border-t border-solid border-secondary w-full pt-4 flex flex-col lg:flex-row flex-grow gap-2">
          <FacebookShareButton shareUrl={shareUrlWithParams} tw="flex-grow" />
          {copyable ? (
            <ClipboardButton
              content={shareUrlWithParams}
              onSuccess={onCopySuccess}
              onFailure={onCopyFailure}
              disabled={!copyable}
              tw="flex-grow"
            >
              <i className="fas fa-link" tw="mr-1"></i>Share via URL
            </ClipboardButton>
          ) : null}
        </section>
        <Clipboard
          id="share-link-copy-url"
          content={shareUrlWithParams}
          onSuccess={onCopySuccess}
          onFailure={onCopyFailure}
          disabled={!copyable}
        >
          {shareUrlWithParams.split("&utm")[0]}
        </Clipboard>
      </DialogContent>
    </DialogOverlay>
  );
};

export default InviteDialog;
